import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SharedService } from '../../service/shared.service';

@Component({
  selector: 'app-waste-info',
  templateUrl: './waste-info.component.html',
  styleUrls: ['./waste-info.component.scss']
})
export class WasteInfoComponent implements OnInit, OnDestroy {
  commentsList: any[];
  machineDtls: any;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public dialogRef: MatDialogRef<WasteInfoComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private service: SharedService
  ) {
    this.machineDtls = data;
  }

  ngOnInit() {
  }

  closeDialog() {
    this.dialogRef.close('closed');
  }

  selectedTabChange(event) {
    if (event && event.index === 1) {
      // past comments
      this.getCommentsForMachine();
    }
  }

  getCommentsForMachine() {
    this.service.getComment(this.machineDtls)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        if (data && data.result) {
          this.commentsList = data.result;

          this.commentsList.forEach(element => {
            element.dateTime = new Date(element.dateTime * 1000);
            element.whenFromTime = new Date(element.whenFromTime * 1000);
            element.whenToTime = new Date(element.whenToTime * 1000);
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
