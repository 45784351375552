import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/core/services/environment.service';
import { NetworkService } from 'src/app/core/services/network.service';
import { DataService } from '../../../shared/service/data.service';
import { MaterialGroupHierarchyList, WasteDataResponse } from '../model/waste-dashboard-model.model';
import { WasteDbHelperService } from './waste-db-helper.service';

@Injectable({
  providedIn: 'root'
})
export class RequesterService {

  constructor(
    private envser: EnvironmentService,
    private networkSer: NetworkService,
    private helper: WasteDbHelperService,
    private dataService: DataService) { }


  getWasteData(
    selectedGranularityLevel?, selectedDetail?, dateFilterdata?, cooispiFlag?,
    costOrQty?, sfgData?, validatedData?, lineOrSku?, catList?, goOneDetailLevelDeeper = false, catmgList?, leadSubFamily?, isStock?
  ): Observable<WasteDataResponse> {
    let costQty = 'cost';

    if (costOrQty && costOrQty === 'cost') {
      costQty = 'cost';
    } else if (costOrQty && costOrQty === 'quantity') {
      costQty = 'Qty';
    } else if (costOrQty && costOrQty === 'wastePer') {
      costQty = 'wastePercent';
    }

    const req = this.dataService.getWasteData(selectedGranularityLevel, selectedDetail,
      dateFilterdata, cooispiFlag, costQty, validatedData, leadSubFamily);

    let wasteFor = req.wasteFor;

    delete req.serviceUrl;
    delete req.wasteFor;
    delete req.FromTimeEpoc;
    delete req.ToTimeEpoc;
    /* istanbul ignore if */
    if (catmgList && catmgList.materialgroup && catmgList.materialgroup !== '') {
      req.MaterialGroup = catmgList.materialgroup;
    }
    if (this.helper.skuTypeChecker(lineOrSku, selectedGranularityLevel)) {
      wasteFor = lineOrSku;
      req.isSFG = sfgData;
      req.IsValidated = validatedData;
      /* istanbul ignore else */
      if (selectedDetail.length) {
        /* istanbul ignore else */
        if (selectedDetail.length === 1) {
          /* istanbul ignore else */
          if (!goOneDetailLevelDeeper) {
            req.ProductHierarchyLevel = 'BigCategory';
          } else {
            req.ProductHierarchyLevel = 'Category';
          }
        } else if (selectedDetail.length === 2) {

          if (!goOneDetailLevelDeeper) {
            req.ProductHierarchyLevel = 'Category';
          } else {
            req.ProductHierarchyLevel = 'SubCategory';
          }
        } else if (selectedDetail.length >= 3) {
          req.ProductHierarchyLevel = 'SubCategory';
        }
      } else {
        req.ProductHierarchyLevel = 'BigCategory';
      }
    }
    if (wasteFor === 'sku') {
      req.isStockAdj = isStock;
    }
    if (selectedGranularityLevel && selectedGranularityLevel.line && selectedGranularityLevel.line.id) {
      req.isStockAdj = false;
    }

    return this.networkSer.httpReq('get', 'wasteData/' + wasteFor, req, false, false)
      .pipe(map(this.transformResult));
  }

  getHierarchy(fromDate?: string, toDate?: string, Hierarchytype?: string, costOrQty?: string, id?: string, subCategorySelected?: boolean, granularity?, validatedData?, materialgroupData?) {
    let costQty = 'cost';

    if (costOrQty && costOrQty === 'cost') {
      costQty = 'cost';
    } else if (costOrQty && costOrQty === 'quantity') {
      costQty = 'Qty';
    } else if (costOrQty && costOrQty === 'wastePer') {
      costQty = 'wastePercent';
    }

    const req = {};
    const key1 = 'costOrQty';
    req[key1] = costQty;
    const key2 = 'FromDate';
    req[key2] = fromDate;
    const key3 = 'ToDate';
    req[key3] = toDate;
    const key4 = 'productHierarchylevel';
    req[key4] = Hierarchytype;
    // req['SubCategoryId'] = null
    const key10 = 'IsValidated';
    req[key10] = validatedData;
    /* istanbul ignore if */
    if (materialgroupData && materialgroupData.materialgroup && materialgroupData.materialgroup !== '') {
      const key11 = 'MaterialGroup';
      req[key11] = materialgroupData.materialgroup;
    }
    if (Hierarchytype === 'Category') {
      const key5 = 'BigCategoryId';
      req[key5] = id;
    } else if (Hierarchytype === 'SubCategory' && subCategorySelected) {
      const key6 = 'SubCategoryId';
      req[key6] = id;
    } else if (Hierarchytype === 'SubCategory') {
      const gethkey111 = 'CategoryId';
      req[gethkey111] = id;
    }
    if (granularity && granularity.cluster && granularity.cluster.id) {
      const getHkey7 = 'ClusterId';
      req[getHkey7] = granularity.cluster.id;
    }
    if (granularity && granularity.factory && granularity.factory.id) {
      const getHkey8 = 'FactoryId';
      req[getHkey8] = granularity.factory.id;
    }
    if (granularity && granularity.line && granularity.line.id) {
      const getHkey9 = 'LineId';
      req[getHkey9] = granularity.line.id;
    }

    return this.networkSer.httpReq('get', 'productHierarchyData', req, false, false)
      .pipe(map(this.transformResult));

  }
  getMaterialGroupHierarchy(fromDate?: string, toDate?: string, materialGroupType?: string, costOrQty?: string, id?: string, subCategorySelected?: boolean, granularity?, validatedData?, IncludeSFG?, level?, isStock?): Observable<MaterialGroupHierarchyList[]> {
    let costQty = 'cost';

    if (costOrQty && costOrQty === 'cost') {
      costQty = 'cost';
    } else if (costOrQty && costOrQty === 'wastePer') {
      costQty = 'wastePercent';
    }

    const req = {};
    const key1 = 'costOrQty';
    req[key1] = costQty;
    const key2 = 'FromDate';
    req[key2] = fromDate;
    const key3 = 'ToDate';
    req[key3] = toDate;
    const key4 = 'productHierarchylevel';
    req[key4] = materialGroupType;
    // req['SubCategoryId'] = null
    const key10 = 'IsValidated';
    req[key10] = validatedData;
    const key11 = 'IncludeSFG';
    req[key11] = IncludeSFG;
    const key12 = 'Level';
    req[key12] = level;
    if (materialGroupType === 'Category') {
      const key5 = 'BigCategoryId';
      req[key5] = id;
    } else if (materialGroupType === 'SubCategory' && subCategorySelected) {
      const key6 = 'SubCategoryId';
      req[key6] = id;
    } else if (materialGroupType === 'SubCategory') {
      const getMkey111 = 'CategoryId';
      req[getMkey111] = id;
    }
    if (granularity && granularity.cluster && granularity.cluster.id) {
      const getMkey7 = 'ClusterId';
      req[getMkey7] = granularity.cluster.id;
    }
    if (granularity && granularity.factory && granularity.factory.id) {
      const getMkey8 = 'FactoryId';
      req[getMkey8] = granularity.factory.id;
    }
    if (granularity && granularity.line && granularity.line.id) {
      const getMkey9 = 'LineId';
      req[getMkey9] = granularity.line.id;
    }
    if (level === 'line' || level === 'linetosku') {
      const key20 = 'isStockAdj';
      req[key20] = false;
    } else {
      const key21 = 'isStockAdj';
      req[key21] = isStock;
    }

    return this.networkSer.httpReq('get', 'materialGroupHierarchyData', req, false, false)
      .pipe(map(this.transformResult));

  }

  getBestPractices(factoryID?: string) {
    const req = {
      factoryIds: factoryID
    };
    return this.networkSer.httpReq('get', 'factory/ChatbotTips', req, false, false);
  }

  getLeadSubFamily(reqest) {
    return this.networkSer.httpReq('get', 'leadSubFamilyData', reqest, false, false)
      .pipe(map(this.transformResult));
  }

  transformResult(res) {
    if (res && (res.message === 'Ok' || res.message === 'ok')) {
      return res.result;
    }
  }

  getFactorySummaryFunction(queryParams) {
    return this.networkSer.httpReq('get', `buildAppData/cluster/${queryParams.ClusterId}/factory/${queryParams.FactoryId}/year/${queryParams.Year}`, null, false, false)
      .pipe(map(res => {
        return res;
      }));
  }

  getDfosSummaryFunction(queryParams) {
    return this.networkSer.httpReq('get', `dfosData/cluster/${queryParams.ClusterId}/factory/${queryParams.FactoryId}/line/${queryParams.Line}/year/${queryParams.Year}`, null, false, false)
      .pipe(map(res => {
        return res;
      }));
  }



  getDfosLineSummaryFunction(queryParams) {
    return this.networkSer.httpReq('get', 'dfosline/data', queryParams, false, false)
      .pipe(map(res => {
        return res;
      }));
  }

  getModulesFunction() {
    return this.networkSer.httpReq('get', 'modules', null, false, false)
      .pipe(map(res => {
        return res;
      }));
  }

  postBuildAppFrom(body) {
    return this.networkSer.httpReq('post', 'buildAppData', body, false, false)
      .pipe(map(this.transformResult));
  }

  postDfosFrom(body) {
    return this.networkSer.httpReq('post', 'dfosData', body, false, false)
      .pipe(map(this.transformResult));
  }

  postDfosLineFrom(body) {
    return this.networkSer.httpReq('post', 'dfoslineData', body, false, false)
      .pipe(map(this.transformResult));
  }

  getSpcSummaryFunction(queryParams) {
    return this.networkSer.httpReq('get', `spcData/cluster/${queryParams.Cluster}/factory/${queryParams.Factory}/line/${queryParams.Line}/year/${queryParams.Year}`, null, false, false)
      .pipe(map(res => {
        return res;
      }));
  }

  postSpcForm(body) {
    return this.networkSer.httpReq('post', 'spcData', body, false, false)
      .pipe(map(this.transformResult));
  }

  getNgtwSummaryFunction(queryParams) {
    return this.networkSer.httpReq('get', `ngtwData/cluster/${queryParams.Cluster}/factory/${queryParams.Factory}/year/${queryParams.Year}`, null, false, false)
      .pipe(map(res => {
        return res;
      }));
  }

  postNgtwForm(body) {
    return this.networkSer.httpReq('post', 'ngtwData', body, false, false)
      .pipe(map(this.transformResult));
  }

  postSpcLine(body) {
    return this.networkSer.httpReq('post', 'lineSave', body, false, false)
      .pipe(map(this.transformResult));
  }

  downloadexcel(body) {
    return this.networkSer.httpReq('post', 'generateReport', body, false, false)
      .pipe(map(this.transformResult));
  }
}
