import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';


import { CurrencyService } from '../../../../shared/service/currency.service';
import { DataService } from 'src/app/shared/service/data.service';
import { Subscription } from 'rxjs';
import { ShowData, WasteDataResponse } from '../../model/waste-dashboard-model.model';

@Component({
  selector: 'app-multi-stacked-bar-chart',
  templateUrl: './multi-stacked-bar-chart.component.html',
  styleUrls: ['./multi-stacked-bar-chart.component.scss'],
  animations: [
    trigger('fillUp', [
      transition(':enter', [
        style({ height: '0' }),
        animate('1s ease-in-out', style({ height: '*' })),
      ]),
      transition(':leave', [])
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1s ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [])
    ])
  ]
})
export class MultiStackedBarChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() graphData: any[];
  @Input() wasteDataResponse: WasteDataResponse;
  @Input() selectedType: string;
  @Input() isLoading: boolean;
  @Input() showInGraph: ShowData;
  @Output() barClick: EventEmitter<object> = new EventEmitter();
  showNoData: any = false;
  showTotalForBar: any = false;
  filterSelection: any;
  plotData: any[];
  yScaleMax: number;
  yRange: any;
  // yUnit: any = {
  //   label: 'Euro',
  //   code: '&euro;'
  // };
  // yUnit: any;
  symbolCode: any;
  hoveredItem: any;
  colorArray = [{
    background: '#00abe6',
    color: '#fff'
  }, {
    background: '#ffc000',
    color: '#333B40'
  }];

  subscription: Subscription;
  currencyValue: any;

  currencyData: any = {
    conversionFactor: '',
    yUnit: '',
    symbolCode: '',
    label: ''
  };

  quantityArr: any[];
  unitTotalArr: any[];

  unitArr = [
    { key: 'kg', value: 'KG' },
    { key: 'ton', value: 'Tons' },
    { key: 'litre', value: 'L' },
    // { key: 'pk', value: 'Pieces' },
    { key: 'pk', value: 'PC' },
    { key: 'm', value: 'M' },
    { key: 'st', value: 'ST' },
    { key: 'ea', value: 'EA' },
    { key: 'g', value: 'G' },
    { key: 'ml', value: 'ML' },
    { key: 'm2', value: 'M2' },
    { key: 'KM', value: 'KM' },
    { key: 'ZKE', value: 'ZKE' },
    { key: 'GLL', value: 'GLL' },
    { key: 'FT', value: 'FT' },
    { key: 'TH', value: 'TH' },
    { key: 'CS', value: 'CS' },
    { key: 'TO', value: 'TO' },
    { key: 'TC', value: 'TC' },
    { key: 'PCO', value: 'PCO' },
    { key: 'ROL', value: 'ROL' },
    { key: 'PWD', value: 'PWD' },
    { key: 'OZA', value: 'OZA' },
    { key: 'MM', value: 'MM' },
    { key: 'M3', value: 'M3' },
    { key: 'CJ', value: 'CJ' },
    { key: 'LB', value: 'LB' },
    { key: 'CM', value: 'CM' },
    { key: 'DZ', value: 'DZ' }
  ];

  showRM = true;
  showPM = true;

  filterApplied: boolean;
  today: Date;
  currentDate: any;
  currrentFilter: any;
  currentHour: any;
  currentEndWeek: any;

  popOverArr: any;
  currentPopOverValue: any;
  quantityWasteArr: any;

  lineNameType: any;
  lineType: any;

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private currencyService: CurrencyService,
  ) {
  }

  ngOnInit() {
    this.dataService.on('stackBarFilterChange', this.filterChanged);
    this.dataService.on('stackBarSFGChange', this.sfgChanged);

    this.lineType = [
      { name: 'SAP Name', translateCode: 'buildapp_db.sap_name', class: 'sap-radio' },
      { name: 'Manex Name', translateCode: 'buildapp_db.manex_name', class: 'manex-radio' },
      { name: 'Local Name', translateCode: 'buildapp_db.local_name', class: 'local-radio' }
    ];
    this.lineNameType = this.lineType[0];
  }

  ngOnChanges(changes: any): void {
    this.showNoData = true;
    if (changes.graphData && changes.graphData.currentValue) {
      if (changes.graphData.currentValue.length) {
        this.showNoData = false;
        this.filterPlotData();
      } else if (changes.graphData && !changes.graphData.firstChange && changes.graphData.currentValue.length === 0) {
        this.plotData = [];
        this.showNoData = true;
      }
    }
    this.onDateChange();
  }

  setScale(arr: any[]): void {
    this.yScaleMax = -9999999999;
    let componentMaxVal = -9999999999;
    arr.forEach(quantityArr => {
      let quantityTotal = 0;
      quantityArr.quantity.forEach(item => {
        let componentTotal = 0;
        item.qty.forEach(component => {
          if (component && component.value) {
            componentTotal += component.value;
            if (componentMaxVal < component.value) {
              componentMaxVal = component.value;
            }
          }
        });
        item.componentTotal = componentTotal;
        quantityTotal += componentTotal;
        if (this.showTotalForBar) {
          if (this.yScaleMax < item.total) {
            /* istanbul ignore next */
            this.yScaleMax = item.total;
          }
        } else {
          if (this.yScaleMax < componentTotal) {
            this.yScaleMax = componentTotal;
          }
        }
      });
      quantityArr.quantityTotal = quantityTotal;
    });

    let keyToSort: any = 'quantityTotal';
    if (!this.showTotalForBar) {
      keyToSort = 'quantityTotal';
    }
    this.paretoSort(arr, keyToSort);
  }

  filterChanged = (filterSelection: any) => {
    this.showInGraph = filterSelection;
    this.filterPlotData();
  }

  sfgChanged = () => {
    this.filterPlotData();
  }

  paretoSort(arr, key) {
    arr.sort((a, b) => {
      return b[key] - a[key];
    });
  }

  setFgSfg() {
    let sfgData = true;
    let sfgFgRmTotal = 0;
    let sfgFgPmTotal = 0;
    let tempRmQuantity = [];
    let tempPmQuantity = [];
    let tempRmFound = false;
    let tempPmFound = false;

    if (JSON.parse(this.dataService.getStorageData('sfgSelected'))) {
      sfgData = JSON.parse(this.dataService.getStorageData('sfgSelected')).sfgData;
    } else {
      sfgData = true;
      this.dataService.setStorageData('sfgSelected', JSON.stringify({ sfgData }));
    }

    this.graphData.forEach(item => {
      sfgFgRmTotal = 0;
      sfgFgPmTotal = 0;
      tempRmQuantity = [];
      tempPmQuantity = [];
      tempRmFound = false;
      tempPmFound = false;

      if (item && item.quantity) {
        item.quantity.forEach((component, index) => {
          if (component.type && component.type === 'RM') {
            if (component.prodType && component.prodType !== 'rmSFGTotal') {
              if (sfgData || (!sfgData && component.prodType === 'FERT')) {
                if (component && component.qty) {
                  component.qty.forEach(value => {
                    tempRmFound = false;

                    tempRmQuantity.forEach(tempArr => {
                      if (tempArr.unit === value.unit) {
                        tempArr.value = tempArr.value + value.value;
                        tempRmFound = true;
                      }
                    });

                    if (!tempRmFound) {
                      tempRmQuantity.push({ unit: value.unit, value: value.value });
                    }
                  });
                }
              }
            } else if (component.prodType && component.prodType === 'rmSFGTotal') {
              item.quantity.splice(index, 1);
            }
          }
        });
      }
      if (item && item.quantity) {
        item.quantity.push({ qty: tempRmQuantity, type: 'RM', prodType: 'rmSFGTotal' });
      }

      if (item && item.quantity) {
        item.quantity.forEach((component, index) => {
          if (component.type && component.type === 'PM') {
            if (component.prodType && component.prodType !== 'pmSFGTotal') {
              if (sfgData || (!sfgData && component.prodType === 'FERT')) {
                if (component && component.qty) {
                  component.qty.forEach(value => {
                    tempPmFound = false;

                    tempPmQuantity.forEach(tempArr => {
                      if (tempArr.unit === value.unit) {
                        tempArr.value = tempArr.value + value.value;
                        tempPmFound = true;
                      }
                    });

                    if (!tempPmFound) {
                      tempPmQuantity.push({ unit: value.unit, value: value.value });
                    }
                  });
                }
              }
            } else if (component.prodType && component.prodType === 'pmSFGTotal') {
              item.quantity.splice(index, 1);
            }
          }
        });
      }
      if (item && item.quantity) {
        item.quantity.push({ qty: tempPmQuantity, type: 'PM', prodType: 'pmSFGTotal' });
      }
    });
  }

  setTotal(arr) {
    arr.forEach(item => {
      item.quantity.forEach(quantityArr => {
        let componentTotal = 0;

        quantityArr.qty.forEach(component => {
          if (component && component.value) {
            componentTotal += component.value;
          }
        });
        quantityArr.total = componentTotal;
      });
    });
  }

  filterPlotData() {
    // this.graphData = this.dataService.convertStandardizedUnit(this.graphData);
    this.convertStandardizedUnitKey(this.graphData);
    this.setFgSfg();
    this.setTotal(this.graphData);

    this.showTotalForBar = false;
    const showingValues: any[] = [];
    const prodTypes = ['rmSFGTotal', 'pmSFGTotal'];
    const dataToSort = JSON.parse(JSON.stringify(this.graphData));

    if (this.showInGraph) {
      if (!this.showInGraph.total) {
        this.showTotalForBar = false;
      }
      this.showPM = false;
      this.showRM = false;
      for (const property in this.showInGraph) {
        if (this.showInGraph[property] && property !== 'total') {

          if (property === 'PM') {
            this.showPM = true;
          } else if (property === 'RM') {
            this.showRM = true;
          }
          showingValues.push(property);
        }
      }

      if (showingValues.length && dataToSort) {
        dataToSort.forEach(bar => {
          bar.quantity = bar.quantity.filter((ele) => {
            return (showingValues.indexOf(ele.type) !== -1);
          });
        });
      }
    }

    if (dataToSort) {
      dataToSort.forEach(bar => {
        bar.quantity = bar.quantity.filter((ele) => {
          return (prodTypes.indexOf(ele.prodType) !== -1);
        });
      });
    }

    this.plotData = dataToSort;
    this.setScale(this.plotData);
    this.setUnitTotal(this.plotData);
    this.setPopOverArr(this.plotData);
  }

  onBarClick(barData) {
    this.barClick.emit(barData);
  }

  ngOnDestroy(): void {
    this.dataService.removeAllListnersFor('stackBarFilterChange');
    this.dataService.removeAllListnersFor('stackBarSFGChange');
  }

  setUnitTotal(arr) {
    this.unitTotalArr = [];

    arr.forEach((item, itemIndex) => {
      const currentUnitTotal = {
        kg: 0,
        kgRM: 0,
        kgPM: 0,

        litre: 0,
        litreRM: 0,
        litrePM: 0,

        ton: 0,
        tonRM: 0,
        tonPM: 0,

        pk: 0,
        pkRM: 0,
        pkPM: 0,

        ea: 0,
        eaRM: 0,
        eaPM: 0,

        st: 0,
        stRM: 0,
        stPM: 0,

        m: 0,
        mRM: 0,
        mPM: 0,

        ml: 0,
        g: 0,
        m2: 0,

        KM: 0,
        ZKE: 0,
        GLL: 0,

        FT: 0,
        TH: 0,
        CS: 0,

        TO: 0,
        TC: 0,
        PCO: 0,

        ROL: 0,
        PWD: 0,
        OZA: 0,
        CJ: 0,

        MM: 0,
        CM: 0,
        M3: 0,
        LB: 0,
        DZ: 0
      };

      item.quantity.forEach(quantityArr => {
        if (quantityArr.qty && ((this.showInGraph.PM && quantityArr.type === 'PM') ||
          (this.showInGraph.RM && quantityArr.type === 'RM'))) {
          quantityArr.qty.forEach(components => {
            switch (components.unit) {
              case 'ton': {
                currentUnitTotal.ton = currentUnitTotal.ton + components.value;
                break;
              }
              case 'litre': {
                currentUnitTotal.litre = currentUnitTotal.litre + components.value;
                break;
              }
              case 'pk': {
                currentUnitTotal.pk = currentUnitTotal.pk + components.value;
                break;
              }
              case 'kg': {
                currentUnitTotal.kg = currentUnitTotal.kg + components.value;
                break;
              }
              case 'ea': {
                currentUnitTotal.ea = currentUnitTotal.ea + components.value;
                break;
              }
              case 'st': {
                currentUnitTotal.st = currentUnitTotal.st + components.value;
                break;
              }
              case 'm': {
                currentUnitTotal.m = currentUnitTotal.m + components.value;
                break;
              }
              case 'ml': {
                currentUnitTotal.ml = currentUnitTotal.ml + components.value;
                break;
              }
              case 'g': {
                currentUnitTotal.g = currentUnitTotal.g + components.value;
                break;
              }
              case 'm2': {
                currentUnitTotal.m2 = currentUnitTotal.m2 + components.value;
                break;
              }
              case 'KM': {
                currentUnitTotal.KM = currentUnitTotal.KM + components.value;
                break;
              }
              case 'ZKE': {
                currentUnitTotal.ZKE = currentUnitTotal.ZKE + components.value;
                break;
              }
              case 'GLL': {
                currentUnitTotal.GLL = currentUnitTotal.GLL + components.value;
                break;
              }
              case 'FT': {
                currentUnitTotal.FT = currentUnitTotal.FT + components.value;
                break;
              }
              case 'TH': {
                currentUnitTotal.TH = currentUnitTotal.TH + components.value;
                break;
              }
              case 'CS': {
                currentUnitTotal.CS = currentUnitTotal.CS + components.value;
                break;
              }
              case 'TO': {
                currentUnitTotal.TO = currentUnitTotal.TO + components.value;
                break;
              }
              case 'TC': {
                currentUnitTotal.TC = currentUnitTotal.TC + components.value;
                break;
              }
              case 'PCO': {
                currentUnitTotal.PCO = currentUnitTotal.PCO + components.value;
                break;
              }
              case 'ROL': {
                currentUnitTotal.ROL = currentUnitTotal.ROL + components.value;
                break;
              }
              case 'PWD': {
                currentUnitTotal.PWD = currentUnitTotal.PWD + components.value;
                break;
              }
              case 'OZA': {
                currentUnitTotal.OZA = currentUnitTotal.OZA + components.value;
                break;
              }
              case 'MM': {
                currentUnitTotal.MM = currentUnitTotal.MM + components.value;
                break;
              }
              case 'M3': {
                currentUnitTotal.M3 = currentUnitTotal.M3 + components.value;
                break;
              }
              case 'CJ': {
                currentUnitTotal.CJ = currentUnitTotal.CJ + components.value;
                break;
              }
              case 'LB': {
                currentUnitTotal.LB = currentUnitTotal.LB + components.value;
                break;
              }
              case 'CM': {
                currentUnitTotal.CM = currentUnitTotal.CM + components.value;
                break;
              }
              case 'DZ': {
                currentUnitTotal.DZ = currentUnitTotal.DZ + components.value;
                break;
              }
            }

          });
        }
      });
      this.unitTotalArr.push(currentUnitTotal);
    });
  }

  convertStandardizedUnitKey(arr) {
    arr.forEach(item => {
      item.quantity.forEach(quantityArr => {
        quantityArr.qty.forEach(component => {
          this.unitArr.forEach(standardUnit => {
            if (standardUnit.value === component.unit) {
              component.unit = standardUnit.key;
            }
          });
        });
      });
    });
  }

  onDateChange() {
    this.currrentFilter = 'month';
    this.today = new Date();
    this.currentDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
    if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter'))) {
      this.currentDate = new Date(JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).dateObj);
      this.currrentFilter = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).appliedFilterType;

      if (this.currrentFilter === 'week') {
        this.currentEndWeek = new Date(JSON.parse
          (this.dataService.getStorageData('selectedWasteDBTimeFilter')).endWeekFromSelectedDate);
      } else {
        this.currentEndWeek = '';
      }

      if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr &&
        JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr.label) {
        this.currentHour = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr.label;
      } else {
        this.currentHour = '';
      }
    } else {
      this.currentDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
      this.currrentFilter = 'month';
    }
  }

  setPopOverArr(arr) {
    this.quantityWasteArr = [];

    this.currentPopOverValue = {
      rmValue: 0,
      pmValue: 0,
      totalValue: '',
      unit: '',
      unitValue: ''
    };

    this.popOverArr = [];

    let unitFound = false;

    arr.forEach((item, itemIndex) => {
      this.quantityWasteArr = [];

      this.popOverArr[itemIndex] = {
        id: item.id,
        name: item.name,
        type: item.type,
        quantityWaste: []
      };

      item.quantity.forEach((quantityArr, quantityArrIndex) => {
        quantityArr.qty.forEach((component, componentIndex) => {
          this.currentPopOverValue = {
            rmValue: 0,
            pmValue: 0,
            totalValue: '',
            unit: '',
            unitValue: '',
            adjRM: 0,
            adjPM: 0,
            adjFG: 0,
            adjSFG: 0
          };

          this.currentPopOverValue.unit = component.unit;

          this.unitArr.forEach(standardUnit => {
            if (standardUnit.key === component.unit) {
              this.currentPopOverValue.unitValue = standardUnit.value;
            }
          });

          if (quantityArr.type === 'RM') {
            this.currentPopOverValue.rmValue = component.value;
          } else if (quantityArr.type === 'PM') {
            this.currentPopOverValue.pmValue = component.value;
          }

          switch (component.unit) {
            case 'ton': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].ton;
              break;
            }
            case 'litre': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].litre;
              break;
            }
            case 'pk': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].pk;
              break;
            }
            case 'kg': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].kg;
              break;
            }
            case 'ea': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].ea;
              break;
            }
            case 'st': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].st;
              break;
            }
            case 'm': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].m;
              break;
            }
            case 'ml': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].ml;
              break;
            }
            case 'g': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].g;
              break;
            }
            case 'm2': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].m2;
              break;
            }
            case 'KM': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].KM;
              break;
            }
            case 'ZKE': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].ZKE;
              break;
            }
            case 'GLL': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].GLL;
              break;
            }
            case 'FT': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].FT;
              break;
            }
            case 'TH': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].TH;
              break;
            }
            case 'CS': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].CS;
              break;
            }
            case 'TO': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].TO;
              break;
            }
            case 'TC': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].TC;
              break;
            }
            case 'PCO': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].PCO;
              break;
            }
            case 'ROL': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].ROL;
              break;
            }
            case 'PWD': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].PWD;
              break;
            }
            case 'OZA': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].OZA;
              break;
            }
            case 'MM': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].MM;
              break;
            }
            case 'M3': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].M3;
              break;
            }
            case 'CM': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].CM;
              break;
            }
            case 'LB': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].LB;
              break;
            }
            case 'DZ': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].DZ;
              break;
            }
            case 'CJ': {
              this.currentPopOverValue.totalValue = this.unitTotalArr[itemIndex].CJ;
              break;
            }
          }

          unitFound = false;

          this.quantityWasteArr.forEach((quantityWaste, quantityWasteIndex) => {
            if (quantityWaste.unit === this.currentPopOverValue.unit) {
              if (quantityArr.type === 'RM') {
                /* istanbul ignore next */
                quantityWaste.rmValue = this.currentPopOverValue.rmValue;
              } else if (quantityArr.type === 'PM') {
                quantityWaste.pmValue = this.currentPopOverValue.pmValue;
              }

              unitFound = true;
            }
          });

          if (!unitFound) {
            this.quantityWasteArr.push(this.currentPopOverValue);
          }
        });
      });

      this.popOverArr[itemIndex].quantityWaste = this.quantityWasteArr;
    });
    this.updatePopOverWithStock();
  }

  updatePopOverWithStock() {
    let checkType = 'clusterKey';
    /* istanbul ignore next */
    if (this.selectedType === 'factory') {
      checkType = 'factoryKey';
    }

    this.popOverArr.forEach(popData => {
      if (this.wasteDataResponse) {
        let line;
        /* istanbul ignore next */
        if (this.wasteDataResponse.wasteData) {
          line = this.wasteDataResponse.wasteData.filter(data => data.id === popData.id);
        } else if (this.wasteDataResponse.skuWasteData) {
          /* istanbul ignore next */
          line = this.wasteDataResponse.skuWasteData.filter(data => data.id === popData.id);
        }
        /* istanbul ignore next */
        if (line[0].type === 'Line') {
          popData.lineManexName = line[0].lineManexName;
          popData.lineLocalName = line[0].lineLocalName;
          popData.lineSAPName = line[0].lineSAPName;
        }
        /* istanbul ignore next */
        popData.adjRM = 0;
        /* istanbul ignore next */
        popData.adjPM = 0;
        /* istanbul ignore next */
        popData.adjFG = 0;
        /* istanbul ignore next */
        popData.adjSFG = 0;
        /* istanbul ignore next */
        if (this.wasteDataResponse.stockAdjData && this.showInGraph.isStock) {
          const selectedData = this.wasteDataResponse.stockAdjData.filter(data => data[checkType] === popData.id);
          /* istanbul ignore next */
          selectedData.forEach(element => {

            const PopupValue = {
              rmValue: 0,
              pmValue: 0,
              totalValue: 0.0,
              unit: '',
              unitValue: '',
              adjRM: 0.0,
              adjPM: 0.0,
              adjFG: 0.0,
              adjSFG: 0.0
            };
            /* istanbul ignore next */
            const selectedQuantity = popData.quantityWaste.find(data => data.unitValue === element.unitOfmeasure);

            /* istanbul ignore next */
            if (selectedQuantity) {

              if (element.materialType === 'RM') {
                selectedQuantity.adjRM = element.qty;
                selectedQuantity.totalValue += element.qty;
              }
              if (element.materialType === 'PM') {
                selectedQuantity.adjPM = element.qty;
                selectedQuantity.totalValue += element.qty;
              }
              if (element.prodType === 'FERT') {
                selectedQuantity.adjFG = element.qty;
                selectedQuantity.totalValue += element.qty;
              }
              if (element.prodType === 'HALB' && this.showInGraph.sfg) {
                selectedQuantity.adjSFG = element.qty;
                if (this.showInGraph.sfg) {
                  selectedQuantity.totalValue += element.qty;
                }
              }
            } else {
              /* istanbul ignore next */
              if (this.showInGraph.isStock) {
                if (element.materialType === 'RM') {
                  PopupValue.adjRM = element.qty;
                  PopupValue.totalValue += element.qty;
                  PopupValue.unitValue = element.unitOfmeasure;
                  this.unitArr.forEach(standardUnit => {
                    if (standardUnit.value === element.unitOfmeasure) {
                      PopupValue.unit = standardUnit.key;
                    }
                  });
                }
                if (element.materialType === 'PM') {
                  PopupValue.adjPM = element.qty;
                  PopupValue.totalValue += element.qty;
                  PopupValue.unitValue = element.unitOfmeasure;
                  this.unitArr.forEach(standardUnit => {
                    if (standardUnit.value === element.unitOfmeasure) {
                      PopupValue.unit = standardUnit.key;
                    }
                  });
                }
                if (element.prodType === 'FERT') {
                  PopupValue.adjFG = element.qty;
                  PopupValue.totalValue += element.qty;
                  PopupValue.unitValue = element.unitOfmeasure;
                  this.unitArr.forEach(standardUnit => {
                    if (standardUnit.value === element.unitOfmeasure) {
                      PopupValue.unit = standardUnit.key;
                    }
                  });
                }
                if (element.prodType === 'HALB') {
                  PopupValue.adjSFG = element.qty;
                  PopupValue.unitValue = element.unitOfmeasure;
                  this.unitArr.forEach(standardUnit => {
                    if (standardUnit.value === element.unitOfmeasure) {
                      PopupValue.unit = standardUnit.key;
                    }
                  });
                  if (this.showInGraph.sfg) {
                    PopupValue.totalValue += element.qty;
                  }
                }
                popData.quantityWaste.push(PopupValue);
                // count++;
              }
            }

          });
        }
        /* istanbul ignore next */
        if (this.selectedType === 'sku') {
          if (this.wasteDataResponse.skuWasteData && this.showInGraph.isStock) {
            const selectedDataSKU = this.wasteDataResponse.skuWasteData.filter(data => data.id === popData.id);
            selectedDataSKU.forEach(element => {


              const selectedQuantitySKU = popData.quantityWaste.find(data => data.unitValue === element.qtyUnit);

              selectedQuantitySKU.adjRM = 0;
              selectedQuantitySKU.adjPM = 0;
              selectedQuantitySKU.adjFG = 0;
              selectedQuantitySKU.adjSFG = 0;
              if (selectedQuantitySKU) {

                if (element.prodType === 'FERT') {
                  selectedQuantitySKU.adjFG = element.stockAdjWasteQty;
                  selectedQuantitySKU.totalValue += element.stockAdjWasteQty;
                }
                if (element.prodType === 'HALB' && this.showInGraph.sfg) {
                  selectedQuantitySKU.adjSFG = element.stockAdjWasteQty;
                  selectedQuantitySKU.totalValue += element.stockAdjWasteQty;
                }
              }

            });
          }
        }
      }

    });
    /* istanbul ignore next */
    if (this.showInGraph.isStock && !this.showInGraph.RM && !this.showInGraph.PM) {
      this.plotData.forEach(data => {
        /* istanbul ignore next */
        this.popOverArr.forEach(element => {
          if (data.id === element.id) {
            let total = 0;
            const array = {
              componentTotal: 0,
              total: 0,
              type: 'StockAdj',
              qty: []
            };
            // let qty: any[] = [];
            element.quantityWaste.forEach(waste => {
              /* istanbul ignore next */
              total += waste.totalValue;
              const valueQty = {
                unit: '',
                value: 0
              };
              valueQty.unit = waste.unitValue;
              valueQty.value = waste.totalValue;
              array.qty.push(valueQty);
            });
            array.componentTotal = total;
            array.total = total;
            data.quantity.push(array);
          }
        });
      });
    }
  }

}
