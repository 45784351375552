import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})

export class IdleTimeoutService {
   public timeoutInMilliseconds = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
  // public timeoutInMilliseconds = 1 * 60 * 1000; 
  public idleTimeout: ReturnType<typeof setTimeout>;


  constructor(private auth: MsalService) {
    this.resetTimer();
    this.initListeners();
  }

/* istanbul ignore next */
  resetTimer(): void {
    clearTimeout(this.idleTimeout);
    this.idleTimeout = setTimeout(() => {
      this.auth.logout();
    }, this.timeoutInMilliseconds);
  }

/* istanbul ignore next */
  private initListeners(): void {
    ['mousemove', 'keydown', 'scroll'].forEach((event) => {
      window.addEventListener(event, () => this.resetTimer());
    });

  }

    logoutUser(): void {
    this.auth.logout();
  }

}
