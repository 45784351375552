// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const protectedResourceMap: [string, string[]][] = [
  ['https://graph.microsoft.com/v1.0/me', ["user.read", "profile", "User.ReadBasic.All", "Group.Read.All", "Directory.Read.All", "Group.ReadWrite.All"]]
];
export const environment = {
  production: false,
  // baseURL: 'https://bnlwe-wmb01-d-57766-funcapp-01-dev.azurewebsites.net/api/',
  // baseURL: 'https://bnlwe-gs-d-57321-apimgt.azure-api.net/sngtwapi/',
  // baseURL: 'https://ngtw-dev.unilever.com/sngtwapi/',
  // baseURL: 'https://dfapi-d.unilever.com/sngtwapi/',
  baseURL: 'https://bnlwe-gs-q-57322-apimgt-01.azure-api.net/dddapi/',
  baseFileURL: 'https://bnlwe-gs-q-57322-apimgt-01.azure-api.net/files/',
  apiKey: 'CisSSpcoHa9xUptpHB2nN4VwbeBmqGfO4pGIrtJKA6BF6bL0wRaY3g==',
  subscriptionID: '526fa4fd873c4c5c9197d5394bbac014',
  // dfosURL: 'https://dfos-dev.unilever.com/#/',
  dfosURL: 'https://dfos-dev.unilever.com/api/',
  mdcsURL: 'https://mdcs-qa.unilever.com/mdcsapi/',
  dfosRedirectURL: 'https://dfos-dev.unilever.com/#!/',
  msalAuthConfig: {
    auth: {
      clientId: '0c4a8941-e08e-423a-9669-54683beee475',
      authority: 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/',
      validateAuthority: true,
      redirectUri: window.location.origin + '/',
      postLogoutRedirectUri: window.location.origin + '/',
      navigateToLoginRequestUrl: true,
    },
  },
  // tenant: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
  tenant: 'f66fae02-5d36-495b-bfe0-78a6ff9f8e6e',
  // clientId: 'adfbd976-f057-4a9d-89e1-8df628b80539',
  clientId: '0c4a8941-e08e-423a-9669-54683beee475',
  cacheLocation: 'sessionStorage',
  redirectUri: window.location.origin + '/',
  postLogoutRedirectUri: window.location.origin + '/',
  type: 'local',
  // redirectUri: 'https://ngtw-dev.unilever.com',
  // endPoint: 'https://login.microsoftonline.com/f66fae02-5d36-495b-bfe0-78a6ff9f8e6e/oauth2/authorize',
  endPoint: {
    'https://ngtw-dev.unilever.com/ngtwapi/': '0c4a8941-e08e-423a-9669-54683beee475',
    'https://dfos-dev.unilever.com/api/': '0c4a8941-e08e-423a-9669-54683beee475',
    'https://mdcs-qa.unilever.com/mdcsapi/': '0c4a8941-e08e-423a-9669-54683beee475',
  }
  // baseURL: 'http://10.133.27.77:7071/api/'
  // baseURL: 'http://10.133.27.66:7071/api/'
  // baseURL: 'http://10.133.27.80:7071/api/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
