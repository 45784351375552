import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatInputModule,
  MatButtonModule,
  MatFormFieldModule,
  MatDialogModule,
  MatProgressSpinnerModule,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatGridListModule,
  MatDatepickerModule,
  MatExpansionModule,
  MatTabsModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatBadgeModule,
  MatMenuModule
} from '@angular/material';
import { PopoverModule } from 'ngx-bootstrap/popover';

import { WasteInputComponent } from './components/waste-input/waste-input.component';

import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { DragDirective } from './directives/drag.directive';
import { TranslateModule } from '@ngx-translate/core';
import { TimePickerUtilService } from './service/time-picker-util.service';
import { DashboardFilterComponent } from './components/dashboard-filter/dashboard-filter.component';
import { DashboardFilterHeaderComponent } from './components/dashboard-filter-header/dashboard-filter-header.component';
import { DashboardDateFilterComponent } from './components/dashboard-date-filter/dashboard-date-filter.component';
import { OverlayLoaderComponent } from './components/overlay-loader/overlay-loader.component';
import { ExceptionHandlingComponent } from './components/exception-handling/exception-handling.component';
import { WasteInfoComponent } from './components/waste-info/waste-info.component';
import { WasteCommentsListComponent } from './components/waste-comments-list/waste-comments-list.component';
import { CurrencyRateFormatterPipe } from './pipes/currency-rate-formatter.pipe';
import { DecimalToCommaPipe } from './pipes/decimal-formatter.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { StackedBarValueFormatterPipe } from '../features/waste-dashboard/pipes/stacked-bar-value-formatter.pipe';
import { StackedBarChartComponent } from '../features/waste-dashboard/components/stacked-bar-chart/stacked-bar-chart.component';
import {
  MultiStackedBarChartComponent
} from '../features/waste-dashboard/components/multi-stacked-bar-chart/multi-stacked-bar-chart.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ConfirmationPopupModelComponent } from './components/confirmation-popup-model/confirmation-popup-model.component';
import { SelectLineComponent } from './components/select-line/select-line.component';
import { StackedBarNameFormatterPipe } from './pipes/stacked-bar-name-formatter.pipe';
import { DaCommentsComponent } from './components/da-comments/da-comments.component';
import { QuillModule } from 'ngx-quill';
import { ReplyComponent } from './components/da-comments/reply/reply.component';
import { CommentComponent } from './components/da-comments/comment/comment.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { CostPipe } from './pipes/cost.pipe';
import { NguCarouselModule } from '@ngu/carousel';
import { FactorySelectionModalComponent } from './components/factory-selection-modal/factory-selection-modal.component';

@NgModule({
  declarations: [
    WasteInputComponent,
    DashboardFilterComponent,
    DashboardFilterHeaderComponent,
    DashboardDateFilterComponent,
    OverlayLoaderComponent,
    ExceptionHandlingComponent,
    WasteInfoComponent,
    WasteCommentsListComponent,
    CurrencyRateFormatterPipe,
    DecimalToCommaPipe,
    StackedBarValueFormatterPipe,
    DragDirective,
    StackedBarChartComponent,
    MultiStackedBarChartComponent,
    UnauthorizedComponent,
    ConfirmationPopupModelComponent,
    SelectLineComponent,
    StackedBarNameFormatterPipe,
    DaCommentsComponent,
    ReplyComponent,
    CommentComponent,
    DateAgoPipe,
    CostPipe,
    FactorySelectionModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatButtonModule,
    MatFormFieldModule,
    MatDialogModule,
    MatIconModule,
    MatSidenavModule,
    MatRippleModule,
    MatSelectModule,
    MatRadioModule,
    TranslateModule,
    MatGridListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
    MatExpansionModule,
    PopoverModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatMenuModule,
    MatTabsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MatAutocompleteModule,
    NgxPermissionsModule,
    QuillModule.forRoot(),
    NguCarouselModule
  ],
  exports: [
    WasteInputComponent,
    DashboardFilterComponent,
    DashboardFilterHeaderComponent,
    OverlayLoaderComponent,
    MatSidenavModule,
    MatIconModule,
    MatRippleModule,
    MatSelectModule,
    MatRadioModule,
    MatGridListModule,
    MatButtonModule,
    TranslateModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTableModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatInputModule,
    MatTabsModule,
    PopoverModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatMenuModule,
    DashboardDateFilterComponent,
    CurrencyRateFormatterPipe,
    DecimalToCommaPipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    StackedBarValueFormatterPipe,
    DragDirective,
    StackedBarChartComponent,
    MultiStackedBarChartComponent,
    UnauthorizedComponent,
    NgxPermissionsModule,
    ConfirmationPopupModelComponent,
    DaCommentsComponent,
    ReplyComponent,
    CommentComponent,
    CostPipe,
    NguCarouselModule
  ],
  entryComponents: [ConfirmationPopupModelComponent],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
    TimePickerUtilService,
    DatePipe

    // DataService
  ], schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
