import { Injectable } from '@angular/core';
import {
    HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';


import * as moment from 'moment';
// import { DialogService } from '../services/dialog/dialog.service';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service.service';
import { EMPTY, Observable } from 'rxjs';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/operator/mergeMap';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    isTokenExpired = false;
    constructor(private router: Router, private authService: AuthService, private msalService: MsalService) { }
    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<any> { // Observable<HttpEvent<any>>

        if (!this.isTokenExpired) {
            const authReq = this.addADTokenToHeaders(req);
            const tokenExpTime = this.authService.getTokenExpiryTime();
            const currentDateTime = moment().format('YYYY-MM-DD HH:mm:ss');

            if (tokenExpTime && currentDateTime && moment(tokenExpTime).isSameOrBefore(currentDateTime)) {
                // To re-generate token
                return this.getADToken()
                    .mergeMap((response) => {
                        if (response && response.type && response.type === 'Success') {
                            const authTokenReq = this.addADTokenToHeaders(authReq);
                            return this.handleRequestWithToken(next, authTokenReq);
                        } else {
                            this.isTokenExpired = true;
                            return this.openSessionExpiredPopup();
                        }
                    });
            } else {
                return this.handleRequestWithToken(next, authReq);
            }
        } else {
            return this.openSessionExpiredPopup();
        }
    }

    // Open session expired popup
    openSessionExpiredPopup() {
        const modalOptions = { disableClose: true, closeOnNavigation: true }; // show dialog
        // this.dialogService.closeAllDialogs();
        // const dialogRef = this.dialogService.openDialog(messages.sessionExpiredMsg, modalOptions);
        // dialogRef.afterClosed().subscribe((closeType) => {
        localStorage.clear();
        sessionStorage.clear();
        this.isTokenExpired = false;
        // if (closeType && closeType === 'Login') {
        // Directly redirect to AD login page
        this.msalService.loginRedirect();
        // } else {
        //   this.router.navigate(['']);
        // }
        //  });
        return EMPTY;
    }

    // modify headers with AD token
    addADTokenToHeaders(req) {
        // const authToken = this.authService.getADToken();
        const authToken = sessionStorage.getItem('msal.idtoken');
        // Clone the request and replace the original headers with cloned headers, updated with the authorization.
        const authReq = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${authToken}`),
        });

        return authReq;
    }

    // Get AD token silently
    getADToken(): Observable<any> {
        // To renew idToken, please pass clientId as the only scope
        // const accessTokenRequest = {
        //   scopes: [environment.msalAuthConfig.auth.clientId],
        //   authority: environment.msalAuthConfig.auth.authority
        // };
        const loggedIn = !!this.msalService.getAccount();
        if (loggedIn) {
        const accessTokenRequest = {
             scopes: ['user.read'],
           // scopes: [environment.msalAuthConfig.auth.clientId],
            // authority: environment.msalAuthConfig.auth.authority
        };
        return Observable.fromPromise(
            this.msalService.acquireTokenSilent(accessTokenRequest).then(async (payload) => {
                this.authService.setTokenExpiryTime(payload); // Set token expiry time
                if (payload && payload.idToken && payload.idToken.rawIdToken) {
                    this.authService.setADToken(payload.idToken.rawIdToken);
                }
                return { type: 'Success', info: payload };
            }).catch((error) => {
                return { type: 'Error', info: error };
            })
        );
        }
    }


    // handle request with proper token
    handleRequestWithToken(next: HttpHandler, authReq: any): Observable<any> {
        return next.handle(authReq);
        // TODO: Uncomment below code when Token is checking on API
        // .pipe(
        //   catchError((err: HttpErrorResponse) => {
        //     console.log({ err });
        //     if (err && err.status && err.status === 401) {
        //       if (err.error && err.error.message && err.error.message === 'Unauthorized. Token invalid') {
        //         console.log('Regenerating new token');
        //         // Generate token
        //         return this.getADToken().mergeMap((response) => {
        //           console.log({ response });
        //           if (response && response.type && response.type === 'Success') {
        //             const authTokenReq = this.addADTokenToHeaders(authReq);
        //             return next.handle(authTokenReq).pipe(catchError((mainErr) => {
        //               return throwError(mainErr); // Error in main API
        //             }));
        //           } else {
        //             return this.openSessionExpiredPopup();
        //           }
        //         });
        //       } else { // Error in main API
        //         return throwError(err);
        //       }
        //     } else { // Error in main API
        //       return throwError(err);
        //     }
        //   })
        // );
    }
}
