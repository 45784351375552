
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from '../service/currency.service';
import { DataService } from '../service/data.service';

@Pipe({
  name: 'cost',
  pure: false
})
export class CostPipe implements PipeTransform {

  currencySymbol: string;
  currencyRate: number;

  constructor(
    private currencyService: CurrencyService,
    private dataService: DataService
  ) {
    currencyService.currencyValue$.subscribe(currency => {
      const yUnit = dataService.getYUnit(currency.selectedCurrency.code);
      this.currencyRate = currency.crate;
      this.currencySymbol = yUnit.symbolCode;
    });
  }

  transform(value: any, ...args: any[]): any {
    return this.costDivide(Number(value));
  }

  costDivide(value: number): string {
    let cost = value * this.currencyRate;
    let negativeSign = '';
    if (cost < 0) {
      cost = Math.abs(cost);
      negativeSign = '-';
    }
    if (cost >= 1000000) {
      return this.currencySymbol + negativeSign + (cost / 1000000).toFixed(2) + 'M';
    } else if (cost >= 1000) {
      return this.currencySymbol + negativeSign + (cost / 1000).toFixed(2) + 'K';
    } else {
      return this.currencySymbol + negativeSign + (cost).toFixed(2);
    }
  }

}
