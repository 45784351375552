import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stackedBarNameFormatter'
})
export class StackedBarNameFormatterPipe implements PipeTransform {
  /* istanbul ignore next */
  transform(obj: any, selectedType): any {
    if (selectedType) {
      return obj[selectedType];
    }
    // default
    return obj.name;
  }

}
