import { Injectable } from '@angular/core';
import { Subject, from, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class CurrencyService {


    constructor() {
    }
    private currencyValue = new BehaviorSubject<any>({
        crate: 1,
        selectedCurrency: {}
    });
    currencyValue$ = this.currencyValue.asObservable();
    /* istanbul ignore next */
    changeCurrencyValue(currency: any) {
        this.currencyValue.next(currency);
    }
}
