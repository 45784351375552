import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {

  constructor() { }

  getAPIBaseURL(): string {
    return environment.baseURL;
  }

  getAPIKey(): string {
    return environment.apiKey;
  }

  getSubscriptionID(): string {
    return environment.subscriptionID;
  }

  getDFOSURL(): string {
    return environment.dfosURL;
  }

  getdfosRedirectURL(): string {
    return environment.dfosRedirectURL;
  }

  getMDCSURL(): string {
    return environment.mdcsURL;
  }

  getFileURL(): string {
    return environment.baseFileURL;
  }

  getTenantID(): any {
    return environment.tenant;
  }

  getClientID(): any {
    return environment.clientId;
  }

  getCacheLocation(): any {
    return environment.cacheLocation;
  }

  getRedirectURI(): any {
    return environment.redirectUri;
  }

  getPostLogoutURI(): any {
    return environment.postLogoutRedirectUri;
  }

  getEndPoint(): any {
    return environment.endPoint;
  }
}
