import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  userType: string;
  userInfo: any;
  userType$ = new BehaviorSubject('');
  dfosRole: any;
  constructor(private location: Location, private authService: MsalService) { }
  /* istanbul ignore next */
  setLoginUserType(type: string) {
    /* istanbul ignore next */
    this.userType = type;
    /* istanbul ignore next */
    this.userType$.next(type);
  }
  /* istanbul ignore next */
  setDFOSRole(role) {
    /* istanbul ignore next */
    this.dfosRole = role;
  }
  /* istanbul ignore next */
  getLoginUserType() {
    /* istanbul ignore next */
    return this.userType;
  }
  /* istanbul ignore next */
  setUserInfo(userInfo: any) {
    /* istanbul ignore next */
    this.userInfo = userInfo;
  }
  /* istanbul ignore next */
  loginRedirect() {
    /* istanbul ignore next */
    return this.authService.loginRedirect;
  }
  /* istanbul ignore next */
  logout() {
    /* istanbul ignore next */
    return this.authService.logout;
  }
  /* istanbul ignore next */
  getUserInfo() {
    /* istanbul ignore next */
    return this.userInfo;
  }
  /* istanbul ignore next */
  checkIsAuthticated() {
    /* istanbul ignore next */
    if (this.authService.getAccount != null) {
      return true;
    } else {
      return false;
    }
  }
  /* istanbul ignore next */
  getAllAccountInfo() {
    /* istanbul ignore next */
    return this.authService.getAllAccounts;
  }
  /* istanbul ignore next */
  isAuthenticated() {
    /* istanbul ignore next */
    if (this.getUserInfo()) {
      return true;
    } else {
      return false;
    }
  }
  /* istanbul ignore next */
  goBack() {
    /* istanbul ignore next */
    this.location.back();
  }

  // Set Token expiry time in local storage
  /* istanbul ignore next */
  setTokenExpiryTime(payload) {
    /* istanbul ignore next */
    if (payload && payload.expiresOn) {
      const tokenExpiryTime = moment(payload.expiresOn).format('YYYY-MM-DD HH:mm:ss');
      sessionStorage.setItem('token_expiry_time', tokenExpiryTime);
    }
  }

  // Get token expiry time
  /* isstanbul ignore next */
  getTokenExpiryTime() {
    /* istanbul ignore next */
    return sessionStorage.getItem('token_expiry_time');
  }

  // Remove token expiry time
  removeTokenExpiryTime() {
    /* istanbul ignore next */
    sessionStorage.removeItem('token_expiry_time');
  }

  // Set AD Token in local storage
  setADToken(rawIdToken) {
    /* istanbul ignore next */
    sessionStorage.setItem('ad_token', rawIdToken);
  }

  // Get token expiry time
  getADToken() {
    /* istanbul ignore next */
    return sessionStorage.getItem('ad_token');
  }

  // Remove token expiry time
  removeADToken() {
    /* istanbul ignore next */
    sessionStorage.removeItem('ad_token');
  }
}
