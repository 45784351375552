import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { EnvironmentService } from './core/services/environment.service';
// import { AdalService } from 'adal-angular4';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { AuthError, AuthResponse } from 'msal';
import { Router } from '@angular/router';
import { Usagereport } from './shared/handlers/usagereport';
import { IdleTimeoutService } from './core/services/idle-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  token;
  // private adalConfiguration = {
  //   tenantId: this.envSer.getTenantID(),
  //   clientId: this.envSer.getClientID(),
  //   cacheLocation: this.envSer.getCacheLocation(),
  //   redirectUri: this.envSer.getRedirectURI(),
  //   postLogoutRedirectUri: this.envSer.getPostLogoutURI(),
  //   endPoint: this.envSer.getEndPoint()
  // };
  private adalConfiguration = {
    tenant: this.envSer.getTenantID(),
    clientId: this.envSer.getClientID(),
    cacheLocation: this.envSer.getCacheLocation(),
    redirectUri: this.envSer.getRedirectURI(),
    navigateToLoginRequestUrl: false,
    endpoints: this.envSer.getEndPoint()
  };
  loggedIn: boolean;
  public userInfo: any = null;
  public isIframe: boolean;

  startTime: Date;

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event) {
  //   this.usageReportInBrowserClose();

  //   alert(1);
  //   event.preventDefault();
  //   event.returnValue = '';
  //   return false;
  // }

  constructor(
    private envSer: EnvironmentService,
    // private adal: AdalService,
    // private adal: MsAdalAngular6Service,
    private broadcastService: BroadcastService, private authService: MsalService,
    private router: Router,
    private usageReport: Usagereport,
    private IdleTimeoutService: IdleTimeoutService
  ) {
    // this.adal.init(this.adalConfiguration);
    // this.adal.handleWindowCallback();
    // this.adal.constructor(this.adalConfiguration);
    // this.adal.handleCallback();
    sessionStorage.removeItem('selectedTabIndex');
    sessionStorage.removeItem('SelectedMaterialButton');
    this.isIframe = window !== window.parent && !window.opener;
    if (this.authService.getAccount()) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
    // if (!(this.adal && this.adal.userInfo && this.adal.userInfo.authenticated)) {
    // if (!(this.adal && this.adal.userInfo && this.adal.isAuthenticated)) {
    //   this.adal.login();
    // }
    this.startTime = new Date();

    window.onbeforeunload = () => {
      this.usageReportInBrowserClose();
    };
  }
  ngOnInit() {

    this.broadcastService.subscribe('msal:loginFailure', () => {
      this.loggedIn = false;
      this.login();
    });

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.loggedIn = true;
    });
    // this.broadcastService.subscribe('msal:acquireTokenFailure', (payload: AuthError) => {
    //   this.login();
    // });
    this.authService.handleRedirectCallback((redirectError: AuthError) => {
      if (redirectError) {
        console.error('Redirect error: ', redirectError);
        return;
      }
    });
  }
  login() {
    this.authService.loginRedirect();
  }

  logout() {
    this.authService.logout();
  }


  usageReportInBrowserClose() {
    const urlColl = decodeURIComponent(this.router.url).split('/');
    let level = '';
    let cluster = null;
    let factory = null;
    let line = null;
    let sku = null;
    if (urlColl && urlColl[1] && urlColl[1].split('?') && urlColl[1].split('?')[0] === 'insights') {
      let insighturlColl;
      if (urlColl[1].split('?')[1]) {
        const selectedFactory = this.getLocalStorageData('selectedDAFactory');
        insighturlColl = urlColl[1].split('?')[1].split('&');
        if (insighturlColl[4] && insighturlColl[4].split('=')[1] === 'factory') {
          level = 'Insight Dashboard - Factory';
          cluster = insighturlColl[0].split('=')[1];
          factory = selectedFactory && selectedFactory.name && selectedFactory.name !== '' ? selectedFactory.name : '';
        }
        if (insighturlColl[4].split('=')[1] === 'line') {
          level = 'Insight Dashboard - Line';
          cluster = insighturlColl[0].split('=')[1];
          factory = selectedFactory && selectedFactory.name && selectedFactory.name !== '' ? selectedFactory.name : '';
          line = insighturlColl[3].split('=')[1];
        }
        if (level !== '') {
          this.usageReport.report(level, this.startTime, cluster, factory, line, sku);
        }
      }
    }
    if (urlColl && urlColl[1] && urlColl[1].split('?') && urlColl[1].split('?')[0] === 'sku-insights') {
      let insighturlColl;
      if (urlColl[1].split('?')[1]) {
        const selectedFactorySet = this.getLocalStorageData('selectedDAFactory');
        insighturlColl = urlColl[1].split('?')[1].split('&');
        level = 'Insight Dashboard - SKU';
        cluster = insighturlColl[0].split('=')[1];
        factory = selectedFactorySet && selectedFactorySet.name && selectedFactorySet.name !== '' ? selectedFactorySet.name : '';
        line = insighturlColl[5].split('=')[1];
        sku = insighturlColl[3].split('=')[1];
        this.usageReport.report(level, this.startTime, cluster, factory, line, sku);
      }
    }
    if (urlColl.includes('search')) {
      if (urlColl[urlColl.length - 1] === 'top-sku-search') {
        level = 'Search - Top 5 SKU';
        cluster = urlColl[2];
        factory = urlColl[4];
      }
      if (urlColl[urlColl.length - 1] === 'top-po-search') {
        level = 'Search - Top 5 PO';
        cluster = urlColl[2];
        factory = urlColl[4];
      }
      if (urlColl[urlColl.length - 1] === 'top-category-search') {
        level = 'Search - Top 5 Category';
        cluster = urlColl[2];
      }
      this.usageReport.report(level, this.startTime, cluster, factory, line, sku);
    }
    if (urlColl.includes('buildapp')) {
      if (urlColl.length === 2) {
        level = 'Waste Dashboard - Global';
      }
      if (urlColl[2]) {
        level = 'Waste Dashboard - Cluster';
        cluster = urlColl[2];
      }
      if (urlColl[4]) {
        level = 'Waste Dashboard - Factory';
        factory = urlColl[4];
      }
      if (urlColl[6]) {
        level = 'Waste Dashboard - Line';
        line = urlColl[6];
      }
      this.usageReport.report(level, this.startTime, cluster, factory, line, sku);
    }
    if (urlColl.includes('machine-view')) {
      level = 'Machine Detail View';
      cluster = urlColl[2];
      factory = urlColl[4];
      line = urlColl[6];
      if (cluster && factory && line) {
        this.usageReport.report(level, this.startTime, cluster, factory, line, sku);
      }
    }
    if (urlColl.includes('line-summary')) {
      level = 'Line Summary View';
      cluster = urlColl[2];
      factory = urlColl[4];
      line = urlColl[6];
      if (cluster && factory && line) {
        this.usageReport.report(level, this.startTime, cluster, factory, line, sku);
      }
    }
  }

  getLocalStorageData(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }
  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
  }
}
