import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimePickerUtilService {
  /* istanbul ignore next */
  initYearSelected = 2;

  constructor() { }

  initMonthList(): any[] {
    return [
      {
        label: 'JAN',
        code: 0
      },
      {
        label: 'FEB',
        code: 1
      },
      {
        label: 'MAR',
        code: 2
      },
      {
        label: 'APR',
        code: 3
      },
      {
        label: 'MAY',
        code: 4
      },
      {
        label: 'JUN',
        code: 5
      },
      {
        label: 'JUL',
        code: 6
      },
      {
        label: 'AUG',
        code: 7
      },
      {
        label: 'SEP',
        code: 8
      },
      {
        label: 'OCT',
        code: 9
      },
      {
        label: 'NOV',
        code: 10
      },
      {
        label: 'DEC',
        code: 11
      }
    ];
  }

  initHourList(): any[] {
    return [
      {
        label: '12 AM - 1 AM',
        hour: '12am - 1am',
        hourVal: 0
      },
      {
        label: '1 AM - 2 AM',
        hour: '1am - 2am',
        hourVal: 1
      },
      {
        label: '2 AM - 3 AM',
        hour: '2am - 3am',
        hourVal: 2
      },
      {
        label: '3 AM - 4 AM',
        hour: '3am - 4am',
        hourVal: 3
      },
      {
        label: '4 AM - 5 AM',
        hour: '4am - 5am',
        hourVal: 4
      },
      {
        label: '5 AM - 6 AM',
        hour: '5am - 6am',
        hourVal: 5
      },
      {
        label: '6 AM - 7 AM',
        hour: '6am - 7am',
        hourVal: 6
      },
      {
        label: '7 AM - 8 AM',
        hour: '7am - 8am',
        hourVal: 7
      },
      {
        label: '8 AM - 9 AM',
        hour: '8am - 9am',
        hourVal: 8
      },
      {
        label: '9 AM - 10 AM',
        hour: '9am - 10am',
        hourVal: 9
      },
      {
        label: '10 AM - 11 AM',
        hour: '10am - 11am',
        hourVal: 10
      },
      {
        label: '11 AM - 12 PM',
        hour: '11am - 12pm',
        hourVal: 11
      },
      {
        label: '12 PM - 1 PM',
        hour: '12pm - 1pm',
        hourVal: 12
      },
      {
        label: '1 PM - 2 PM',
        hour: '1pm - 2pm',
        hourVal: 13
      },
      {
        label: '2 PM - 3 PM',
        hour: '2pm - 3pm',
        hourVal: 14
      },
      {
        label: '3 PM - 4 PM',
        hour: '3pm - 4pm',
        hourVal: 15
      },
      {
        label: '4 PM - 5 PM',
        hour: '4pm - 5pm',
        hourVal: 16
      },
      {
        label: '5 PM - 6 PM',
        hour: '5pm - 6pm',
        hourVal: 17
      },
      {
        label: '6 PM - 7 PM',
        hour: '6pm - 7pm',
        hourVal: 18
      },
      {
        label: '7 PM - 8 PM',
        hour: '7pm - 8pm',
        hourVal: 19
      },
      {
        label: '8 PM - 9 PM',
        hour: '8pm - 9pm',
        hourVal: 20
      },
      {
        label: '9 PM - 10 PM',
        hour: '9pm - 10pm',
        hourVal: 21
      },
      {
        label: '10 PM - 11 PM',
        hour: '10pm - 11pm',
        hourVal: 22
      },
      {
        label: '11 PM - 12 AM',
        hour: '11pm - 12am',
        hourVal: 23
      }
    ];
  }
}
