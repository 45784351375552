import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sbValFormat'
})
export class StackedBarValueFormatterPipe implements PipeTransform {
  /* istanbul ignore next */
  transform(value: any, ...args: any[]): any {
    if (args[0] && args[0].divisionfactor) {
      return ((value / args[0].divisionfactor).toFixed(2));
    }
    return value;
  }

}
