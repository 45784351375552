import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router'; 
import { MsalGuard } from '@azure/msal-angular';
import { PermissionGuard } from './core/guard/permission.guard';



const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule), 
    canActivate: [MsalGuard, PermissionGuard]
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes, 
    { useHash: false, 
    },
   
  )],
  
  exports: [RouterModule]
})
export class AppRoutingModule {

  
 } 



