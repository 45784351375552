import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { formatDate } from '@angular/common';
import { TimePickerUtilService } from '../../service/time-picker-util.service';
import { SharedService } from '../../service/shared.service';
import { DataService } from '../../service/data.service';
import { element } from 'protractor';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import moment from 'moment';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-dashboard-date-filter',
  templateUrl: './dashboard-date-filter.component.html',
  styleUrls: ['./dashboard-date-filter.component.scss']
})

export class DashboardDateFilterComponent implements OnInit, OnChanges, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  timeFilterList: any[];
  shiftFilters: any[];
  selectedTimeFilter: any;
  openDatePicker: any = false;
  today: Date;
  hourList: any[];
  selectedHr: any;
  selectedMonth: any;
  selectedDate: any;
  selectedWeek: Date;
  selectedShiftDate: any;
  selectedHourDate: any;
  daysWeekSelected: any;
  selectedShift: any;
  showShift: boolean;
  showHourFilter: boolean;
  showMonthCalendar: boolean;
  currentYear: any;
  monthFilterYears = new Array();
  monthFilterMonths = new Array();
  monthFilterYearSelected: any;
  monthFilterMonthSelected = -1;
  monthFilterQuaterSelected: any;
  showMonthFilterYear: boolean;
  ShowMonthFilterMonth: boolean;
  ShowMonthFilterheader: boolean;
  previousYearDisabled: boolean;
  nextYearDisabled: boolean;
  minimumCalendarDate: Date;
  maxWeekDate: Date;
  currentMonth: number;
  shiftValues: any;
  selectedShiftValue: any;
  minimumYear: any;
  quarterList: any;
  monthList: any;
  quarterListNew: any;
  monthListNew: any;
  monthStartCalendar: any;
  singleSelection = false;

  monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // @ViewChild('monthCalendar', { static: false }) monthCalendarInstance: ElementRef;
  @Output() filterValues: EventEmitter<object> = new EventEmitter();
  @Input() disabledSelection: any[];
  @Input() initialValues: any;
  // @Output() filterValues = new EventEmitter<{typeOfFilter: string, year: any,
  // month: any, date: any, hour: any, shift: any}>();
  @Input() multipleSelection: any;
  quarterFlag = false;
  monthFlag: boolean;
  baseYear: number;
  febEndDate: string;
  finalYear: any;
  monthEndCalendar: any;
  monthFilterSelectedMonth: any;
  quarterStartCalendar: string;
  monthFilterSelectedQuarter: any;
  quarterEndCalendar: string;
  singleQuarterSelection: boolean;
  selectedPrevious: boolean;
  selectedPreviousQuarter: boolean;
  monthListSelectedData: any;
  quarterListSelectedData: any;
  filterChangeSelection: boolean;


  constructor(
    private timeInitser: TimePickerUtilService,
    private service: SharedService,
    private dataService: DataService,
    private router: Router
  ) {
    this.timeFilterList = [
      {
        label: 'common.month',
        code: 'month',
        disabled: false
      },
      {
        label: 'common.quarter',
        code: 'quarter',
        disabled: false
      },
      {
        label: 'common.year',
        code: 'year',
        disabled: false
      },
      // {
      //   label: 'common.week',
      //   code: 'week',
      //   disabled: false
      // },
      // {
      //   label: 'common.day',
      //   code: 'day',
      //   disabled: false
      // },
      // {
      //   label: 'common.shift',
      //   code: 'shift',
      //   disabled: false
      // },
      // {
      //   label: 'common.hour',
      //   code: 'hour',
      //   disabled: false
      // }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.router && this.router.url && this.router.url !== '' &&
      this.router.url.indexOf('/search/') > -1) {
      /* istanbul ignore next */
      this.disabledSelection.push('quarter', 'year');
    }
    this.disableTypes(this.disabledSelection);



    if (this.router.url === '/search') {
      if (changes.initialValues && changes.initialValues.currentValue) {
        this.initialValues = changes.initialValues.currentValue;

        if (this.initialValues && this.initialValues.appliedFilterType) {
          this.timeFilterList.forEach(filterArrs => {
            if (filterArrs.code === this.initialValues.appliedFilterType) {
              this.selectedTimeFilter = filterArrs;
            }
          });

          if (this.selectedTimeFilter.code === 'month') {
            this.monthFilterYearSelected = this.initialValues.dateObj.getFullYear();
            this.monthFilterMonthSelected = this.initialValues.dateObj.getMonth();
            this.monthFilterMonth(this.monthFilterMonths[this.monthFilterMonthSelected]);
          }
        }
      }
    }

  }

  ngOnInit() {
    this.today = new Date();
    this.currentYear = this.today.getFullYear();
    this.maxWeekDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 6);
    this.selectedTimeFilter = this.timeFilterList[0];
    this.hourList = this.timeInitser.initHourList();
    this.selectedMonth = '';
    this.maxWeekDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 6);
    this.selectedWeek = this.maxWeekDate;
    this.daysWeekSelected = new Date(this.maxWeekDate.getFullYear(), this.maxWeekDate.getMonth(), this.maxWeekDate.getDate() + 6);
    this.selectedDate = '';
    this.selectedShiftDate = '';
    this.selectedShift = undefined;
    this.selectedHr = -1;
    this.selectedHourDate = this.today;
    this.monthFilterYearSelected = this.today.getFullYear();
    this.monthFilterMonthSelected = this.currentMonth - 1;
    this.baseYear = this.monthFilterYearSelected - this.timeInitser.initYearSelected;
    this.singleSelection = true;
    this.singleQuarterSelection = true;
    this.monthFilterSelectedQuarter = this.monthFilterYearSelected;
    this.monthFilterSelectedMonth = this.monthFilterYearSelected;
    this.selectedPrevious = false;
    this.selectedPreviousQuarter = false;

    if (this.baseYear < 2020) {
      this.minimumYear = 2020;
    } else {
      this.minimumYear = this.baseYear;
    }
    this.quarterList = [];
    // monthCode
    this.monthList = [];


    this.quarterListNew = [
      {
        month: 'JAN-MAR',
        code: 3,
        indexValue: 1,
        startDate: '01-01',
        endDate: '03-31',
        isSelected: false,
        year: this.monthFilterYearSelected,
        quarterDisabled: false
      },
      {
        month: 'APR-JUN',
        code: 6,
        indexValue: 2,
        startDate: '04-01',
        endDate: '06-30',
        isSelected: false,
        year: this.monthFilterYearSelected,
        quarterDisabled: false
      },
      {
        month: 'JUL-SEP',
        code: 9,
        indexValue: 3,
        startDate: '07-01',
        endDate: '09-30',
        isSelected: false,
        year: this.monthFilterYearSelected,
        quarterDisabled: false
      },
      {
        month: 'OCT-DEC',
        code: 12,
        indexValue: 4,
        startDate: '10-01',
        endDate: '12-31',
        isSelected: false,
        year: this.monthFilterYearSelected,
        quarterDisabled: false
      }
    ];
    this.monthListNew = [
      {
        label: 'JAN',
        code: 1,
        isSelected: false,
        startDate: '01-01',
        endDate: '01-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        // startMonth: 'January'
      },
      {
        label: 'FEB',
        code: 2,
        isSelected: false,
        startDate: '02-01',
        endDate: this.febEndDate,
        year: this.monthFilterYearSelected,
        monthDisabled: false
        // startMonth: 'February'
      },
      {
        label: 'MAR',
        code: 3,
        isSelected: false,
        startDate: '03-01',
        endDate: '03-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        // startMonth: 'March'
      },
      {
        label: 'APR',
        code: 4,
        isSelected: false,
        startDate: '04-01',
        endDate: '04-30',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //  startMonth: 'April'
      },
      {
        label: 'MAY',
        code: 5,
        isSelected: false,
        startDate: '05-01',
        endDate: '05-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        // startMonth: 'May'
      },
      {
        label: 'JUN',
        code: 6,
        isSelected: false,
        startDate: '06-01',
        endDate: '06-30',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //  startMonth: 'June'
      },
      {
        label: 'JUL',
        code: 7,
        isSelected: false,
        startDate: '07-01',
        endDate: '07-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        // startMonth: 'July'
      },
      {
        label: 'AUG',
        code: 8,
        isSelected: false,
        startDate: '08-01',
        endDate: '08-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //  startMonth: 'August'
      },
      {
        label: 'SEP',
        code: 9,
        isSelected: false,
        startDate: '09-01',
        endDate: '09-30',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //  startMonth: 'September'
      },
      {
        label: 'OCT',
        code: 10,
        isSelected: false,
        startDate: '10-01',
        endDate: '10-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //   startMonth: 'October'
      },
      {
        label: 'NOV',
        code: 11,
        isSelected: false,
        startDate: '11-01',
        endDate: '11-30',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //  startMonth: 'November'
      },
      {
        label: 'DEC',
        code: 12,
        isSelected: false,
        startDate: '12-01',
        endDate: '12-31',
        year: this.monthFilterYearSelected,
        monthDisabled: false
        //   startMonth: 'December'
      }
    ];

    if (this.multipleSelection) {
      this.monthList = JSON.parse(JSON.stringify(this.monthListNew.concat(this.monthList)));
      // monthcode ends
      // parallel Quarter Selection
      this.quarterList = JSON.parse(JSON.stringify(this.quarterListNew.concat(this.quarterList)));
    }


    for (var i = this.baseYear; i <= this.currentYear; i++) {
      this.monthFilterYears.push(i);
    }

    this.shiftFilters = null;
    // commented for testing
    this.monthFilterMonths = this.timeInitser.initMonthList();
    // this.monthFilterMonths = this.monthListNew;
    this.showShift = false;
    this.showHourFilter = true;
    this.showMonthCalendar = true;
    this.showMonthFilterYear = false;
    this.ShowMonthFilterMonth = true;
    this.ShowMonthFilterheader = true;
    this.previousYearDisabled = false;
    this.nextYearDisabled = false;
    this.minimumCalendarDate = new Date(this.minimumYear, 0);
    this.currentMonth = this.today.getMonth();
    if (this.initialValues && this.initialValues.appliedFilterType) {
      this.timeFilterList.forEach(filterArr => {
        if (filterArr.code === this.initialValues.appliedFilterType) {
          this.selectedTimeFilter = filterArr;
          // } else if (this.initialValues.appliedFilterType === 'date') {
          //   this.selectedTimeFilter = this.timeFilterList[2];
        }
      });

      if (this.selectedTimeFilter.code === 'month') {
        if (!this.multipleSelection) {
          this.monthFilterYearSelected = this.initialValues.dateObj.getFullYear();
          this.monthFilterMonthSelected = this.initialValues.dateObj.getMonth();
          this.monthFilterMonth(this.monthFilterMonths[this.monthFilterMonthSelected]);
        }
        // testcode
        /* istanbul ignore next */
        if (this.multipleSelection) {
          if (this.initialValues && !this.initialValues.dateObj.toString().includes('|')) {
            this.monthFilterYearSelected = parseInt(moment(this.initialValues.dateObj).utc().format('YYYY'));
          } else {
            if (this.initialValues.startOfMonth.slice(5, 7).includes('01')) {
              this.monthFilterYearSelected = parseInt(moment(this.initialValues.dateObj.split('|')[0]).utc().format('YYYY')) + 1;
            } else {
              this.monthFilterYearSelected = parseInt(moment(this.initialValues.dateObj.split('|')[0]).utc().format('YYYY'));
            }

          }
          if (this.currentYear > parseInt(this.monthFilterYearSelected)) {
            for (var i = this.currentYear - 1; i >= parseInt(this.monthFilterYearSelected); i--) {
              this.selectMonthPrevious(i + 1);
              this.selectQuarterPrevious(i + 1);

            }
          }
        }
        // end testcode
        // } else if (this.selectedTimeFilter.code === 'week') {
        // this.selectedWeek = this.initialValues.dateObj;
        // this.daysWeekSelected = new Date(this.selectedWeek.getFullYear(), this.selectedWeek.getMonth(), this.selectedWeek.getDate() + 6);
        // } else if (this.selectedTimeFilter.code === 'day') {
        //   this.selectedDate = this.initialValues.dateObj;
        // } else if (this.selectedTimeFilter.code === 'shift') {
        //   this.selectedShiftDate = this.initialValues.dateObj;
        //   this.selectedShift = this.initialValues.selectedHr;
        // } else if (this.selectedTimeFilter.code === 'hour') {
        //   this.selectedHr = this.initialValues.selectedHr;
        //   this.selectedHourDate = this.initialValues.dateObj;
      } else if (this.selectedTimeFilter.code === 'year') {
        this.showMonthFilterYear = true;
        this.ShowMonthFilterMonth = false;
        this.monthFilterYearSelected = this.initialValues.dateObj.getFullYear();
        if (this.multipleSelection) {
          /* istanbul ignore next */
          if (this.currentYear > parseInt(this.monthFilterYearSelected)) {
            for (var i = this.currentYear - 1; i >= parseInt(this.monthFilterYearSelected); i--) {
              this.selectQuarterPrevious(i + 1);
              this.selectMonthPrevious(i + 1);
            }
          }
        }
      } else if (this.selectedTimeFilter.code === 'quarter' && this.multipleSelection) {
        this.monthFilterYearSelected = parseInt(moment(this.initialValues.dateObj.split('|')[0]).utc().format('YYYY'));
        if (this.currentYear > parseInt(this.monthFilterYearSelected)) {
          for (var i = this.currentYear - 1; i >= parseInt(this.monthFilterYearSelected); i--) {
            this.selectQuarterPrevious(i + 1);
            this.selectMonthPrevious(i + 1);
          }
        }
      }
    }

    // this.selectedTimeFilter = this.timeFilterList[0];
    this.hourList = this.timeInitser.initHourList();
    // this.selectedMonth = '';
    this.maxWeekDate = new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() - 6);
    // this.selectedWeek = this.maxWeekDate;
    // this.daysWeekSelected = new Date(this.maxWeekDate.getFullYear(), this.maxWeekDate.getMonth(), this.maxWeekDate.getDate() + 6);
    // this.selectedDate = '';
    // this.selectedShiftDate = '';
    // this.selectedShift = undefined;
    // this.selectedHr = -1;
    // this.selectedHourDate = this.today;
    // this.monthFilterYearSelected = this.today.getFullYear();
    // this.monthFilterMonthSelected = this.currentMonth - 1;
    // this.monthFilterMonth(this.monthFilterMonths[this.monthFilterMonthSelected]);

    if (this.monthFilterYearSelected === this.minimumYear) {
      this.previousYearDisabled = true;
    }

    if (this.monthFilterYearSelected === this.currentYear) {
      this.nextYearDisabled = true;
    }

    // disable month on init
    /* istanbul ignore next */
    // this.disableMonths(this.monthFilterYearSelected);
  }

  selectQuarter(monthObj) {
    try {
      if (this.getQuarterList() && this.getQuarterList()[0] === this.monthFilterYearSelected &&
        monthObj.code > this.getQuarterList()[1]) {
        return;
      }

      // waste dashboard new code starts
      if (this.multipleSelection) {
        this.selectedPreviousQuarter = false;
        let selectedObject = this.quarterList.filter(listObj => {
          if (listObj.isSelected) {
            return listObj.code;
          }

        }).length;
        console.log("selected Object", selectedObject);
        if (selectedObject === 0) {
          this.singleQuarterSelection = false;
          this.quarterList.filter(listObj => listObj.month === monthObj.month && listObj.year === monthObj.year)[0].isSelected = true;
        } else {
          this.singleQuarterSelection = false;
          // get first and last selection
          let firstSelection = -1;
          let lastSelection = -1;
          for (var i = 0; i <= this.quarterList.length - 1; i++) {
            if (this.quarterList[i].isSelected) {
              if (firstSelection === -1) {
                firstSelection = i;
                lastSelection = i;
              } else {
                /* istanbul ignore next */
                lastSelection = i;
              }

            }
            if (monthObj.code === this.quarterList[i].code && monthObj.year === this.quarterList[i].year) {
              var currentSelected = i;
            }
          }

          var selectedQuarterList = this.quarterList.filter(list => list.isSelected);
          if (currentSelected < firstSelection - 1 || currentSelected > lastSelection + 1) {
            /* istanbul ignore next */
            this.quarterList.filter(list => list.isSelected = false);
            /* istanbul ignore next */
            this.quarterList.filter(listObj => listObj.month === monthObj.month && listObj.year === monthObj.year)[0].isSelected = true;
            /* istanbul ignore next */
          } else if ((currentSelected == firstSelection - 1 || currentSelected == lastSelection + 1) && selectedQuarterList.length < 4) {
            /* istanbul ignore next */
            this.quarterList.filter(listObj => listObj.month === monthObj.month && listObj.year === monthObj.year)[0].isSelected = true;

          } else if (currentSelected === firstSelection || currentSelected === lastSelection) {
            if (selectedQuarterList.length === 1) {
              this.quarterList.filter(listObj => listObj.month === monthObj.month && listObj.year === monthObj.year)[0].isSelected = true;
            } else {
              this.quarterList.filter(listObj => listObj.month === monthObj.month && listObj.year === monthObj.year)[0].isSelected = false;
            }
          } else {
            /* istanbul ignore next */
            for (let index = 0; index < selectedQuarterList.length; index++) {
              if (selectedQuarterList.length === 4 && selectedQuarterList[index].year !== this.monthFilterYearSelected) {
                this.selectedPreviousQuarter = true;
              } else {
                this.selectedPreviousQuarter = false;
              }
            }

          }
        }
        selectedQuarterList = this.quarterList.filter(list => list.isSelected);
        // this.finalYear=selectedQuarterList[selectedQuarterList.length - 1].year;
        if (selectedQuarterList.length > 0) {
          console.log(55555, selectedQuarterList);
          console.log(772, selectedQuarterList[selectedQuarterList.length - 1].endDate);
          // display date code start
          this.quarterEndCalendar = selectedQuarterList[selectedQuarterList.length - 1].month.slice(4, 7) + " " + selectedQuarterList[selectedQuarterList.length - 1].year;
          if (selectedQuarterList.length == 1) {
            this.monthFilterSelectedQuarter = selectedQuarterList[0].month + " " + selectedQuarterList[0].year;
          } else if (selectedQuarterList.length > 1) {
            this.monthFilterSelectedQuarter = selectedQuarterList[0].month.slice(0, 3) + " " + selectedQuarterList[0].year + " " + "to" + " " + this.quarterEndCalendar;
          }
          // display date code end

          var dateStart = selectedQuarterList[0].month.slice(0, 3) + this.monthFilterYearSelected;
          var dateEnd = selectedQuarterList[selectedQuarterList.length - 1].month.slice(3);
          console.log('dateStart', dateStart);
          console.log('dateEnd', dateEnd);
          // const selectedQuarterList = this.quarterList.filter(list => list.isSelected)[1];
          this.monthFilterQuaterSelected = dateStart + dateEnd;
          console.log("month" + this.monthFilterQuaterSelected);
          this.finalYear = selectedQuarterList[selectedQuarterList.length - 1].year;

          this.filterValues.emit({
            typeOfFilter: 'quarter', year: this.monthFilterYearSelected,
            startYear: selectedQuarterList[0].year, endYear: selectedQuarterList[selectedQuarterList.length - 1].year,
            month: '', date: '', hour: '', shift: '', startDate: selectedQuarterList[0].year
              + '-' + selectedQuarterList[0].startDate, endDate: selectedQuarterList[selectedQuarterList.length - 1].year + '-' +
                selectedQuarterList[selectedQuarterList.length - 1].endDate, selectedData: true
          });
          this.selectedPreviousQuarter = true;
          this.monthFilterMonthSelected = -1;
        }/*  else {
          this.filterValues.emit();
        } */
      } else {
        // insight dashboard old code starts

        this.quarterListNew.filter(list => list.isSelected = false);
        this.quarterListNew.filter(listObj => listObj.month === monthObj.month)[0].isSelected = true;
        const selectedQuarterList = this.quarterListNew.filter(list => list.isSelected)[0];
        this.monthFilterQuaterSelected = selectedQuarterList;

        this.filterValues.emit({
          typeOfFilter: 'quarter', year: this.monthFilterYearSelected,
          month: '', date: '', hour: '', shift: '', startDate: this.monthFilterYearSelected
            + '-' + selectedQuarterList.startDate, endDate: this.monthFilterYearSelected + '-' +
              selectedQuarterList.endDate
        });
        this.monthFilterMonthSelected = -1;
        // insight dashboard old code ends
      }

    } catch (error) {

    }
  }

  getQuarterList(): number[] {
    const yearArray: number[] = [];

    const currYear = new Date().getFullYear();
    yearArray.push(currYear);

    const currMonth = new Date().getMonth();
    yearArray.push(currMonth);

    return yearArray;
  }
  /* istanbul ignore next */
  disableOkButton() {
    this.monthListSelectedData = [];
    this.quarterListSelectedData = [];
    /* istanbul ignore next */
    if (this.multipleSelection) {
      if (this.selectedTimeFilter.code === 'quarter') {
        this.monthList.filter(list => list.isSelected = false);
      }
      if (this.selectedTimeFilter.code === 'month') {
        this.quarterList.filter(list => list.isSelected = false);
      }
      this.monthListSelectedData = this.monthList.filter(list => list.isSelected);
      this.quarterListSelectedData = this.quarterList.filter(list => list.isSelected);

      if (this.monthListSelectedData.length === 0 && this.quarterListSelectedData.length === 0) {
        this.filterValues.emit({ typeOfFilter: 'filter-changed', year: '', month: '', date: '', hour: '', shift: '', selectedData: false });
      } else {
        if (this.monthListSelectedData.length > 0) {
          this.filterValues.emit({
            typeOfFilter: 'month', year: this.monthListSelectedData[this.monthListSelectedData.length - 1].year, month: this.monthListSelectedData[this.monthListSelectedData.length - 1].code, date: '', hour: '', shift: '', startDate: this.monthListSelectedData[0].year
              + '-' + this.monthListSelectedData[0].startDate, endDate: this.monthListSelectedData[this.monthListSelectedData.length - 1].year + '-' +
                this.monthListSelectedData[this.monthListSelectedData.length - 1].endDate, selectedData: true, monthListSelected: true, isSelectedFilter: this.filterChangeSelection
          });
        } else if (this.quarterListSelectedData.length > 0) {
          this.filterValues.emit({
            typeOfFilter: 'quarter', year: this.quarterListSelectedData[this.quarterListSelectedData.length - 1].year, month: this.quarterListSelectedData[this.quarterListSelectedData.length - 1].code, date: '', hour: '', shift: '', startDate: this.quarterListSelectedData[0].year
              + '-' + this.quarterListSelectedData[0].startDate, endDate: this.quarterListSelectedData[this.quarterListSelectedData.length - 1].year + '-' +
                this.quarterListSelectedData[this.quarterListSelectedData.length - 1].endDate, selectedData: true, monthListSelected: false, isSelectedFilter: this.filterChangeSelection
          });
        }
      }
    } else {
      this.filterValues.emit({ typeOfFilter: 'filter-changed', year: '', month: '', date: '', hour: '', shift: '' });
    }
  }

  timeFilterSelection(filterSelected) {

    this.selectedTimeFilter = filterSelected;
    this.filterChangeSelection = true;
    this.disableOkButton();
    this.showShift = false;
    this.showHourFilter = true;
    this.selectedPrevious = false;
    this.selectedPreviousQuarter = false;

    if (this.monthFilterYearSelected === this.minimumYear) {
      this.previousYearDisabled = true;
      this.nextYearDisabled = false;
    } else if (this.monthFilterYearSelected === this.currentYear) {
      this.nextYearDisabled = true;
      this.previousYearDisabled = false;
    } else {
      this.nextYearDisabled = false;
      this.previousYearDisabled = false;
    }

    if (this.selectedTimeFilter.code === 'year') {
      this.showMonthFilterYear = true;
      this.ShowMonthFilterMonth = false;
      if (this.multipleSelection) {
        this.quarterList.filter(list => list.isSelected = false);
        this.monthList.filter(list => list.isSelected = false);
      } else {
        this.quarterListNew.filter(list => list.isSelected = false);
        this.monthList.filter(list => list.isSelected = false);
      }
      this.monthFilterQuaterSelected = '';
      this.monthFilterMonthSelected = -1;
      if (this.monthFilterYearSelected !== '') {
        this.filterValues.emit({
          typeOfFilter: 'year', year: this.monthFilterYearSelected,
          month: '', date: '', hour: '', shift: ''
        });
      }
    } else if (this.selectedTimeFilter.code === 'month') {
      this.ShowMonthFilterMonth = true;
      this.showMonthFilterYear = false;
      if (this.multipleSelection) {
        this.monthList.filter(list => list.isSelected = false);

        this.monthFilterSelectedMonth = this.monthFilterYearSelected;
        this.singleSelection = false;

      } else {
        this.quarterList.filter(list => list.isSelected = false);
      }
      this.monthFilterQuaterSelected = '';
      if (this.monthFilterYearSelected !== '' && this.monthFilterMonthSelected !== -1) {
        this.filterValues.emit({
          typeOfFilter: 'month', year: this.monthFilterYearSelected,
          month: this.monthFilterMonthSelected, date: '', hour: '', shift: ''
        });
      }
    } else if (this.selectedTimeFilter.code === 'quarter') {
      this.ShowMonthFilterMonth = true;
      this.showMonthFilterYear = false;
      this.monthFilterMonthSelected = -1;
      if (this.multipleSelection) {
        this.quarterList.filter(list => list.isSelected = false);

        this.monthFilterSelectedQuarter = this.monthFilterYearSelected;
        // this.monthFilterSelectedMonth = this.monthFilterYearSelected;
        this.singleQuarterSelection = false;
      }
    }
    console.log("outfunc", this.quarterList);
  }

  monthFilterYear(year) {
    this.monthFilterYearSelected = year;
    this.monthFilterMonthSelected = -1;

    if (this.multipleSelection) {
      this.selectQuarterPrevious(this.monthFilterYearSelected + 1);
      this.selectMonthPrevious(this.monthFilterYearSelected + 1);
      /* istanbul ignore next */
      this.filterValues.emit({
        typeOfFilter: 'year', year: this.monthFilterYearSelected,
        month: '', date: '', hour: '', shift: '', selectedData: true
      });
    } else {
      this.filterValues.emit({
        typeOfFilter: 'year', year: this.monthFilterYearSelected,
        month: '', date: '', hour: '', shift: ''
      });
    }
  }

  monthFilterMonth(month) {
    if (!this.multipleSelection) {
      if (month && (month.code <= this.currentMonth ||
        (this.monthFilterYearSelected !== this.currentYear && month.code > this.currentMonth))) {
        this.monthFilterMonthSelected = month.code;
        this.filterValues.emit({
          typeOfFilter: 'month', year: this.monthFilterYearSelected,
          month: this.monthFilterMonthSelected, date: '', hour: '', shift: ''
        });
        this.monthFilterQuaterSelected = undefined;
      }
    } else {
      this.selectedPrevious = false;
      let selectedObject = this.monthList.filter(listObj => {
        if (listObj.isSelected) {
          return listObj.code;
        }

      }).length;
      if (month) {
        // this.singleSelection =false;
        if (selectedObject === 0) {
          this.singleSelection = false;
          // this.finalYear=this.monthFilterYearSelected;
          this.monthList.filter(listObj => listObj.label === month.label && listObj.year === month.year)[0].isSelected = true;
          console.log(12345, this.monthList.filter(listObj => listObj.isSelected)[0].isSelected);

          // console.log(123456,this.quarterList.filter(listObj => listObj.isSelected)[0].startDate);
        } else {
          // get first and last selection
          /* istanbul ignore next */
          this.singleSelection = false;
          let firstSelection = -1;
          let lastSelection = -1;
          /* istanbul ignore next */
          for (var i = 0; i <= this.monthList.length - 1; i++) {
            if (this.monthList[i].isSelected) {
              if (firstSelection == -1) {
                firstSelection = i;
                //  console.log(2222,this.quarterList[firstSelection].startDate);
                lastSelection = i;
                //  console.log(3322,this.quarterList[lastSelection].endDate);
              } else {
                lastSelection = i;
                //  console.log(4444,this.quarterList[lastSelection].endDate);
              }

            }
            if (month.code === this.monthList[i].code && month.year === this.monthList[i].year) {
              var currentSelected = i;
            }
          }
          /* istanbul ignore next */
          var selectedMonthList = this.monthList.filter(list => list.isSelected);
          /* istanbul ignore next */
          if (currentSelected < firstSelection - 1 || currentSelected > lastSelection + 1) {
            this.monthList.filter(list => list.isSelected = false);
            this.monthList.filter(listObj => listObj.label === month.label && listObj.year === month.year)[0].isSelected = true;
            // this.monthStartCalendar = selectedMonthList[0].startMonth;
            /* istanbul ignore next */
          } else if ((currentSelected == firstSelection - 1 || currentSelected == lastSelection + 1) && selectedMonthList.length < 12) {
            this.monthList.filter(listObj => listObj.label === month.label && listObj.year === month.year)[0].isSelected = true;
            /* istanbul ignore next */
          } else if (currentSelected == firstSelection || currentSelected == lastSelection) {
            if (selectedMonthList.length == 1) {
              this.monthList.filter(listObj => listObj.label === month.label && listObj.year === month.year)[0].isSelected = true;

            } else {
              /* istanbul ignore next */
              this.monthList.filter(listObj => listObj.label === month.label && listObj.year === month.year)[0].isSelected = false;
            }
            // this.monthStartCalendar = selectedMonthList[0].startMonth;
          } else {
            /* istanbul ignore next */
            for (let index = 0; index < selectedMonthList.length; index++) {
              /* istanbul ignore next */
              if (selectedMonthList.length === 12 && selectedMonthList[index].year !== this.monthFilterYearSelected) {
                this.selectedPrevious = true;
              } else {
                /* istanbul ignore next */
                this.selectedPrevious = false;
              }
            }

          }
        }

        selectedMonthList = this.monthList.filter(list => list.isSelected);
        if (selectedMonthList.length > 0) {
          this.monthStartCalendar = selectedMonthList[0].label + " " + selectedMonthList[0].year;
          if (selectedMonthList[selectedMonthList.length - 1].label == 'FEB') {
            // logic for leap year
            /* istanbul ignore next */
            const leapYear = new Date(this.monthFilterYearSelected, 1, 29);
            /* istanbul ignore next */
            if (leapYear.getMonth() === 1) {
              /* istanbul ignore next */
              selectedMonthList[selectedMonthList.length - 1].endDate = '02-29';
            } else {
              /* istanbul ignore next */
              selectedMonthList[selectedMonthList.length - 1].endDate = '02-28';
            }

            // new code
          }
          // var monthEnd = selectedMonthList[selectedMonthList.length - 1].startMonth;
          // this.finalYear=selectedMonthList[selectedMonthList.length - 1].year;
          // test code start
          this.monthEndCalendar = selectedMonthList[selectedMonthList.length - 1].label + " " + selectedMonthList[selectedMonthList.length - 1].year;
          if (selectedMonthList.length === 1) {
            this.monthFilterSelectedMonth = selectedMonthList[0].label + " " + selectedMonthList[0].year;
          } else if (selectedMonthList.length > 1) {
            this.monthFilterSelectedMonth = selectedMonthList[0].label + " " + selectedMonthList[0].year + " " + "to" + " " + this.monthEndCalendar;
          }
          // test code end        

          /* istanbul ignore next */
          if (month && (month.code <= this.currentMonth + 1 ||
            (this.monthFilterYearSelected !== this.currentYear && month.code > this.currentMonth))) {
            this.monthFilterMonthSelected = month.code;
            this.filterValues.emit({
              typeOfFilter: 'month', year: this.monthFilterYearSelected,
              startYear: selectedMonthList[0].year, endYear: selectedMonthList[selectedMonthList.length - 1].year,
              month: this.monthFilterMonthSelected, date: '', hour: '', shift: '', startDate: selectedMonthList[0].year
                + '-' + selectedMonthList[0].startDate, endDate: selectedMonthList[selectedMonthList.length - 1].year + '-' +
                  selectedMonthList[selectedMonthList.length - 1].endDate, selectedData: true
            });
            this.monthFilterQuaterSelected = undefined;
            this.selectedPrevious = true;
            // this.monthFilterMonthSelected = -1;
          }

        } /* else {
        this.filterValues.emit();
      } */
      }
    }
  }

  monthFilterYearChange() {
    this.showMonthFilterYear = true;
    this.ShowMonthFilterMonth = false;
    this.ShowMonthFilterheader = false;
  }

  monthFilterPreviousYear() {
    if (!this.multipleSelection) {
      // insight old code starts
      this.quarterListNew.filter(list => list.isSelected = false);
      // insight old code ends
    } else {
      // newcode for filtering month and quarter logic based on filter selected
      this.selectQuarterPrevious(this.monthFilterYearSelected);
      this.selectMonthPrevious(this.monthFilterYearSelected);
    }
    this.previousYearDisabled = false;
    this.nextYearDisabled = false;
    // const quarterListNew = this.quarterList;
    if ((this.monthFilterYearSelected - 1) >= this.minimumYear) {
      this.monthFilterYearSelected--;
      this.monthFilterMonthSelected = -1;
      this.monthFilterQuaterSelected = undefined;
      this.disableOkButton();
    }
    if (this.monthFilterYearSelected === this.minimumYear) {
      this.previousYearDisabled = true;
    }

  }

  // quarter selection on previous year logic
  selectQuarterPrevious(yearSelected) {
    var selectedQuarterListOnPrevious = this.quarterList.filter(list => list.isSelected);
    if (selectedQuarterListOnPrevious.length == 0) {
      this.monthFilterSelectedQuarter = yearSelected - 1;
      this.selectedPreviousQuarter = false;
    } else {
      this.selectedPreviousQuarter = true;
    }
    this.quarterFlag = false;
    for (var i = 0; i <= this.quarterList.length - 1; i++) {
      if ((yearSelected - 1) == this.quarterList[i].year) {
        this.quarterFlag = true;
      }
    }
    // new code
    this.quarterListNew.forEach(element1 => {
      element1.year = yearSelected - 1;
    });
    // logic for disabling moths prior 36 months
    if (!this.quarterFlag) {
      this.quarterList = JSON.parse(JSON.stringify(this.quarterListNew.concat(this.quarterList)));
    }
    // this.quarterList.forEach(quarterData => {
    //   if (this.baseYear >= this.monthFilterSelectedQuarter) {
    //     if (this.minimumYear === this.monthFilterSelectedQuarter) {
    //       if (quarterData.code <= this.currentMonth && quarterData.year === this.minimumYear) {
    //         quarterData.quarterDisabled = true;
    //       }
    //     } else {
    //       quarterData.quarterDisabled = false;
    //     }
    //   }
    // });
    // end
  }

  selectMonthPrevious(yearSelected) {
    this.filterChangeSelection = true;
    // for changing calendar header year
    var selectedMonthListOnPrevious = this.monthList.filter(list => list.isSelected);
    if (selectedMonthListOnPrevious.length == 0) {
      this.monthFilterSelectedMonth = yearSelected - 1;
      this.selectedPrevious = false;
    } else {
      this.selectedPrevious = true;
    }
    // end
    // logic for reventing insert if same year is visited
    this.monthFlag = false;
    for (var i = 0; i <= this.monthList.length - 1; i++) {
      if ((yearSelected - 1) == this.monthList[i].year) {
        this.monthFlag = true;
      }
    }
    // new code
    this.monthListNew.forEach(element => {
      element.year = yearSelected - 1;
    });

    if (!this.monthFlag) {
      this.monthList = JSON.parse(JSON.stringify(this.monthListNew.concat(this.monthList)));
    }
    // this.disableMonths(this.monthFilterYearSelected - 1);
    // endcode
  }

  monthFilterYearNextChange() {
    if (!this.multipleSelection) {
      // insight old code starts
      this.quarterListNew.filter(list => list.isSelected = false);
      // insight old code ends
    } else {
      // this.selectedPrevious = true;
      // this.selectedPreviousQuarter = true;
      var selectedQuarterListOnPrevious = this.quarterList.filter(list => list.isSelected);
      var selectedMonthListOnPrevious = this.monthList.filter(list => list.isSelected);
      this.monthFlag = false;
      this.quarterFlag = false;
      /* istanbul ignore next */
      for (var i = 0; i <= this.monthList.length - 1; i++) {
        if (this.monthFilterYearSelected + 1 == this.monthList[i].year) {
          this.monthFlag = true;
        }
      }
      // new code
      /* istanbul ignore next */
      this.monthListNew.forEach(element => {
        element.year = this.monthFilterYearSelected + 1;
      });
      /* istanbul ignore next */
      if (!this.monthFlag) {
        this.monthList = JSON.parse(JSON.stringify(this.monthList.concat(this.monthListNew)));
      }
      /* istanbul ignore next */
      this.monthList.sort((a, b) => a.year - b.year);

      for (var i = 0; i <= this.quarterList.length - 1; i++) {
        if (this.monthFilterYearSelected + 1 == this.quarterList[i].year) {
          this.quarterFlag = true;
        }
      }
      // new code
      /* istanbul ignore next */
      this.quarterListNew.forEach(element => {
        element.year = this.monthFilterYearSelected + 1;
      });
      /* istanbul ignore next */
      if (!this.quarterFlag) {
        this.quarterList = JSON.parse(JSON.stringify(this.quarterListNew.concat(this.quarterList)));
      }
      /* istanbul ignore next */
      this.quarterList.sort((a, b) => a.year - b.year);
      if (selectedQuarterListOnPrevious.length == 0) {
        this.monthFilterSelectedQuarter = this.monthFilterYearSelected + 1;
        this.selectedPreviousQuarter = false;
      } else {
        this.selectedPreviousQuarter = true;
      }
      if (selectedMonthListOnPrevious.length == 0) {
        /* istanbul ignore next */
        this.monthFilterSelectedMonth = this.monthFilterYearSelected + 1;
        /* istanbul ignore next */
        this.selectedPrevious = false;
      } else {
        this.selectedPrevious = true;
      }
      console.log("multiple selection", this.quarterList);
    }
    this.previousYearDisabled = false;
    this.nextYearDisabled = false;

    if ((this.monthFilterYearSelected + 1) <= this.currentYear) {
      this.monthFilterYearSelected++;
      this.monthFilterMonthSelected = -1;
      this.monthFilterQuaterSelected = undefined;

      this.disableOkButton();
    }

    if (this.monthFilterYearSelected === this.currentYear) {
      this.nextYearDisabled = true;

    }
  }

  onWeekSelect(event) {
    this.selectedWeek = event;

    // for (let i = 0; i < 7; i++) {
    //   this.daysWeekSelected.push(new Date(this.selectedWeek.getFullYear(), this.selectedWeek.getMonth(),
    //     this.selectedWeek.getDate() + i));
    // }
    this.daysWeekSelected = new Date(this.selectedWeek.getFullYear(), this.selectedWeek.getMonth(), this.selectedWeek.getDate() + 6);

    this.filterValues.emit({
      typeOfFilter: 'week', year: this.selectedWeek.getFullYear(),
      month: (this.selectedWeek.getMonth()), date: this.selectedWeek.getDate(), hour: '', shift: ''
    });
  }

  // WeekDateClass() {
  //   return (date: Date): MatCalendarCellCssClasses => {
  //     const highlightDate = this.daysWeekSelected
  //       .map(strDate => new Date(strDate))
  //       .some(d => d.getDate() === date.getDate() && d.getMonth() === date.getMonth() && d.getFullYear() === date.getFullYear());
  //     return highlightDate ? 'special-date' : '';
  //   };
  // }

  onDateSelect(event) {
    this.selectedDate = event;

    this.filterValues.emit({
      typeOfFilter: 'date', year: this.selectedDate.getFullYear(),
      month: (this.selectedDate.getMonth()), date: this.selectedDate.getDate(), hour: '', shift: ''
    });
  }

  onShiftDateSelect(event) {
    this.selectedShift = undefined;
    this.selectedShiftDate = event;
    this.showShift = true;
    let shiftCount = 0;
    this.shiftValues = '';
    this.shiftFilters = [];

    this.service.getShiftList(this.dataService.getData('lineVisualizationFilter'),
      this.dataService.getData('lineDetailFilter'),
      formatDate(this.selectedShiftDate, 'yyyy-MM-dd', 'en'))
      // .pipe(takeUntil(this.destroy$))
      .subscribe(result => {
        if (result) {
          if (result.result) {
            shiftCount = result.result.count;
            this.shiftValues = result.result.shiftData;
          }

          for (let i = 1; i <= shiftCount; i++) {
            this.shiftFilters.push({
              label: 'Shift ' + i,
              code: i
            });
          }
        }
      });
  }

  onShiftChange() {
    this.selectedShiftValue = this.shiftValues[this.selectedShift - 1];

    this.filterValues.emit({
      typeOfFilter: 'shift', year: this.selectedShiftDate.getFullYear(),
      month: (this.selectedShiftDate.getMonth()), date: this.selectedShiftDate.getDate(), hour: '',
      shift: { shift: this.selectedShift, shiftTIme: this.selectedShiftValue }
    });
  }

  onShiftDateChange(item) {
    if (item.toElement.className === 'mat-button-wrapper' ||
      item.toElement.className === 'mat-calendar-next-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused' ||
      item.toElement.className === 'mat-calendar-previous-button mat-icon-button mat-button-base cdk-focused cdk-mouse-focused' ||
      item.toElement.className === 'mat-calendar-period-button mat-button mat-button-base') {
      this.showShift = false;
    }
  }

  onHourDateSelect(event) {
    this.selectedHr = -1;
    this.selectedHourDate = event;
    this.showHourFilter = true;
  }

  onHourChange(item) {
    this.selectedHr = item;

    this.filterValues.emit({
      typeOfFilter: 'hour', year: this.selectedHourDate.getFullYear(),
      month: (this.selectedHourDate.getMonth()), date: this.selectedHourDate.getDate(), hour: this.selectedHr, shift: ''
    });
  }

  editHourSelectedDate() {
    this.showHourFilter = false;
    this.selectedHourDate = '';
  }

  disableTypes(arr) {
    arr.forEach(disableCode => {
      for (const x of this.timeFilterList) {
        if (x.code === disableCode) {
          x.disabled = true;
          break;
        }
      }
    });
  }

  // method to disable months prior to 36 months

  // disableMonths(year) {
  //   this.monthList.forEach(monthData => {
  //     if (this.baseYear >= year) {
  //       if (this.minimumYear === year) {
  //         if (monthData.code <= this.currentMonth && monthData.year === this.minimumYear) {
  //           monthData.monthDisabled = true;
  //         }
  //       }
  //     }
  //   });
  // }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
