import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-exception-handling',
  templateUrl: './exception-handling.component.html',
  styleUrls: ['./exception-handling.component.scss']
})
export class ExceptionHandlingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
