import { Inject, Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { NgxPermissionsService } from 'ngx-permissions';
import { NetworkService } from 'src/app/core/services/network.service';
import { map, catchError } from 'rxjs/operators';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {
  roles: any[] = [
    'clusterdv',
    'countrydv',
    'deo',
    'dfos-reader',
    'factory-manager',
    'factory-supervisor',
    'ga',
    'gm',
    'key',
    'line-operator',
    'line-supervisor',
    'mdcs-ga',
    'production-admin',
    'sitedv',
    'support',
    'technical-admin',
    'technical-operator'
  ];

  isBrowser: boolean;

  authorizedEmails: string[] = [
    'andrei.bulgaru@unilever.com',
    'nisha.rathi@unilever.com',
    'jose.mathew@unilever.com',
    'muneer.pj@unilever.com',
    'm.shilpa@unilever.com',
    'mageshwari.j@unilever.com',
    'gaurav.singh22@unilever.com',
    'nimisha.p@unilever.com',
    'shiljith.mp@unilever.com',
    'yogananth.vanniarajan@unilever.com',
    'smitha.sajith@unilever.com',
    'bhavana.chava@unilever.com',
    'koshal.desai@unilever.com',
    'prasanth.e2@unilever.com',
    'shinshinu.vh@unilever.com',
    'amruthkrishna.g@unilever.com',
    'monika.budna@unilever.com',
    'kaya.gulkaya@unilever.com',
    'ozgur.akinci@unilever.com',
    'priyanka.goel@unilever.com',
    'geethesh.sidda2@unilever.com',
    'shananth.appallappa@unilever.com',
    'Serar.Baysan@unilever.com',
    'serdar.baysan@unilever.com',
    'parvathy.ashok@unilever.com',
    'mohammed.hussain@unilever.com',
    'neethu.sj@unilever.com',
    'sadaf.rakshan2@unilever.com',
    'jigar.desai2@unilever.com',
    'jigar.sdesai@unilever.com',
    'sanjay.m@unilever.com',
    'ashwin.k@unilever.com',
    'LineSupervisor.Tester@unilever.com',
    'FactorySupervisor.Tester@unilever.com',
    'attoli.ramu@unilever.com',
    'sarika.s@unilever.com',
    'NGTW.PerfGA@unilever.com',
    'borah.anisha@unilever.com',
    'aamit.maurya@unilever.com',
    'uddipta.pathak@unilever.com',
    'ganesh.velayudhan@unilever.com',
    'sharat.sugur@unilever.com',
    'jisna.das@unilever.com',
    'venkata.ravi@unilever.com',
    'ramesh.gumudavelli@unilever.com',
    'vivekkumar.singh@unilever.com',
    'chaitanya.mishra@unilever.com',
    'karthi.r@unilever.com',
    'neha.sahu@unilever.com',
    'biplab.sur@unilever.com',
    'laxmikant.ghode@unilever.com',
    'bijith.kb@unilever.com',
    'geethan.kasiananthan@unilever.com',
    'AutoTesting1.NGTW@unilever.com',
    'Harika.Posam@unilever.com',
    'narendradas.tripurana@unilever.com',
    'Sarah.Baligwas@unilever.com',
    'rajnikant.bajpai@unilever.com',
    'shruti.kumari@unilever.com',
    'anshuman.acharya@unilever.com',
    'priya.jain2@unilever.com',
    'chaitanya.ambaskar@unilever.com',
    'karthikeyan.r3@unilever.com',
    'aniket.jain2@unilever.com',
    'raju.palaji@unilever.com',
    'sabyasachi.das2@unilever.com',
    'sumit.karade@unilever.com',
    'avinash.pawar2@unilever.com',
    'venkatesh.baliga@unilever.com',
    'vasantakiran.chandaluri@unilever.com',
    'jitendra.gupta3@unilever.com'
  ];

  constructor(
    // private adal: MsAdalAngular6Service,
    private permissionsService: NgxPermissionsService,
    private http: NetworkService,
    private authService: MsalService,
    @Inject(PLATFORM_ID) platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    /* DON'T PUSH 'loggedInUser' LOGIG TO PPD OR PROD - THIS IS ONLY OF TESTING IN QA */
    let loggedInUser = '';
    let currentURL = '';

    if (this.isBrowser) {
      currentURL = window.location.href;
    }

    // Comment this code of loggedInUser while pushing to ppd and prod
    if (this.authService.getAccount() && this.authService.getAccount().userName && this.authService.getAccount().userName !== '') {
      loggedInUser = this.authService.getAccount().userName.toLowerCase();
    }
    let role = 'no-role';
    // const profile = this.adal.userInfo.profile;
    //   window.location.assign('https://ngtw.unilever.com');
    // } else {
    if (currentURL && (currentURL.includes('ngtw-qa') || currentURL.includes('ngtw-dev')) && loggedInUser && loggedInUser !== '' &&
      this.authorizedEmails.findIndex(email => email.toLowerCase() === loggedInUser) < 0) {

      window.location.assign('https://ngtw.unilever.com');
    } else {
      const role = 'no-role';
      //  const profile = this.adal.userInfo.profile;
      // if (profile.roles) {
      //   // role = profile.roles[0];
      //   role = profile.roles.toLocaleString().toLowerCase().split(' ').join('-').split(',').find(val => this.roles.includes(val)) ?
      //     profile.roles.toLocaleString().toLowerCase().split(' ').join('-').split(',').find(val => this.roles.includes(val)) :
      //     'no-role';
      // }

      // if (profile.roles) {
      //   role = profile.roles[0];
      // }

      return this.http.getPermissions(role).pipe(
        map((permission) => {
          this.permissionsService.loadPermissions(permission);
          return true;
        }),
        catchError(() => of(true))
      );
    }


    return this.http.getPermissions(role).pipe(
      map((permission) => {
        this.permissionsService.loadPermissions(permission);
        return true;
      }),
      catchError(() => of(true))
    );
  }
}
