import { Component, OnInit, Input, OnChanges, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { DataService } from '../../service/data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-overlay-loader',
  templateUrl: './overlay-loader.component.html',
  styleUrls: ['./overlay-loader.component.scss']
})

export class OverlayLoaderComponent implements OnInit, OnDestroy {
  showLoader: any = false;
  spinner: any;
  currentLink: any;

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit() {
    this.dataService.on('loader', this.setFlag);
    this.currentLink = '';
  }

  setFlag = (flag: any) => {
    this.showLoader = flag;
    const key = 'destroyed';
    this.currentLink = '';
    let tempLinkArr = [];

    if (!this.cdr[key]) {
      this.cdr.detectChanges();
    }

    tempLinkArr = this.router.url.split('/');
    this.currentLink = tempLinkArr[tempLinkArr.length - 1];
  }

  ngOnDestroy(): void {
    this.dataService.removeAllListnersFor('loader');
    // if (this.spinner) {
    //   this.spinner.unsubscribe();
    // }
  }
}
