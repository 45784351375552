import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoaderHandlerService } from 'src/app/shared/service/loader-handler.service';
import { EnvironmentService } from './environment.service';
import { Observable, of } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
// import { AdalService } from 'adal-angular4';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
// import { MsalService, BroadcastService } from '@azure/msal-angular';
import { AuthService } from './auth.service.service';
import { ToasterHandlerService } from 'src/app/shared/service/toaster-handler.service';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};
/* istanbul ignore next */
const pluck = key => array => Array.from(new Set(array.map(obj => obj[key])));

@Injectable()
export class NetworkService {
  /* istanbul ignore next */
  requestType: any = '';
  /* istanbul ignore next */
  endUrl: any = '';
  /* istanbul ignore next */
  constructor(

    private http: HttpClient,
    private loaderHandler: LoaderHandlerService,
    private env: EnvironmentService,
    private toastr: ToasterHandlerService,
    // private adal: AdalService
    public auth: AuthService
  ) { }

  /* istanbul ignore next */
  setHeaders(isFile: any) {

    if (!isFile) {
      /* istanbul ignore next */
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // 'x-functions-key': this.env.getAPIKey(),
          'Ocp-Apim-Subscription-Key': this.env.getSubscriptionID()
        })
      };
    }
    /* istanbul ignore next */
    return {

      headers: new HttpHeaders({
        Accept: 'application/json',
        'Ocp-Apim-Subscription-Key': this.env.getSubscriptionID()
      })
    };
  }
  /* istanbul ignore next */
  httpReq(reqType: string, endURL: string, data?: any, isFile = false, isLoader: boolean = true): Observable<any> {
    // const fullURL = this.env.getAPIBaseURL() + endURL;
    let fullURL;
    /* istanbul ignore next */
    this.requestType = reqType;
    /* istanbul ignore next */
    this.endUrl = endURL;
    /* istanbul ignore next */
    if (reqType === 'get-dfos') {
      /* istanbul ignore next */
      fullURL = this.env.getDFOSURL() + endURL;
      reqType = 'get';
    } else if (reqType === 'get-mdcs') {
      /* istanbul ignore next */
      fullURL = this.env.getMDCSURL() + endURL;
      reqType = 'get';
    } else if (reqType === 'get-file') {
      /* istanbul ignore next */
      fullURL = this.env.getFileURL() + endURL;
      reqType = 'get';
    } else {
      /* istanbul ignore next */
      fullURL = this.env.getAPIBaseURL() + endURL;
    }
    // const fullURL = this.env.getAPIBaseURL() + endURL + '?code=' + this.env.getAPIKey();
    /* istanbul ignore next */
    if (isLoader) {
      /* istanbul ignore next */
      this.loaderHandler.toggleLoader(1);
    }
    /* istanbul ignore next */
    switch (reqType) {
      case 'get':
        /* istanbul ignore next */
        const encodedURL = this.encodeURL(fullURL, data);
        return this.http.get(encodedURL, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'post':
        /* istanbul ignore next */
        return this.http.post(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'put':
        /* istanbul ignore next */
        return this.http.put(fullURL, data, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );

      case 'delete':
        /* istanbul ignore next */
        const urlD = this.encodeURL(fullURL, data);
        return this.http.delete(urlD, this.setHeaders(isFile)).pipe(
          tap(res => this.handleHTTPSuccess(res)),
          catchError(this.handleError('error', []))
        );
    }
  }
  /* istanbul ignore next */
  handleError<T>(operation = 'operation', result?: T) {
    /* istanbul ignore next */
    return (error: any): Observable<T> => {
      /* istanbul ignore next */
      const errMsg = 'Something went wrong.';
      this.loaderHandler.toggleLoader(0);
      /* istanbul ignore next */
      if (error.status === 401) {
        /* istanbul ignore next */
        this.auth.loginRedirect();
      } else if (error.status === 404) {
        /* istanbul ignore next */
        if (this.requestType === 'get-dfos') {
          /* istanbul ignore next */
          const slashSplit = this.endUrl.split('/');
          if (slashSplit && slashSplit.length > 0) {
            /* istanbul ignore next */
            const apiURL = slashSplit[slashSplit.length - 1].split('?');
            if (apiURL && apiURL[0] && apiURL[0] === 'loss') {
              /* istanbul ignore next */
              return of(result as T);
            } else {
              /* istanbul ignore next */
              this.showError(errMsg);
            }
          } else {
            /* istanbul ignore next */
            this.showError(errMsg);
          }
        } else {
          /* istanbul ignore next */
          this.showError(errMsg);
        }
      } else {
        /* istanbul ignore next */
        if (this.endUrl !== 'ManualWasteEntry') {
          this.showError(errMsg);
        }
      }
      return of(result as T);
    };
  }
  /* istanbul ignore next */
  handleHTTPSuccess(result) {
    /* istanbul ignore next */
    this.loaderHandler.toggleLoader(0);
    return result;
  }
  /* istanbul ignore next */
  showError(errorMsg) {
    // this.loaderHandler.toggleLoader(0);
    // this.toastr.showToast('failure', errorMsg, 'FAILED');
  }
  /* istanbul ignore next */
  encodeURL(url, data) {
    /* istanbul ignore next */
    let encodedUrl = url;
    if (data && Object.keys(data) && Object.keys(data).length) {
      /* istanbul ignore next */
      encodedUrl =
        encodedUrl +
        '?' +
        Object.keys(data)
          .map((k) => {
            /* istanbul ignore next */
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k]);
          })
          .join('&');
    }
    /* istanbul ignore next */
    return encodedUrl;
  }
  /* istanbul ignore next */
  getPermissions(role: any = 'no-role') {
    /* istanbul ignore next */
    const splits = role.toLowerCase().split(' ');
    const fileName = splits.join('-') + '.json';
    // const fullURL = this.env.getAPIBaseURL('masterConfig') + 'masterconfigs/roles/SPC';
    // return this.http.get('/assets/permissions/' + fileName).
    // return this.http.get(fullURL).
    //   pipe(
    //   map((data: any) => data.UserRolesList.filter((item: any) => item.IsActive)[0]),
    //   map((data: any) => data.accessRights),
    //   map((data: any) => data.filter((item: any) => item.isPresent)),
    //   map((data: any) => this.processPermission(data))
    //   );

    // return this.http.get('/assets/permissions/' + 'support.json').
    return this.http.get('/assets/permissions/' + fileName).
      pipe(
        /* istanbul ignore next */
        map((data: any) => data.accessRights),
        map((data: any) => data.filter((item: any) => item.isPresent)),
        map((data: any) => this.processPermission(data))
      );
  }
  /* istanbul ignore next */
  processPermission(data: any): any {
    /* istanbul ignore next */
    const perms = pluck('name');
    return perms(data);
  }
}
