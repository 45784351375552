import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class LoaderHandlerService {
  /* istanbul ignore next */
  outgoingServicesCount = 0;
  /* istanbul ignore next */
  constructor(private dataService: DataService) { }
  /* istanbul ignore next */
  toggleLoader(serviceFlag: number) {
    switch (serviceFlag) {
      case 0:
        // service returned response
        this.outgoingServicesCount -= 1;
        if (this.outgoingServicesCount <= 0) {
          this.outgoingServicesCount = 0;
          this.dataService.broadcast('loader', false);
        }
        break;
      case 1:
        // service called
        this.outgoingServicesCount += 1;
        this.dataService.broadcast('loader', true);
        break;
    }
  }

}
