import { Component, OnInit, Inject, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SharedService } from '../../service/shared.service';
import { ToasterHandlerService } from '../../service/toaster-handler.service';
import { WasteInfoComponent } from '../waste-info/waste-info.component';
// import { AdalService } from 'adal-angular4';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service.service';

@Component({
  selector: 'app-waste-input',
  templateUrl: './waste-input.component.html',
  styleUrls: ['./waste-input.component.scss']
})
export class WasteInputComponent implements OnInit, OnChanges, OnDestroy {
  @Input() machineDtls: any;
  @ViewChild('commentAdded', { static: false }) commentAdded: ElementRef;
  @ViewChild('commentUpdated', { static: false }) commentUpdated: ElementRef;


  destroy$: Subject<boolean> = new Subject<boolean>();
  public description: string;
  public validationPattern = new RegExp('^[a-zA-Z0-9@#$%. \n]*$');
  public form: FormGroup;
  public commentId: any;
  public comment: any = {
    Who: null,
    What: null,
    whenFromTimeEpoch: null,
    whenToTimeEpoch: null,
    Where: null,
    Why: null,
    How: null,
    GranularityLevel: null,
    GranularityId: null,
    GranularityValue: null,
    Id: null
  };

  public addTransformerWho = false;
  public addTransformerWhat = false;
  public addTransformerToDate = false;
  public addTransformerFromDate = false;
  public addTransformerWhere = false;
  public addTransformerWhy = false;
  public addTransformerHow = false;

  public changedValue = false;
  public addUpdateFlag = 'add';
  public machineDetail: any;
  public machineName: any;
  public machineType: any = 'Machines';
  public machineSequenceNumber: any;
  public fromDateTime: any;
  public toDateTime: any;

  public selectedMoment = new Date();
  public min = new Date(2010, 0, 1);
  public max = new Date(this.selectedMoment.getFullYear(), this.selectedMoment.getMonth(),
    this.selectedMoment.getDate(), this.selectedMoment.getHours(), this.selectedMoment.getMinutes(), 59);
  showToDate: boolean;
  fromDateTimeDisplay = '';
  toDateTimeDisplay = '';

  constructor(
    public dialogRef: MatDialogRef<WasteInfoComponent>,
    public service: SharedService,
    private toastr: ToasterHandlerService,
    private toastrService: ToastrService,
    // private adal: AdalService,
    public auth: AuthService,
    public datepipe: DatePipe
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.machineDetail = this.machineDtls;
    this.machineSequenceNumber = this.machineDtls.sequenceNumber;
  }

  ngOnInit() {
    if (this.auth.checkIsAuthticated) {
      this.comment.Who = this.auth.getAllAccountInfo;
    }

    this.showToDate = false;
    this.setMaxDate();
  }


  validate_form(form) {
    if (form.valid) {
      this.saveUpdateComment();
      this.dialogRef.close();
    }
  }

  valueChanged(valueEntered: any, changedFilter?: any) {
    this.changedValue = false;

    if (changedFilter && changedFilter === 'fromDateChanged') {
      this.min = this.fromDateTime;
      this.showToDate = true;
      this.fromDateTimeDisplay = this.datepipe.transform(this.fromDateTime, 'dd/MMM/yyyy, h:mm a');
      this.toDateTimeDisplay = '';
      this.toDateTime = '';
    } else if (changedFilter && changedFilter === 'toDateChanged') {
      this.toDateTimeDisplay = this.datepipe.transform(this.toDateTime, 'dd/MMM/yyyy, h:mm a');
    }

    if (((this.fromDateTime && this.toDateTime) && (this.fromDateTime < this.toDateTime)) &&
      (this.comment.What && this.comment.What.trim().length > 0) &&
      (this.comment.Where && this.comment.Where.trim().length > 0) &&
      (this.comment.Why && this.comment.Why.trim().length > 0) &&
      (this.comment.How && this.comment.How.trim().length > 0)) {
      this.changedValue = true;
    }
  }

  saveUpdateComment() {
    this.comment.GranularityLevel = this.machineType;
    this.comment.MachineId = this.machineDetail.id;
    this.comment.GranularityValue = this.machineDetail.name;

    if (this.toDateTime) {
      this.comment.WhenToTime = Math.floor(+(new Date(this.toDateTime.getFullYear(),
        this.toDateTime.getMonth(), this.toDateTime.getDate(),
        this.toDateTime.getHours(), this.toDateTime.getMinutes())) / 1000);
    }

    if (this.fromDateTime) {
      this.comment.WhenFromTime = Math.floor(+(new Date(this.fromDateTime.getFullYear(),
        this.fromDateTime.getMonth(), this.fromDateTime.getDate(),
        this.fromDateTime.getHours(), this.fromDateTime.getMinutes())) / 1000);
    }

    if (this.addUpdateFlag === 'update') {
      this.comment.Id = this.commentId;
    }

    this.service.saveUpdateComment(this.comment, this.addUpdateFlag)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        // const message = this.addUpdateFlag === 'add' ? '5W1H data added.' : '5W1H data updated.';
        if (data && data.message &&
          (data.message === 'Resource created successfully') || (data.message === 'Resource updated successfully')) {
          // this.toastr.showToast('success', message, 'SUCCESS');
          if (this.addUpdateFlag === 'add') {
            this.toastrService.success(this.commentAdded.nativeElement.innerHTML, '', { enableHtml: true });
          } else {
            this.toastrService.success(this.commentUpdated.nativeElement.innerHTML, '', { enableHtml: true });
          }
        }
      });
  }

  setMaxDate() {
    this.selectedMoment = new Date();

    this.max = new Date(this.selectedMoment.getFullYear(), this.selectedMoment.getMonth(),
      this.selectedMoment.getDate(), this.selectedMoment.getHours(), this.selectedMoment.getMinutes(), 59);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
