import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { AuthService } from 'src/app/core/services/auth.service.service';
import { SharedService } from '../service/shared.service';

export interface UsageReportReq {
    ApplicationName?: string;
    ScreenName?: string;
    UserEmailId?: string;
    UserCountry?: string;
    UserIPAddress?: string;
    UserName: any;
    UserWorkLocation?: string;
    UserRole?: string;
    StartTime?: Date;
    EndTime?: Date;
    VisitedCluster?: string;
    VisitedFactory?: string;
    VisitedLine?: string;
    VisitedSKU?: string;
}

@Injectable({
    providedIn: 'root'
})
export class Usagereport {
    userDtls: any;

    constructor(
        public sharedService: SharedService,
        public auth: AuthService,
        public authService: MsalService
    ) {

    }

    public report(screenName?: string, startTime?: Date, cluster?: string, factory?: string, line?: string, sku?: string) {

        if (!startTime || screenName === '') {
            return;
        }

        if (screenName === 'Waste Dashboard - Global') {
            cluster = null;
            factory = null;
            line = null;
            sku = null;
        } 
        if (this.auth.checkIsAuthticated) {
            /* istanbul ignore next */
            this.userDtls = this.authService.getAccount();
            // console.log(this.userDtls);
        }

        const reportReq: UsageReportReq = {
            ApplicationName: 'NGTW',
            ScreenName: screenName,
            UserEmailId: this.userDtls.userName ? this.userDtls.userName : '',
            UserCountry: this.userDtls.idToken.country,
            UserIPAddress: '0.0.0.0',
            UserName: this.userDtls.name ? this.userDtls.name : '',
            UserWorkLocation: this.userDtls.idToken.city,
            UserRole: this.userDtls.idToken.roles ? this.userDtls.idToken.roles[0] : '',
            StartTime: startTime ? new Date(startTime.getTime() + (startTime.getTimezoneOffset() * 60000)) :
                new Date(new Date().getTime() + (new Date().getTimezoneOffset() * 60000)),
            EndTime: new Date(new Date().getTime() + (new Date().getTimezoneOffset() * 60000))
        };
        if (cluster) {
            reportReq.VisitedCluster = cluster;
        }
        if (factory) {
            reportReq.VisitedFactory = factory;
        }
        if (line) {
            reportReq.VisitedLine = line;
        }
        if (sku) {
            reportReq.VisitedSKU = sku;
        }

        this.sharedService.insertUsage(reportReq).subscribe();
    }
}
