import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { startWith, takeUntil } from 'rxjs/operators';
import { map } from 'rxjs/internal/operators/map';
import { Observable, Subject, Subscription } from 'rxjs';
import { SharedService } from 'src/app/shared/service/shared.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DataService } from 'src/app/shared/service/data.service';
import { RequesterService } from 'src/app/features/waste-dashboard/services/requester.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-select-line',
  templateUrl: './select-line.component.html',
  styleUrls: ['./select-line.component.scss']
})
export class SelectLineComponent implements OnInit, OnDestroy {
  clusterFilteredOptions: Observable<string[]>;
  factoryFilteredOptions: Observable<string[]>;
  lineFilteredOptions: Observable<string[]>;
  clustersList: any[] = [];
  factoriesList: any[] = [];
  linesList: any[] = [];
  lineSelectors: FormGroup;
  linesListToStore: any;
  lineUnderCluster;
  lineUnderFactory;
  lineUnderLine;
  localStorageName: any;

  getRealTimeLineListSub: Subscription;
  getDFOSFactoryListSub: Subscription;
  uniqueLineCluster: any[];
  selectedClusterFactory: any;
  selectedClusterforLine: any;
  businessGroup: any[];
  public _filter(clusterValue: string): string[] {
    const filterValues = clusterValue.toLowerCase();
    return this.clustersList.filter(option => option && option.subRegionName && option.subRegionName.toLowerCase().includes(filterValues));
  }

  public _filterfact(factoryValue: string): string[] {
    const filterValues = factoryValue.toLowerCase();
    return this.factoriesList.filter(option => option && option.name && option.name.toLowerCase().includes(filterValues));
  }

  public _filterline(lineValue: string): string[] {
    const filterValues = lineValue.toLowerCase();
    return this.linesList.filter(option => option && option.name && option.name.toLowerCase().includes(filterValues));
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public dataPassed,
    public formBuilder: FormBuilder,
    private dialog: MatDialogRef<SelectLineComponent>,
    private sharedService: SharedService,
    private dataSer: DataService, private daService: RequesterService, private toastrService: ToastrService) {
    this.lineSelectors = this.formBuilder.group({
      cluster: ['', [Validators.required]],
      clusterid: ['', [Validators.required]],
      factory: ['', [Validators.required]],
      factoryid: ['', [Validators.required]],
      lineName: ['', [Validators.required]],
      mdcsLineName: [''],
      businessGroup: ['', [Validators.required]],
      businessUnit: [''],
    });

    if (this.dataPassed && this.dataPassed.localStorageName) {
      this.localStorageName = this.dataPassed.localStorageName;
    } else {
      this.localStorageName = [];
    }

    this.lineSelectors.controls.factory.disable();
    this.getCluster();
  }

  ngOnInit() {
    this.businessGroup = [
      {
        label: 'Personal Care',
        value: 'Personal Care',
      },
      {
        label: 'Nutrition',
        value: 'Nutrition',
      },
      {
        label: 'Home Care',
        value: 'Home Care',
      },
      {
        label: 'Beauty & Wellbeing',
        value: 'Beauty & Wellbeing',
      },
      {
        label: 'Ice Cream',
        value: 'Ice Cream',
      }
    ];
  }






  onClusterChanged(selectedCluster: any) {
    if (selectedCluster.name) {
      this.lineUnderCluster = selectedCluster;
      this.lineSelectors.controls.clusterid.setValue(selectedCluster.name);
      this.lineSelectors.controls.factory.enable();
      this.getFactoryList(selectedCluster);
      this.lineSelectors.controls.factory.setValue('');
    }
  }

  onFactoryChanged(selectedFactory: any) {
    if (selectedFactory.factoryId) {
      this.lineUnderFactory = selectedFactory;
      this.lineSelectors.controls.factoryid.setValue(selectedFactory.factoryId);
    }
  }




  /* istanbul ignore next */
  getCluster() {
    this.clustersList = [];
    this.doAsyncTaskSelectLine().then((data: any[]) => {

      if (data) {
        this.clustersList = data;
      }
      this.clusterFilteredOptions = this.lineSelectors.controls.cluster.valueChanges.pipe(
        startWith(''),
        map(clusterValue => this._filter(clusterValue))
      );
      this.uniqueLineCluster = [];
      this.uniqueLineCluster = [...new Map(this.clustersList.map((m) => [m.subRegionName, m])).values()];
      this.uniqueLineCluster.sort((a, b) => a.subRegionName.localeCompare(b.subRegionName));

      if (JSON.parse(this.dataSer.getStorageData(this.localStorageName)) &&
        JSON.parse(this.dataSer.getStorageData(this.localStorageName)).cluster) {
        if (this.clustersList && this.clustersList.length > 0) {
          let data = JSON.parse(this.dataSer.getStorageData(this.localStorageName)).cluster.subRegionName;
          this.clustersList.forEach((clusters) => {
            if (clusters.subRegionName === data) {
              this.lineSelectors.controls.cluster.setValue(JSON.parse(this.dataSer.getStorageData(this.localStorageName)).cluster.subRegionName);
              this.onClusterChanged(JSON.parse(this.dataSer.getStorageData(this.localStorageName)).cluster);
            }
          });
        }
      }

    },
      (err) => {

      });


  }

  /* istanbul ignore next */
  get f() {
    return this.lineSelectors.controls;
  }
  /* istanbul ignore next */
  autoCompleteCluster(event: string) {
    this.f.factory.setValue('');
    this.f.factory.disable();
    if (event && event !== '') {
      const searchKeyword = event.toLowerCase();
      this.uniqueLineCluster = [...new Map(this.clustersList.map((m) => [m.subRegionName, m])).values()];
      this.uniqueLineCluster = this.uniqueLineCluster.filter(c => {
        if (c.subRegionName.toLowerCase().includes(searchKeyword)) {
          return true;
        }
      });
      this.uniqueLineCluster.sort((a, b) => a.subRegionName.localeCompare(b.subRegionName));
    } else {
      this.uniqueLineCluster = [...new Map(this.clustersList.map((m) => [m.subRegionName, m])).values()];
      this.uniqueLineCluster.sort((a, b) => a.subRegionName.localeCompare(b.subRegionName));
    }
  }
  /* istanbul ignore next */
  autoCompleteFactory(event: string) {
    if (event && event !== '') {
      const searchKeyword = event.toLowerCase();
      const clonedFactoryList = [...this.factoriesList];
      this.selectedClusterFactory = clonedFactoryList.filter(f => {
        if (f.name.toLowerCase().includes(searchKeyword)) {
          return true;
        }
      });
    } else {
      this.uniqueLineCluster = [...new Map(this.clustersList.map((m) => [m.subRegionName, m])).values()];
      this.uniqueLineCluster.sort((a, b) => a.subRegionName.localeCompare(b.subRegionName));
      this.selectedClusterFactory = this.factoriesList;
      this.selectedClusterFactory.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  doAsyncTaskSelectLine() {
    return new Promise((resolve, reject) => {
      this.sharedService.getRealTimeClusterList().subscribe((dataSelectLine => {
        if (dataSelectLine && dataSelectLine.length > 0) {
          this.dataSer.removeItemFromStorage('dfos.userfactory.dataforline');
          this.dataSer.setStorageData('dfos.userfactory.dataforline', JSON.stringify(dataSelectLine));
          resolve(dataSelectLine);
        } else {
          reject('error');
        }
      }));
    });
  }


  /* istanbul ignore next */
  getFactoryList(selectedClusterOdr: any) {
    this.factoriesList = [];
    this.selectedClusterFactory = [];
    if (this.clustersList && this.clustersList.length > 0) {
      for (let index = 0; index < this.clustersList.length; index++) {
        if (this.clustersList[index].name === selectedClusterOdr.name) {
          this.clustersList.forEach((entry) => {
            entry.factories.forEach(element => {
              element['subRegionName'] = entry.name;
            });
          });
          this.selectedClusterFactory = this.factoriesList = [...this.selectedClusterFactory, ...this.clustersList[index].factories];
        }
        this.selectedClusterFactory.sort((a, b) => a.name.localeCompare(b.name));
      }

      /* istanbul ignore if */
      if (JSON.parse(this.dataSer.getStorageData(this.localStorageName)) &&
        JSON.parse(this.dataSer.getStorageData(this.localStorageName)).factory) {
        if (this.factoriesList && this.factoriesList.length > 0) {
          this.factoriesList.forEach((factoryLi) => {
            if (factoryLi.name === JSON.parse(this.dataSer.getStorageData(this.localStorageName)).factory.name) {
              this.lineSelectors.controls.factory.setValue(JSON.parse(this.dataSer.getStorageData(this.localStorageName)).factory.name);
              this.onFactoryChanged(JSON.parse(this.dataSer.getStorageData(this.localStorageName)).factory);
            }
          });
        }
      }
    }
  }
  /* istanbul ignore next */


  valueReset(catg: any) {
    if (catg === 'cluster') {
      this.lineSelectors.controls.clusterid.reset();
      this.lineSelectors.controls.factory.disable();
    } else if (catg === 'factory') {
      this.lineSelectors.controls.factoryid.reset();
    }
  }

  dialogClose() {
    this.dialog.close({ action: 'cancelled' });
  }

  onSubmit(): void {
    let saveForm = {
      factoryId: this.lineSelectors.controls.factoryid.value,
      lineName: this.lineSelectors.controls.lineName.value,
      mdcsLineName: this.lineSelectors.controls.mdcsLineName.value,
      businessGroup: this.lineSelectors.controls.businessGroup.value,
      businessUnit: this.lineSelectors.controls.businessUnit.value,
      createdDate: new Date(),
      createdBy: this.dataSer.getStorageData('userName'),
    };
    this.daService.postSpcLine(saveForm).subscribe((res: any) => { 
      if (res == 1) {
        this.toastrService.success("Line created successfully", 'success');
        this.doAsyncTaskSelectLine();
        this.dialogClose();
      }
    });

  }


  // onSubmit() {
  //   this.dialog.close(
  //     {
  //       action: 'submitted',
  //       selectedValues: {
  //         cluster: this.lineUnderCluster,
  //         factory: this.lineUnderFactory,
  //         line: this.lineUnderLine,
  //         lineList: this.linesListToStore
  //       }
  //     }
  //   );
  // }

  ngOnDestroy(): void {
    if (this.getRealTimeLineListSub) {
      this.getRealTimeLineListSub.unsubscribe();
    }
    if (this.getDFOSFactoryListSub) {
      this.getDFOSFactoryListSub.unsubscribe();
    }
  }
}
