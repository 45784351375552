import { Component, OnInit, Input, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy, Output, EventEmitter } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { DataService } from 'src/app/shared/service/data.service';
import { Subscription } from 'rxjs';
import { CurrencyService } from '../../../../shared/service/currency.service';
import { PlotData, ShowData, StockAdjData, TotalRange, TotalValues, WasteDashboardModel, WasteDataResponse, WastePercentList } from '../../model/waste-dashboard-model.model';
import { MatTabChangeEvent } from '@angular/material';

@Component({
    selector: 'app-stacked-bar-chart',
    templateUrl: './stacked-bar-chart.component.html',
    styleUrls: ['./stacked-bar-chart.component.scss'],
    animations: [
        trigger('fillUp', [
            transition(':enter', [
                style({ height: '0' }),
                animate('1s ease-in-out', style({ height: '*' })),
            ]),
            transition(':leave', [])
        ]),
        trigger('fadeIn', [
            transition(':leave', []),
            transition(':enter', [
                style({ opacity: '0' }),
                animate('1s ease-in-out', style({ opacity: 1 })),
            ])
        ]),
    ]
})
export class StackedBarChartComponent implements OnInit, OnChanges, OnDestroy {
    @Input() graphData: WasteDashboardModel[];
    @Input() selectedType: string;
    @Input() lineOrSKU: string;
    @Input() isLoading: boolean;
    @Input() totalValues: TotalValues;
    @Input() totalRange: TotalRange;
    @Input() showInGraph: ShowData;
    @Input() plotData: PlotData[];
    @Input() selectedTabIndex: number;
    @Input() wasteDataResponse: WasteDataResponse;
    // lineOrSKU: string = 'line';
    @Output() barClick: EventEmitter<object> = new EventEmitter();
    @Output() tabClick: EventEmitter<string> = new EventEmitter();
    @Output() toggleTabname: EventEmitter<string> = new EventEmitter();
    @Input() selectedtabname: string;
    showNoData: any = false;
    displayLabel = '';
    showTotalForBar: any = true;
    filterSelection: any;
    yScaleMax: number;
    yRange: any;
    timeText: any;
    // enableApplyBtn: boolean;
    // typeOfFilter: any;
    filterApplied: boolean;
    today: Date;
    currentDate: any;
    currrentFilter: any;
    currentHour: any;
    currentEndWeek: any;
    unitSelectedParams: any;
    sortedWith = 'total'; // total, charge, name
    sortType = 'descending'; // descending, ascending
    // yUnit: any = {
    //   label: 'Euro',
    //   code: '&euro;'
    // };
    // yUnit: any;
    symbolCode: any;
    hoveredItem: any;
    colorArray = [{
        background: '#00abe6',
        color: '#fff'
    }, {
        background: '#ffc000',
        color: '#333B40'
    }];

    subscription: Subscription;
    currencyValue: any;

    currencyData: any = {
        currencyRate: '',
        yUnit: '',
        symbolCode: '',
        label: ''
    };

    lineNameType: any;
    lineType: any;

    unitService: any;
    isOpen = false;
    removeLine: boolean;
    clusterDetails: any;

    constructor(
        private dataService: DataService,
        private cdr: ChangeDetectorRef,
        private currencyService: CurrencyService,
    ) {
        this.subscription = currencyService.currencyValue$.subscribe(
            (currencyValue: any) => {
                this.currencyData.currencyRate = currencyValue.crate;
                this.currencyData.yUnit = currencyValue.selectedCurrency.code;
                this.getYUnit();
                if (this.plotData && this.plotData.length) {
                    this.setScale(this.plotData);
                }
            });

        this.lineType = [
            { name: 'SAP Name', translateCode: 'buildapp_db.sap_name', class: 'sap-radio' },
            { name: 'Manex Name', translateCode: 'buildapp_db.manex_name', class: 'manex-radio' },
            { name: 'Local Name', translateCode: 'buildapp_db.local_name', class: 'local-radio' }
        ];
        this.lineNameType = this.lineType[0];

        this.unitService = this.dataService.getUnit().subscribe(message => {
            this.unitSelectedParams = message.text;

            // if (this.unitSelectedParams === 'wastePer') {
            //     this.showInGraph = {
            //         total: false,
            //         RM: true,
            //         PM: true,
            //     };
            // } else {
            // this.showInGraph = {
            //     total: true,
            //     RM: true,
            //     PM: true,
            //     sfg: true
            // };
            // }
        });
    }


    ngOnChanges(changes: any): void {
        this.showNoData = true;

        this.onDateChange();

        if (changes.graphData && changes.graphData.currentValue) {
            if (changes.graphData.currentValue.length) {
                this.filterPlotData();
                this.showNoData = false;
            } else if (changes.graphData && !changes.graphData.firstChange && changes.graphData.currentValue.length === 0) {
                this.showNoData = true;
                this.plotData = [];
                this.totalValues.rmTotal = 0;
                this.totalValues.pmTotal = 0;
                this.totalValues.rmpmTotal = 0;
                this.totalValues.sfgTotal = 0;
                this.totalValues.adjFGTotal = 0;
                this.totalValues.adjPMTotal = 0;
                this.totalValues.adjRMTotal = 0;
                this.totalValues.adjSFGTotal = 0;
            }
        }
    }

    ngOnInit() {
        this.dataService.on('stackBarFilterChange', this.filterChanged);
        this.dataService.on('stackBarSFGChange', this.sfgChanged);

        if (JSON.parse(this.dataService.getStorageData('sfgSelected'))) {
            this.showInGraph.sfg = JSON.parse(this.dataService.getStorageData('sfgSelected')).sfgData;
        } else {
            this.dataService.setStorageData('sfgSelected', JSON.stringify({ sfgData: this.showInGraph.sfg }));
            this.showInGraph.sfg = true;
        }

        if (this.selectedType === 'line') {
            this.displayLabel = 'lineSAPName';
        }
        if (JSON.parse(this.dataService.getStorageData('selectedWasteDBGranularity'))) {
            this.clusterDetails = JSON.parse(this.dataService.getStorageData('selectedWasteDBGranularity')).cluster.name;
            if (this.clusterDetails === 'South Asia') {
                this.removeLine = true;
            } else {
                this.removeLine = false;
            }

        }
    }

    filterChanged = (filterSelection: any) => {
        this.showInGraph = filterSelection;
        // const key = 'destroyed';
        // if (!this.cdr[key]) {
        //   this.cdr.detectChanges();
        // }
        this.filterPlotData();
        this.totaltableshow();
    }

    sfgChanged = () => {
        this.filterPlotData();
        this.totaltableshow();
    }

    onDateChange() {
        this.today = new Date();
        this.currrentFilter = 'month';
        this.currentDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
        if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter'))) {
            this.currrentFilter = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).appliedFilterType;
            this.currentDate = new Date(JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).dateObj);
            if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr &&
                JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr.label) {
                this.currentHour = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr.label;
            } else {
                this.currentHour = '';
            }

            if (this.currrentFilter === 'week') {
                this.currentEndWeek = new Date(JSON.parse
                    (this.dataService.getStorageData('selectedWasteDBTimeFilter')).endWeekFromSelectedDate);
            } else if (this.currrentFilter === 'quarter') {
                this.currentDate = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).startQuarterMonth + '|' +
                    JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).endQuarterMonth;

            } else {
                this.currentEndWeek = '';
            }
        } else {
            this.currrentFilter = 'month';
            this.currentDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
        }
    }
    setScale(arr: PlotData[]): void {
        // this.yScaleMax = -9999999999;
        // let componentMaxVal = -9999999999;
        this.yScaleMax = 0;
        let componentMaxVal = 0;

        const total = Math.max.apply(null, arr.map(itemData => itemData.total));
        arr.forEach(item => {
            let componentTotal = 0;
            item.waste.forEach(component => {
                if (component && component.value) {
                    componentTotal += component.value;
                    if (Math.abs(componentMaxVal) < Math.abs(component.value)) {
                        componentMaxVal = component.value;
                    }
                }
            });
            componentTotal += item.adjPMWaste;
            componentTotal += item.adjRMWaste;
            componentTotal += item.adjFGWaste;
            componentTotal += item.adjSFGWaste;
            item.componentTotal = componentTotal;
            if (this.showTotalForBar) {
                if (this.yScaleMax < item.total) {
                    this.yScaleMax = item.total;
                }
            } else {
                if (this.yScaleMax < componentTotal) {
                    this.yScaleMax = componentTotal;
                }
            }
        });
        this.yRangeCalculate(Math.abs(total * this.currencyData.currencyRate));
        // this.yRangeCalculate(this.yScaleMax);

        const keyToSort: any = this.sortedWith;
        // if (!this.showTotalForBar) {
        //     keyToSort = 'componentTotal';
        // }
        this.paretoSort(arr, keyToSort);
        this.sortedWith = 'total';
    }

    paretoSort(arr, key) {
        arr.sort((a, b) => {
            return b[key] - a[key];
        });
    }

    yRangeCalculate(maxVal: number): void {
        if (maxVal < 0) {
            maxVal = Math.abs(maxVal);
        }
        if (maxVal >= 1000000) {
            // millions
            this.yRange = {
                label: 'Million',
                code: 'm',
                divisionfactor: 1000000
            };

        } else if (maxVal >= 1000) {
            // kilos
            this.yRange = {
                label: 'Kilo',
                code: 'k',
                divisionfactor: 1000
            };
        } else {
            this.yRange = {
                label: '',
                code: '',
                divisionfactor: 1
            };
        }
    }

    setFgSfg(sfgDefault = true) {
        try {
            // let sfgData = sfgDefault;
            let sfgFgPmTotal = 0;
            let sfgFgRmTotal = 0;
            let wasteQtyPmTotal = 0;
            let wasteQtyRmTotal = 0;
            let zeroBasedPmTotal = 0;
            let zeroBasedRmTotal = 0;

            let prevZeroBasedPmtotal = 0;
            let prevZeroBasedRmtotal = 0;
            let prevWasteRMTotal = 0;
            let prevWastePMTotal = 0;

            if (JSON.parse(this.dataService.getStorageData('sfgSelected'))) {
                this.showInGraph.sfg = JSON.parse(this.dataService.getStorageData('sfgSelected')).sfgData;
            } else {
                this.dataService.setStorageData('sfgSelected', JSON.stringify({ sfgData: this.showInGraph.sfg }));
                this.showInGraph.sfg = true;
            }

            // if (JSON.parse(this.dataService.getStorageData('sfgSelected'))) {
            //     sfgData = JSON.parse(this.dataService.getStorageData('sfgSelected')).sfgData;
            // } else {
            //     sfgData = sfgDefault;
            //     this.dataService.setStorageData('sfgSelected', JSON.stringify({ sfgData }));
            // }

            this.graphData.forEach(item => {
                sfgFgPmTotal = 0;
                sfgFgRmTotal = 0;
                wasteQtyPmTotal = 0;
                wasteQtyRmTotal = 0;
                zeroBasedPmTotal = 0;
                zeroBasedRmTotal = 0;
                prevZeroBasedPmtotal = 0;
                prevZeroBasedRmtotal = 0;
                prevWasteRMTotal = 0;
                prevWastePMTotal = 0;

                item.waste.forEach((component, index) => {
                    if (component.type && component.type === 'PM') {
                        if (component.prodType && component.prodType !== 'pmSFGTotal') {
                            /* istanbul ignore else */
                            if (this.showInGraph.sfg) {
                                if (this.unitSelectedParams === 'wastePer') {
                                    wasteQtyPmTotal = wasteQtyPmTotal +
                                        (component.wasteInCost ? component.wasteInCost : 0);
                                    zeroBasedPmTotal = zeroBasedPmTotal +
                                        (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 0);

                                    prevZeroBasedPmtotal = prevZeroBasedPmtotal +
                                        (component.prevZeroBasedTargetWasteCost ? component.prevZeroBasedTargetWasteCost : 0);
                                    prevWastePMTotal = prevWastePMTotal +
                                        (component.prevWasteInCost ? component.prevWasteInCost : 0);
                                } else {
                                    sfgFgPmTotal = sfgFgPmTotal + component.wasteInCost;
                                }
                            } else {
                                if (component.prodType === 'FERT') {
                                    if (this.unitSelectedParams === 'wastePer') {
                                        wasteQtyPmTotal = wasteQtyPmTotal +
                                            (component.wasteInCost ? component.wasteInCost : 0);
                                        zeroBasedPmTotal = zeroBasedPmTotal +
                                            (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 0);

                                        prevZeroBasedPmtotal = prevZeroBasedPmtotal +
                                            (component.prevZeroBasedTargetWasteCost ? component.prevZeroBasedTargetWasteCost : 0);
                                        prevWastePMTotal = prevWastePMTotal +
                                            (component.prevWasteInCost ? component.prevWasteInCost : 0);
                                    } else {
                                        sfgFgPmTotal = sfgFgPmTotal + component.wasteInCost;
                                    }
                                }
                            }
                        } else if (component.prodType && component.prodType === 'pmSFGTotal') {
                            item.waste.splice(index, 1);
                        }
                    }
                });

                item.waste.push({
                    value: sfgFgPmTotal,
                    wasteQtyPmTotal,
                    zeroBasedPmTotal,
                    type: 'PM',
                    prodType: 'pmSFGTotal',
                    prevWastePmTotal: prevWastePMTotal,
                    preZeroBasedPmTotal: prevZeroBasedPmtotal
                });

                item.waste.forEach((component, index) => {
                    if (component.type && component.type === 'RM') {
                        if (component.prodType && component.prodType !== 'rmSFGTotal') {
                            /* istanbul ignore else */
                            if (this.showInGraph.sfg) {
                                if (this.unitSelectedParams === 'wastePer') {
                                    wasteQtyRmTotal = wasteQtyRmTotal +
                                        (component.wasteInCost ? component.wasteInCost : 0);
                                    zeroBasedRmTotal = zeroBasedRmTotal +
                                        (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 0);

                                    prevZeroBasedRmtotal = prevZeroBasedRmtotal +
                                        (component.prevZeroBasedTargetWasteCost ? component.prevZeroBasedTargetWasteCost : 0);
                                    prevWasteRMTotal = prevWasteRMTotal +
                                        (component.prevWasteInCost ? component.prevWasteInCost : 0);

                                } else {
                                    sfgFgRmTotal = sfgFgRmTotal + component.wasteInCost;
                                }
                            } else {
                                if (component.prodType === 'FERT') {
                                    if (this.unitSelectedParams === 'wastePer') {
                                        wasteQtyRmTotal = wasteQtyRmTotal +
                                            (component.wasteInCost ? component.wasteInCost : 0);
                                        zeroBasedRmTotal = zeroBasedRmTotal +
                                            (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 0);

                                        prevZeroBasedRmtotal = prevZeroBasedRmtotal +
                                            (component.prevZeroBasedTargetWasteCost ? component.prevZeroBasedTargetWasteCost : 0);
                                        prevWasteRMTotal = prevWasteRMTotal +
                                            (component.prevWasteInCost ? component.prevWasteInCost : 0);
                                    } else {
                                        sfgFgRmTotal = sfgFgRmTotal + component.wasteInCost;
                                    }
                                }
                            }
                        } else if (component.prodType && component.prodType === 'rmSFGTotal') {
                            item.waste.splice(index, 1);
                        }
                    }
                });
                item.waste.push({
                    value: sfgFgRmTotal,
                    wasteQtyRmTotal,
                    zeroBasedRmTotal,
                    type: 'RM',
                    prodType: 'rmSFGTotal',
                    prevWasteRmTotal: prevWasteRMTotal,
                    prevZeroBasedRmTotal: prevZeroBasedRmtotal
                });
            });
        } catch (error) {

        }

    }

    setTotal(arr: WasteDashboardModel[]) {
        this.totalValues.rmTotal = 0;
        this.totalValues.pmTotal = 0;
        this.totalValues.rmpmTotal = 0;
        this.totalValues.sfgTotal = 0;
        this.totalValues.adjFGTotal = 0;
        this.totalValues.adjPMTotal = 0;
        this.totalValues.adjRMTotal = 0;
        this.totalValues.adjSFGTotal = 0;

        arr.forEach((item: WasteDashboardModel) => {
            let componentTotal = 0;
            let wasteQtyTotal = 0;
            let zeroBasedTotal = 0;
            let prevTotal = 0;
            let prevZeroBasedTotal = 0;

            item.waste.forEach((component: WastePercentList) => {
                if (this.unitSelectedParams === 'wastePer') {
                    // if (component && component.value) {

                    if (component.type && component.type === 'RM' &&
                        component.prodType && component.prodType === 'rmSFGTotal') {

                        wasteQtyTotal = wasteQtyTotal + (component.wasteQtyRmTotal ? component.wasteQtyRmTotal : 0);
                        zeroBasedTotal = zeroBasedTotal + (component.zeroBasedRmTotal ? component.zeroBasedRmTotal : 0);

                        component.value = ((
                            (component.wasteQtyRmTotal ? component.wasteQtyRmTotal : 0) /
                            (component.zeroBasedRmTotal ? component.zeroBasedRmTotal : 1)
                        ) * 100);

                        prevTotal = prevTotal + (component.prevWasteRmTotal ? component.prevWasteRmTotal : 0);
                        prevZeroBasedTotal = prevZeroBasedTotal +
                            (component.prevZeroBasedRmTotal ? component.prevZeroBasedRmTotal : 0);

                    } else if (component.type && component.type === 'PM' &&
                        component.prodType && component.prodType === 'pmSFGTotal') {
                        wasteQtyTotal = wasteQtyTotal + (component.wasteQtyPmTotal ? component.wasteQtyPmTotal : 0);
                        zeroBasedTotal = zeroBasedTotal + (component.zeroBasedPmTotal ? component.zeroBasedPmTotal : 0);

                        component.value = ((
                            (component.wasteQtyPmTotal ? component.wasteQtyPmTotal : 0) /
                            (component.zeroBasedPmTotal ? component.zeroBasedPmTotal : 1)
                        ) * 100);
                        prevTotal = prevTotal + (component.prevWastePmTotal ? component.prevWastePmTotal : 0);
                        prevZeroBasedTotal = prevZeroBasedTotal +
                            (component.preZeroBasedPmTotal ? component.preZeroBasedPmTotal : 0);
                    } else if (item.type === 'SKU') {
                        if (component.type && component.type === 'RM') {

                            wasteQtyTotal = wasteQtyTotal + (component.wasteInCost ? component.wasteInCost : 0);
                            zeroBasedTotal = zeroBasedTotal + (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 0);
                            component.value = ((
                                (component.wasteInCost ? component.wasteInCost : 0) /
                                (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 1)
                            ) * 100);
                        } else if (component.type && component.type === 'PM') {

                            wasteQtyTotal = wasteQtyTotal + (component.wasteInCost ? component.wasteInCost : 0);
                            zeroBasedTotal = zeroBasedTotal + (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 0);
                            component.value = ((
                                (component.wasteInCost ? component.wasteInCost : 0) /
                                (component.zeroBasedTargetWasteCost ? component.zeroBasedTargetWasteCost : 1)
                            ) * 100);
                        }

                        prevTotal = prevTotal + (component.prevWastePmTotal ? component.prevWastePmTotal : 0);
                        prevZeroBasedTotal = prevZeroBasedTotal +
                            (component.preZeroBasedPmTotal ? component.preZeroBasedPmTotal : 0);

                    }

                    // }
                } else {
                    if (component && component.value) {
                        if (component.type && component.type === 'RM' &&
                            component.prodType && component.prodType === 'rmSFGTotal') {
                            componentTotal += component.value;
                            this.totalValues.rmTotal += component.value;
                        } else if (component.type && component.type === 'PM' &&
                            component.prodType && component.prodType === 'pmSFGTotal') {
                            componentTotal += component.value;
                            this.totalValues.pmTotal += component.value;
                        }
                    }
                    if (component.prodType && component.prodType === 'HALB') {
                        this.totalValues.sfgTotal += component.wasteInCost;
                    }
                }
            });

            if (this.showInGraph.isStock) {

                wasteQtyTotal += item.adjFGStock ? item.adjFGStock : 0;
                wasteQtyTotal += item.adjRMStock ? item.adjRMStock : 0;
                wasteQtyTotal += item.adjPMStock ? item.adjPMStock : 0;

                prevTotal += item.adjFGStockPrev ? item.adjFGStockPrev : 0;
                prevTotal += item.adjRMStockPrev ? item.adjRMStockPrev : 0;
                prevTotal += item.adjPMStockPrev ? item.adjPMStockPrev : 0;

                if (this.showInGraph.sfg) {
                    wasteQtyTotal += item.adjSFGStock ? item.adjSFGStock : 0;
                    prevTotal += item.adjSFGStockPrev ? item.adjSFGStockPrev : 0;
                }
            }

            item.adjRMWaste = 0;
            item.adjPMWaste = 0;
            item.adjFGWaste = 0;
            item.adjSFGWaste = 0;

            let checkType = 'clusterKey';

            if (this.selectedType === 'factory') {
                checkType = 'factoryKey';
            }

            if (this.wasteDataResponse && this.wasteDataResponse.stockAdjData && this.showInGraph.isStock) {
                const selectedStockData: StockAdjData[] = this.wasteDataResponse.stockAdjData.filter(singData => singData[checkType] === item.id);
                if (selectedStockData && selectedStockData.length > 0) {
                    if (selectedStockData.find(stockData => stockData.materialType === 'RM')) {
                        item.adjRMWaste = selectedStockData.find(stockData => stockData.materialType === 'RM').amountinEuros;
                    }
                    componentTotal += item.adjRMWaste;
                    if (selectedStockData.find(stockData => stockData.materialType === 'PM')) {
                        item.adjPMWaste = selectedStockData.find(stockData => stockData.materialType === 'PM').amountinEuros;
                    }
                    componentTotal += item.adjPMWaste;
                    if (selectedStockData.find(stockData => stockData.prodType === 'FERT')) {
                        item.adjFGWaste = selectedStockData.find(stockData => stockData.prodType === 'FERT').amountinEuros;
                    }
                    componentTotal += item.adjFGWaste;
                    if (selectedStockData.find(stockData => stockData.prodType === 'HALB') && this.showInGraph.sfg) {
                        item.adjSFGWaste = selectedStockData.find(stockData => stockData.prodType === 'HALB').amountinEuros;
                    }
                    componentTotal += item.adjSFGWaste;
                }
            }
            if (this.selectedType === 'sku' && this.showInGraph.isStock && this.wasteDataResponse && this.wasteDataResponse.skuWasteData) {
                const selectedData = this.wasteDataResponse.skuWasteData.find(data => data.id === item.id);
                if (selectedData.prodType === 'FERT') {
                    item.adjFGWaste = selectedData.stockAdjWasteCost;
                }
                if (selectedData.prodType === 'HALB') {
                    item.adjSFGWaste = selectedData.stockAdjWasteCost;
                }
                componentTotal += selectedData.stockAdjWasteCost;
                wasteQtyTotal = selectedData.wasteInCostPM + selectedData.wasteInCostRM + selectedData.stockAdjWasteCost;
            }
            if (this.unitSelectedParams === 'wastePer') {
                componentTotal = (((wasteQtyTotal ? wasteQtyTotal : 0) / (zeroBasedTotal ? zeroBasedTotal : 1)) * 100);
                item.prevTotal = (((prevTotal ? prevTotal : 0) / (prevZeroBasedTotal ? prevZeroBasedTotal : 1)) * 100);
            }
            if ((this.unitSelectedParams === 'wastePer' && item.type !== 'SKU') || this.unitSelectedParams === 'cost') {
                item.total = componentTotal;

            }
        });
        this.totalValues.rmpmTotal = this.totalValues.rmTotal + this.totalValues.pmTotal;
        this.totalWasteCalculate(this.totalValues.rmpmTotal);
        if (this.wasteDataResponse && this.wasteDataResponse.stockAdjData) {
            this.updateTotalValues();
        }

        if (this.selectedType === 'sku') {
            if (this.wasteDataResponse.skuWasteData.filter(data =>
                data.prodType === 'FERT').length > 0) {
                this.totalValues.adjFGTotal += this.wasteDataResponse.skuWasteData.filter(data =>
                    data.prodType === 'FERT').map(fgData =>
                        fgData.stockAdjWasteCost).reduce((a, b) => a + b, 0);
            }

            if (this.wasteDataResponse.skuWasteData.filter(data =>
                data.prodType === 'HALB').length > 0) {
                this.totalValues.adjSFGTotal += this.wasteDataResponse.skuWasteData.filter(data =>
                    data.prodType === 'HALB').map(fgData =>
                        fgData.stockAdjWasteCost).reduce((a, b) => a + b, 0);
            }
        }
    }

    updateTotalValues() {
        if (this.wasteDataResponse) {
            this.totalValues.adjRMTotal = this.wasteDataResponse.stockAdjData.filter(stockData =>
                stockData.materialType === 'RM').map(stockMap =>
                    stockMap.amountinEuros).reduce((a, b) => a + b, 0);
            this.totalValues.adjPMTotal = this.wasteDataResponse.stockAdjData.filter(stockData =>
                stockData.materialType === 'PM').map(stockMap =>
                    stockMap.amountinEuros).reduce((a, b) => a + b, 0);
            this.totalValues.adjFGTotal = this.wasteDataResponse.stockAdjData.filter(stockData =>
                stockData.prodType === 'FERT').map(stockMap =>
                    stockMap.amountinEuros).reduce((a, b) => a + b, 0);
            this.totalValues.adjSFGTotal = this.wasteDataResponse.stockAdjData.filter(stockData =>
                stockData.prodType === 'HALB').map(stockMap =>
                    stockMap.amountinEuros).reduce((a, b) => a + b, 0);
        }
    }

    filterPlotData() {
        if (this.unitSelectedParams === 'wastePer' && this.graphData) {
            this.graphData.forEach(item => {
                if (item.wastePercentList) {
                    item.waste = JSON.parse(JSON.stringify(item.wastePercentList));
                } else if (item.type !== 'SKU') {
                    item.waste = [];
                }
            });
        }
        this.setFgSfg();
        this.setTotal(this.graphData);

        this.showTotalForBar = true;
        const showingValues: any[] = [];
        const prodTypes = ['rmSFGTotal', 'pmSFGTotal'];
        const dataToSort = JSON.parse(JSON.stringify(this.graphData));


        if (this.wasteDataResponse && this.wasteDataResponse.skuWasteData) {
            this.wasteDataResponse.skuWasteData.forEach((skuData, index) => {
                dataToSort[index].stockAdjWasteCost = skuData.stockAdjWasteCost;
                dataToSort[index].prevStockAdjWasteCost = skuData.prevStockAdjWasteCost;
            });
        }

        if (this.showInGraph) {
            // if (!this.showInGraph.total) {
            //     this.showTotalForBar = false;
            // }

            for (const property in this.showInGraph) {
                if (this.showInGraph[property] && property !== 'total') {
                    showingValues.push(property);
                }
            }

            if (showingValues.length && dataToSort) {
                dataToSort.forEach(bar => {
                    bar.waste = bar.waste.filter((ele) => {
                        return (showingValues.indexOf(ele.type) !== -1);
                    });
                });
            }
        }
        if (dataToSort) {
            dataToSort.forEach(bar => {
                bar.waste = bar.waste.filter((ele) => {
                    return (prodTypes.indexOf(ele.prodType) !== -1);
                });
            });
        }

        this.plotData = dataToSort.filter(data => data.waste.reverse());

        this.setScale(this.plotData);
        this.updateUnitValue();
        if (this.unitSelectedParams === 'wastePer') {
            this.updateWasteAndChargePercentageForSKU();
        }
    }

    updateUnitValue() {
        try {

            const isSFG = JSON.parse(this.dataService.getStorageData('sfgSelected')).sfgData;
            let sumOfPrevWasteInCost = 0;
            let sumOfCurrWasteInCost = 0;
            let chargePercentage = 0;
            let subtractedWasteValue = 0;
            let divisionValue = 0;

            this.graphData.forEach((singleGraphData: WasteDashboardModel) => {
                sumOfPrevWasteInCost = 0;
                sumOfCurrWasteInCost = 0;
                chargePercentage = 0;
                subtractedWasteValue = 0;
                divisionValue = 0;
                singleGraphData.waste.forEach((singleWastePercent: WastePercentList) => {

                    if (((isSFG) || (!isSFG && singleWastePercent.prodType === 'FERT')) &&
                        singleWastePercent && singleWastePercent.prevWasteInCost) {
                        sumOfPrevWasteInCost = sumOfPrevWasteInCost + singleWastePercent.prevWasteInCost;
                    }
                    if (((isSFG) || (!isSFG && singleWastePercent.prodType === 'FERT')) &&
                        singleWastePercent && singleWastePercent.wasteInCost) {
                        sumOfCurrWasteInCost = sumOfCurrWasteInCost + singleWastePercent.wasteInCost;
                    }
                });
                let checkTypeC = 'clusterKey';

                if (this.selectedType === 'factory') {
                    checkTypeC = 'factoryKey';
                }

                if (this.wasteDataResponse && (this.selectedType !== 'line')) {
                    if (this.selectedType !== 'sku' && this.selectedType !== 'linetosku') {
                        const selectedStockAdjData = this.wasteDataResponse.stockAdjData.filter(data => data[checkTypeC] === singleGraphData.id);
                        const selectedPrevStockAdjData = this.wasteDataResponse.previousStockAdjData.filter(data => data[checkTypeC] === singleGraphData.id);

                        if (selectedStockAdjData && selectedStockAdjData.length > 0) {
                            selectedStockAdjData.forEach(stock => {
                                if (this.showInGraph.isStock) {

                                    // if (stock.prodType === 'FERT') {
                                    sumOfCurrWasteInCost = sumOfCurrWasteInCost + stock.amountinEuros;
                                    // }
                                    if (stock.prodType === 'HALB' && !this.showInGraph.sfg) {
                                        sumOfCurrWasteInCost = sumOfCurrWasteInCost - stock.amountinEuros;
                                    }
                                }

                            });
                        }
                        if (selectedPrevStockAdjData && selectedPrevStockAdjData.length > 0) {
                            selectedPrevStockAdjData.forEach(prevStock => {
                                if (this.showInGraph.isStock) {
                                    // if (prevStock.prodType === 'FERT') {
                                    sumOfPrevWasteInCost = sumOfPrevWasteInCost + prevStock.amountinEuros;
                                    // }
                                    if (prevStock.prodType === 'HALB' && !this.showInGraph.sfg) {
                                        sumOfPrevWasteInCost = sumOfPrevWasteInCost - prevStock.amountinEuros;
                                    }
                                }
                            });
                        }
                    } else if (this.selectedType === 'sku') {
                        if (this.showInGraph.isStock && this.wasteDataResponse && this.wasteDataResponse.skuWasteData) {
                            const selectedData = this.wasteDataResponse.skuWasteData.find(data => data.id === singleGraphData.id);
                            sumOfCurrWasteInCost += selectedData.stockAdjWasteCost;
                            sumOfPrevWasteInCost += selectedData.prevStockAdjWasteCost;
                        }
                    }
                }


                const objIndex = this.plotData.findIndex((objData: WasteDashboardModel) =>
                    objData.id === singleGraphData.id && objData.name === singleGraphData.name);

                this.plotData[objIndex].sumOfCurrWasteInCost = sumOfCurrWasteInCost;
                this.plotData[objIndex].sumOfPrevWasteInCost = sumOfPrevWasteInCost;

                subtractedWasteValue =
                    Number(Number(this.plotData[objIndex].sumOfCurrWasteInCost) - Number(this.plotData[objIndex].sumOfPrevWasteInCost));
                if (this.unitSelectedParams === 'wastePer') {
                    this.plotData[objIndex].chargePercentageValue = Math.abs(this.plotData[objIndex].total -
                        this.plotData[objIndex].prevTotal);

                    this.plotData[objIndex].upArrow = this.plotData[objIndex].total > this.plotData[objIndex].prevTotal;
                    if (isNaN(this.plotData[objIndex].chargePercentageValue) || this.plotData[objIndex].prevTotal === 0) {
                        this.plotData[objIndex].chargePercentageValue = 'noData';
                    }
                } else {
                    // check division by Zero
                    if (Number(this.plotData[objIndex].sumOfPrevWasteInCost) === 0) {
                        this.plotData[objIndex].chargePercentageValue = 'noData';
                    } else {
                        divisionValue = Number(Number(subtractedWasteValue) / Number(this.plotData[objIndex].sumOfPrevWasteInCost));
                        this.plotData[objIndex].chargePercentageValue = Math.abs(Number(Number(divisionValue) * 100));
                        this.plotData[objIndex].upArrow = sumOfCurrWasteInCost > sumOfPrevWasteInCost;
                        if (isNaN(this.plotData[objIndex].chargePercentageValue)) {
                            this.plotData[objIndex].chargePercentageValue = 'noData';
                        }
                    }
                }
            });

        } catch (error) {
            // --TODO
        }

    }

    updateWasteAndChargePercentageForSKU() {
        try {
            if (this.plotData && this.plotData[0] &&
                this.plotData[0].type && this.plotData[0].type === 'SKU') {
                this.plotData.forEach(plotDataElement => {
                    if (plotDataElement) {

                        let rmWastData = [];
                        let pmWastData = [];

                        rmWastData = plotDataElement.waste.filter(wasteDataRm =>
                            wasteDataRm && wasteDataRm.type && wasteDataRm.type === 'RM');

                        if (this.showInGraph && !this.showInGraph.RM && !rmWastData[0]) {
                            const rmValueFromGraphData = this.graphData.filter(grpDataRm => grpDataRm.id === plotDataElement.id);

                            if (rmValueFromGraphData && rmValueFromGraphData[0]) {

                                const rmWasteDataFromGraphData = rmValueFromGraphData[0].waste.filter(wasteGraphRM =>
                                    wasteGraphRM.type === 'RM' && wasteGraphRM.prodType !== 'rmSFGTotal');
                                rmWastData.push({
                                    wasteQtyRmTotal: rmWasteDataFromGraphData[0] ? rmWasteDataFromGraphData[0].wasteInCost : 0,
                                    zeroBasedRmTotal: rmWasteDataFromGraphData[0] ?
                                        rmWasteDataFromGraphData[0].zeroBasedTargetWasteCost : 0,
                                    prevWasteRmTotal: rmWasteDataFromGraphData[0] ? rmWasteDataFromGraphData[0].prevWasteInCost : 0,
                                    prevZeroBasedRmTotal: rmWasteDataFromGraphData[0] ?
                                        rmWasteDataFromGraphData[0].prevZeroBasedTargetWasteCost : 0,

                                });
                            }

                        }

                        pmWastData = plotDataElement.waste.filter(wasteDataPm =>
                            wasteDataPm && wasteDataPm.type && wasteDataPm.type === 'PM');

                        if (this.showInGraph && !this.showInGraph.PM && !pmWastData[0]) {
                            const pmValueFromGraphData = this.graphData.filter(grpDataPm => grpDataPm.id === plotDataElement.id);

                            if (pmValueFromGraphData && pmValueFromGraphData[0]) {

                                const pmWasteDataFromGraphData = pmValueFromGraphData[0].waste.filter(wasteGraphPM =>
                                    wasteGraphPM.type === 'PM' && wasteGraphPM.prodType !== 'pmSFGTotal');
                                pmWastData.push({
                                    wasteQtyPmTotal: pmWasteDataFromGraphData[0] ? pmWasteDataFromGraphData[0].wasteInCost : 0,
                                    zeroBasedPmTotal: pmWasteDataFromGraphData[0] ?
                                        pmWasteDataFromGraphData[0].zeroBasedTargetWasteCost : 0,
                                    prevWastePmTotal: pmWasteDataFromGraphData[0] ? pmWasteDataFromGraphData[0].prevWasteInCost : 0,
                                    preZeroBasedPmTotal: pmWasteDataFromGraphData[0] ?
                                        pmWasteDataFromGraphData[0].prevZeroBasedTargetWasteCost : 0,

                                });
                            }
                        }


                        let totalOfRmAndPm = (pmWastData[0] ? pmWastData[0].wasteQtyPmTotal : 0) +
                            (rmWastData[0] ? rmWastData[0].wasteQtyRmTotal : 0);

                        const prevZeroValue = rmWastData[0] && rmWastData[0].zeroBasedRmTotal &&
                            rmWastData[0].zeroBasedRmTotal > 0 ? rmWastData[0].zeroBasedRmTotal : 1;

                        if (this.showInGraph.isStock) {

                            totalOfRmAndPm += plotDataElement.stockAdjWasteCost;
                        }


                        plotDataElement.total = ((totalOfRmAndPm / prevZeroValue) * 100);

                        let totalOfPrevRmAndPm = (pmWastData[0] && pmWastData[0].prevWastePmTotal ? pmWastData[0].prevWastePmTotal : 0) +
                            (rmWastData[0] && rmWastData[0].prevWasteRmTotal ? rmWastData[0].prevWasteRmTotal : 0);

                        if (this.showInGraph.isStock) {

                            totalOfPrevRmAndPm += plotDataElement.prevStockAdjWasteCost;
                        }


                        if (totalOfPrevRmAndPm !== 0) {

                            const prevZeroTotalValue = rmWastData[0] && rmWastData[0].prevZeroBasedRmTotal &&
                                rmWastData[0].prevZeroBasedRmTotal > 0 ? rmWastData[0].prevZeroBasedRmTotal : 1;

                            const percentageTotalInWaste = ((totalOfPrevRmAndPm / prevZeroTotalValue) * 100);

                            plotDataElement.chargePercentageValue = plotDataElement.total - percentageTotalInWaste;

                            if (plotDataElement.total > percentageTotalInWaste) {
                                plotDataElement.upArrow = true;
                            } else {
                                plotDataElement.upArrow = false;
                            }
                        }
                    }
                });
                this.setTotal(this.plotData);
                this.setScale(this.plotData);
            }
        } catch (error) {

        }
    }

    totaltableshow() {
        if (!this.showInGraph.total) {
            this.totalValues.rmpmTotal = 0;
        }
        if (!this.showInGraph.RM) {
            this.totalValues.rmTotal = 0;
            if (!this.showInGraph.total) {
                this.totalWasteCalculate(this.totalValues.pmTotal);
                this.totalValues.rmpmTotal = 0;
            } else {
                this.totalValues.rmpmTotal = this.totalValues.pmTotal;
                this.totalWasteCalculate(this.totalValues.rmpmTotal);
            }
        }
        if (!this.showInGraph.PM) {
            this.totalValues.pmTotal = 0;
            if (!this.showInGraph.total) {
                this.totalWasteCalculate(this.totalValues.rmpmTotal);
                this.totalValues.rmpmTotal = 0;
            } else {
                this.totalValues.rmpmTotal = this.totalValues.rmTotal;
                this.totalWasteCalculate(this.totalValues.rmpmTotal);
            }
        }
    }

    onBarClick(barData) {
        if (this.clusterDetails === 'South Asia') {
            barData.type = "Factory";
            var data = JSON.parse(this.dataService.getStorageData('selectedWasteDBGranularity'));
            if (this.selectedType !== 'sku') {

                data.factory = { id: barData.id, name: barData.name };
                this.dataService.setStorageData('selectedWasteDBGranularity', JSON.stringify(data));
            }
            /* istanbul ignore else */
            this.selectedType = 'sku';
            /* istanbul ignore else */
            this.tabClick.emit(this.selectedType);
        } else {
            /* istanbul ignore else */
            this.barClick.emit(barData);
            /* istanbul ignore else */
            this.toggleTabname.emit(this.selectedtabname);
        }
    }

    ngOnDestroy(): void {
        this.dataService.removeAllListnersFor('stackBarFilterChange');
        this.dataService.removeAllListnersFor('stackBarSFGChange');

        // if (this.filterSelection) {
        //   this.filterSelection.unsubscribe();
        // }

        if (this.unitSelectedParams) {
            this.unitService.unsubscribe();
        }
    }

    getYUnit() {
        const yUnit = this.dataService.getYUnit(this.currencyData.yUnit);

        this.currencyData.symbolCode = yUnit.symbolCode;
        this.currencyData.label = yUnit.label;
    }

    totalWasteCalculate(maxVal: number): void {
        if (maxVal < 0) {
            maxVal = Math.abs(maxVal);
        }
        if (maxVal >= 1000000) {
            // millions
            this.totalRange.label = 'Million';
            this.totalRange.code = 'm';
            this.totalRange.divisionfactor = 1000000;

        } else if (maxVal >= 1000) {
            // kilos
            this.totalRange.label = 'Kilo';
            this.totalRange.code = 'k';
            this.totalRange.divisionfactor = 1000;
        } else {
            this.totalRange.label = '';
            this.totalRange.code = '';
            this.totalRange.divisionfactor = 1;
        }
    }

    selectView(type: any) {
        let selectedType = '';
        if (type.index === 0 && !this.removeLine) {
            selectedType = 'line';
        } else {
            selectedType = 'sku';
        }

        if (this.lineOrSKU !== selectedType) {
            this.tabClick.emit(selectedType);
        }
    }

    selectSortOrder(type: string, sortOrder: string) {
        this.sortedWith = type;
        this.sortType = sortOrder;

        if (this.sortedWith === 'name') {
            let sortName = this.sortedWith;
            if (this.selectedType === 'line') {
                sortName = this.displayLabel;
            }
            if (this.sortType === 'ascending') {
                this.plotData.sort((a, b) => {
                    const nameA = a[sortName].toUpperCase(); // ignore upper and lowercase
                    const nameB = b[sortName].toUpperCase(); // ignore upper and lowercase
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            } else {
                this.plotData.sort((a, b) => {
                    const nameA = a[sortName].toUpperCase(); // ignore upper and lowercase
                    const nameB = b[sortName].toUpperCase(); // ignore upper and lowercase
                    if (nameB < nameA) {
                        return -1;
                    }
                    if (nameB > nameA) {
                        return 1;
                    }

                    // names must be equal
                    return 0;
                });
            }
        } else {
            if (this.sortType === 'ascending') {
                this.plotData.sort((a, b) => {
                    return a[this.sortedWith] - b[this.sortedWith];
                });

            } else {
                this.plotData.sort((a, b) => {
                    return b[this.sortedWith] - a[this.sortedWith];
                });
            }
        }
    }

    isHide(type: string, hoveredItem?: PlotData) {

        let status = true;
        if (this.selectedType === 'line') {
            status = false;
        } else if (this.selectedType === 'linetosku') {
            status = false;
        }
        if (type === 'adjRM' || type === 'adjPM') {
            if (this.selectedType === 'sku') {
                status = false;
            }
        }
        if (hoveredItem && this.selectedType === 'sku') {
            const selectedData = this.wasteDataResponse.skuWasteData.find(data => data.id === hoveredItem.id);
            if (type === 'adjFG' && selectedData.prodType !== 'FERT') {
                status = false;
            } else if (type === 'adjSFG' && selectedData.prodType !== 'HALB') {
                status = false;
            }
        }
        return status;
    }

    getWidth(barData: PlotData) {
        let totalStock = 0;
        if (barData) {
            if (this.showInGraph.isStock) {
                totalStock += barData.adjRMWaste;
                totalStock += barData.adjPMWaste;
                totalStock += barData.adjFGWaste;
                if (this.showInGraph.sfg) {
                    totalStock += barData.adjSFGWaste;
                }
            }
        }
        return Math.abs((totalStock * 100) / barData.total) + '%';
    }

    getABSValue(component, bar) {
        let value = 0;
        if (this.showTotalForBar) {
            value = component.value * 100 / bar.total;
        } else {
            value = component.value * 100 / bar.componentTotal;
        }
        return Math.abs(value) + '%';
    }

    negativeCaseCheck(currentWaste, prevWaste) {
        let wasteIncreased = true;
        /**
         * waste cost negative - raw materials were under utilised with NO WASTE
         * waste cost positive - raw materials were utilised and waste produced
         * if both costs negative || both are positive - magnitude value should be compared
         * if current is negative and prev is positive - GREEN
         * if current is positive and prev is negative - RED
         * if either value is 0 - new logic
         * function returns true - RED, false - GREEN
         */


        if (currentWaste === 0 || prevWaste === 0) {
            if (currentWaste === 0) {
                if (prevWaste >= 0) {
                    // current is lesser - positive scenario
                    wasteIncreased = false;
                }
            } else if (prevWaste === 0) {
                if (currentWaste <= 0) {
                    wasteIncreased = false;
                }
            }
        } else {
            if (currentWaste < 0 && prevWaste < 0) {
                wasteIncreased = Math.abs(currentWaste) < Math.abs(prevWaste);
            } else if (currentWaste > 0 && prevWaste > 0) {
                wasteIncreased = currentWaste > prevWaste;
            } else if ((currentWaste < 0 && prevWaste > 0)) {
                wasteIncreased = false;
            }
        }


        return wasteIncreased;
    }

}

