import { Pipe, PipeTransform } from '@angular/core';
import * as timeZone from 'moment-timezone';
import moment from 'moment';
@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {

  clientTimeZone: string;

  constructor() {
    this.clientTimeZone = timeZone.tz.guess();
  }

  convertUTCToTimezone(utcDt, utcDtFormat, timezone) {
    return moment.utc(utcDt, utcDtFormat).tz(timezone).format('YYYY-MM-DD hh:mm:ss A');
  }

  transform(value: any, ...args: any[]): any {
    const localDateTime = this.convertUTCToTimezone(value, null, this.clientTimeZone);
    if (localDateTime) {
      const seconds = Math.floor((+new Date() - +new Date(localDateTime)) / 1000);
      if (seconds < 29) {
        return 'Just now';
      }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };
      let counter;
      for (const i of Object.keys(intervals)) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0) {
          if (counter === 1) {
            return counter + ' ' + i + ' ago';
          } else {
            return counter + ' ' + i + 's ago';
          }
        }
      }
    }
    return localDateTime;
  }

}
