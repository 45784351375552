import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currencyRateFormatter'
})

export class CurrencyRateFormatterPipe implements PipeTransform {
    /* istanbul ignore next */
    transform(value: number, currencyRate: any): number {
        if (currencyRate) {
            return value * currencyRate;
        }
        return value;
    }
}
