import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnvironmentService } from './services/environment.service';
import { NetworkService } from './services/network.service';




@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [EnvironmentService, NetworkService]
})
export class CoreModule { }
