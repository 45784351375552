import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { PermissionGuard } from './core/guard/permission.guard';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';
import { MatInputModule, MatButtonModule, MatFormFieldModule, MatDialogModule, MatIconModule, MatTooltipModule } from '@angular/material';
import { DashboardFilterComponent } from './shared/components/dashboard-filter/dashboard-filter.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthTokenInterceptor } from './core/adal/auth-token-interceptor';
import { ToastrModule } from 'ngx-toastr';
import { SelectLineComponent } from './shared/components/select-line/select-line.component';
import { environment, protectedResourceMap } from 'src/environments/environment';
import { Usagereport } from './shared/handlers/usagereport';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { AuthGuardService } from './core/services/can-activate.guard.service';
import { IdleTimeoutService } from './core/services/idle-timeout.service';

export function loggerCallback(message) {
}
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
registerLocaleData(en);
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        CoreModule,
        SharedModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ToastrModule.forRoot(),
        FormsModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatIconModule,
        MatTooltipModule,
        NgxPermissionsModule.forRoot(),
        PopoverModule.forRoot(),
        MsalModule.forRoot({
            auth: environment.msalAuthConfig.auth,
            cache: {
                cacheLocation: 'sessionStorage',
                storeAuthStateInCookie: isIE, // set to true for IE 11
            },
            framework: {
                unprotectedResources: ['https://www.microsoft.com/en-us/'],
                protectedResourceMap: new Map(protectedResourceMap)
            },
            system: {
                loadFrameTimeout: 30000,
            }
        }, {
            popUp: false,
            consentScopes: [
                'user.read',
                'openid',
                'profile',
            ],
            extraQueryParameters: {}
        }),
    ],
    providers: [
        IdleTimeoutService,
        Usagereport,
        AuthGuardService, PermissionGuard, NgxPermissionsService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthTokenInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    entryComponents: [
        DashboardFilterComponent,
        SelectLineComponent,
    ]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

