import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterHandlerService {
  /* istanbul ignore next */
  config: any = {
    positionClass: 'toast-bottom-right'
  };
  /* istanbul ignore next */
  constructor(
    private toastr: ToastrService
  ) { }
  /* istanbul ignore next */
  showToast(toastType, title, msg, overrideConfig?) {
    switch (toastType) {
      case 'success':
        this.toastr.success(msg, title, this.config);
        break;
      case 'failure':
        this.toastr.error(msg, title, this.config);
        break;
    }
  }
}
