import {
  Directive,
  HostBinding,
  HostListener,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
export interface FileHandle {
  file: File;
  url: SafeUrl;
}
@Directive({
  selector: '[appDrag]'
})
export class DragDirective {

  @Output() files: EventEmitter<FileHandle[]> = new EventEmitter();

  @Input() page: string;

  @HostBinding('style.background') public background = '#fff';

  constructor(private sanitizer: DomSanitizer) { }

  @HostListener('dragover', ['$event']) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#999';
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(evnt: DragEvent) {
    evnt.preventDefault();
    evnt.stopPropagation();
    this.background = '#fff';
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '#fff';
    const files: FileHandle[] = [];

    if (evt.dataTransfer.files.length > 1 ||
      (this.page !== 'line-layout' && evt.dataTransfer.files[0].name.substr(evt.dataTransfer.files[0].name.length - 3) !== 'csv')) {
      return false;
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < evt.dataTransfer.files.length; i++) {
      const file = evt.dataTransfer.files[i];
      const url = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
      files.push({ file, url });
    }
    if (files.length > 0) {
      this.files.emit(files);
    }
  }

}
