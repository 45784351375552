import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class WasteDbHelperService {

  constructor() { }

  skuTypeChecker(lineOrSku, granularity) {
    if (lineOrSku === 'sku' && granularity &&
      granularity.factory && granularity.factory.id) {
      return true;
    }
    return false;
  }

}
