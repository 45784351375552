import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SharedService } from '../../service/shared.service';
import { DataService } from '../../service/data.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss']
})
export class DashboardFilterComponent implements OnInit, OnDestroy {
  detailLevelList: any[];
  bigCatList: any[];
  catList: any[];
  subCatList: any[];
  brandList: any[];
  prodOrderList: any[];
  productList: any[];
  bomList: any[];
  selectedDBDetailLevel: any;

  selectedDetailvalues: any = {
    bigCat: null,
    category: null,
    subCat: null,
    brand: null,
    prodOrder: null,
    product: null,
    bom: null,
  };

  visualizationLevelList: any[];
  selectedDBvisualizationLevel: any = {
    label: null,
    code: null,
    level: null
  };

  selectedVisualizationValues: any = {
    cluster: null,
    factory: null,
    line: null,
    machine: null
  };

  clusterList: any[];
  factoryList: any[];
  lineList: any[];
  machineList: any[];

  selectedWasteDBGranularity: any;
  selectedWasteDBDetail: any;

  filteredData: any = {
    cluster: {
      list: [],
      searchText: ''
    },
    factory: {
      list: [],
      searchText: ''
    },
    line: {
      list: [],
      searchText: ''
    },
    bigCat: {
      list: [],
      searchText: ''
    },
    category: {
      list: [],
      searchText: ''
    },
    subCat: {
      list: [],
      searchText: ''
    },
    brand: {
      list: [],
      searchText: ''
    },
    product: {
      list: [],
      searchText: ''
    },
    bom: {
      list: [],
      searchText: ''
    }
  };

  getClusterListSubscr: Subscription;
  getFactoryListSubscr: Subscription;
  getLineListSubscr: Subscription;
  getBigCategoryListSubscr: Subscription;
  getCategoryListSubScr: Subscription;
  getSubCategoryListSubscr: Subscription;
  getBrandListSubscr: Subscription;
  getProductListSubscr: Subscription;
  getBOMComponentListSubscr: Subscription;

  constructor(
    public dialogRef: MatDialogRef<DashboardFilterComponent>,
    private service: SharedService,
    private dataSer: DataService,
    @Inject(MAT_DIALOG_DATA) data
  ) { }

  ngOnInit() {
    this.visualizationLevelList = [
      {
        label: 'Cluster',
        code: 'cluster',
        translateCode: 'filter_dropdowns.cluster',
        level: 1
      }, {
        label: 'Factory',
        code: 'factory',
        translateCode: 'filter_dropdowns.factory',
        level: 2
      }, {
        label: 'Line',
        code: 'line',
        translateCode: 'filter_dropdowns.line',
        level: 3
      }
      // , { label: 'Machine', code: 'machine', level: 4 }
    ];
    this.selectedDBvisualizationLevel = this.visualizationLevelList[2];

    this.detailLevelList = this.dataSer.setDetailLevelList();
    // this.selectedDBDetailLevel = this.detailLevelList[0];

    this.setFilterValues();

    this.getClusterList();
  }

  setFilterValues() {
    if (this.dataSer.getStorageData('selectedWasteDBGranularity')) {
      this.selectedWasteDBGranularity = JSON.parse(this.dataSer.getStorageData('selectedWasteDBGranularity'));
    }

    if (this.dataSer.getStorageData('selectedWasteDBDetail')) {
      this.selectedWasteDBDetail = JSON.parse(this.dataSer.getStorageData('selectedWasteDBDetail'));

      if (this.selectedWasteDBDetail.bigCat && this.selectedWasteDBDetail.bigCat !== '') {
        this.selectedDBDetailLevel = this.detailLevelList[0];
      }

      if (this.selectedWasteDBDetail.category && this.selectedWasteDBDetail.category !== '') {
        this.selectedDBDetailLevel = this.detailLevelList[1];
      }

      if (this.selectedWasteDBDetail.subCat && this.selectedWasteDBDetail.subCat !== '') {
        this.selectedDBDetailLevel = this.detailLevelList[2];
      }

      if (this.selectedWasteDBDetail.brand && this.selectedWasteDBDetail.brand !== '') {
        this.selectedDBDetailLevel = this.detailLevelList[3];
      }

      if (this.selectedWasteDBDetail.product && this.selectedWasteDBDetail.product !== '') {
        this.selectedDBDetailLevel = this.detailLevelList[4];
      }

      if (this.selectedWasteDBDetail.bom && this.selectedWasteDBDetail.bom !== '') {
        this.selectedDBDetailLevel = this.detailLevelList[5];
      }
    }
  }

  changeDetailLevel() {
    if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level <= 5) {
      this.selectedDetailvalues.bom = '';
      if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level <= 4) {
        this.selectedDetailvalues.product = '';
        this.selectedDetailvalues.prodOrder = '';
        if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level <= 3) {
          this.selectedDetailvalues.brand = '';
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level <= 2) {
            this.selectedDetailvalues.subCat = '';
            if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level === 1) {
              this.selectedDetailvalues.category = '';
            }
          }
        }
      }
    }
  }

  changeVisualizationLevel() {
    if (this.selectedDBvisualizationLevel.level === 2) {
      this.selectedVisualizationValues.line = null;
    } else if (this.selectedDBvisualizationLevel.level === 1) {
      this.selectedVisualizationValues.line = null;
      this.selectedVisualizationValues.factory = null;
    }

    if (this.selectedDBvisualizationLevel.level <= 2) {
      // this.selectedDBDetailLevel = this.detailLevelList[0];
      this.selectedDetailvalues.bigCat = null;
      this.selectedDetailvalues.category = null;
      this.selectedDetailvalues.subCat = null;
      this.selectedDetailvalues.brand = null;
      this.selectedDetailvalues.prodOrder = null;
      this.selectedDetailvalues.product = null;
      this.selectedDetailvalues.bom = null;
    }
  }

  close_dialogue() {
    this.dialogRef.close();
  }

  getClusterList() {
    this.filteredData.cluster.searchText = null;
    this.filteredData.factory.searchText = null;
    this.filteredData.line.searchText = null;

    this.getClusterListSubscr = this.service.getClusterList()
      .subscribe(result => {
        if (result) {
          this.clusterList = result;
          this.filteredData.cluster.list = this.clusterList;
          if (this.clusterList && this.clusterList.length === 1) {
            this.selectedVisualizationValues.cluster = this.clusterList[0];
            this.filteredData.cluster.searchText = this.clusterList[0];
            // this.clusterChanged();
          } else {
            this.clusterList.forEach(element => {
              if (this.selectedWasteDBGranularity && this.selectedWasteDBGranularity.cluster &&
                element.id === this.selectedWasteDBGranularity.cluster.id) {
                this.selectedVisualizationValues.cluster = element;
                this.filteredData.cluster.searchText = element;
                this.clusterChanged();
              }
            });
          }
        }
      });
  }

  clusterChanged() {
    this.selectedVisualizationValues.line = '';

    this.filteredData.factory.searchText = null;
    this.filteredData.line.searchText = null;

    // if (!this.selectedWasteDBDetail) {
    //   this.selectedDBDetailLevel = {
    //     label: '',
    //     code: '',
    //     level: 0
    //   };
    // }

    this.factoryList = [];
    this.lineList = [];
    this.machineList = [];

    this.bigCatList = [];
    this.catList = [];
    this.subCatList = [];
    this.brandList = [];
    this.productList = [];
    this.bomList = [];


    this.getFactoryListSubscr = this.service.getFactoryList(this.selectedVisualizationValues)
      .subscribe(result => {
        if (result) {
          this.factoryList = result;
          this.filteredData.factory.list = this.factoryList;
          if (this.factoryList && this.factoryList.length === 1) {
            this.selectedVisualizationValues.factory = this.factoryList[0];
            this.filteredData.factory.searchText = this.factoryList[0];
            // this.factoryChanged();
          } else {
            this.factoryList.forEach(element => {
              if (this.selectedWasteDBGranularity && this.selectedWasteDBGranularity.factory &&
                element.id === this.selectedWasteDBGranularity.factory.id) {
                this.selectedVisualizationValues.factory = element;
                this.filteredData.factory.searchText = element;
                this.factoryChanged();
              }
            });
          }
        }
      });
  }

  factoryChanged() {
    this.selectedVisualizationValues.line = '';
    this.filteredData.line.searchText = null;

    // if (!this.selectedWasteDBDetail) {
    //   this.selectedDBDetailLevel = {
    //     label: '',
    //     code: '',
    //     level: 0
    //   };
    // }

    this.lineList = [];
    this.machineList = [];

    this.bigCatList = [];
    this.catList = [];
    this.subCatList = [];
    this.brandList = [];
    this.productList = [];
    this.bomList = [];

    this.getLineListSubscr = this.service.getLineList(this.selectedVisualizationValues)
      .subscribe(result => {
        if (result) {
          this.lineList = result;
          this.filteredData.line.list = this.lineList;
          if (this.lineList && this.lineList.length === 1) {
            this.selectedVisualizationValues.line = this.lineList[0];
            this.filteredData.line.searchText = this.lineList[0];
            // this.lineChanged();
          } else {
            this.lineList.forEach(element => {
              if (this.selectedWasteDBGranularity && this.selectedWasteDBGranularity.line
                && element.id === this.selectedWasteDBGranularity.line.id) {
                this.selectedVisualizationValues.line = element;
                this.filteredData.line.searchText = element;
                this.lineChanged();
              }
            });
          }
        }
      });
  }

  lineChanged() {
    this.machineList = [];

    // if (!this.selectedWasteDBDetail) {
    //   this.selectedDBDetailLevel = {
    //     label: '',
    //     code: '',
    //     level: 0
    //   };
    // }

    this.bigCatList = [];
    this.catList = [];
    this.subCatList = [];
    this.brandList = [];
    this.productList = [];
    this.bomList = [];

    this.selectedDetailvalues.bigCat = '';
    this.selectedDetailvalues.category = '';
    this.selectedDetailvalues.subCat = '';
    this.selectedDetailvalues.brand = '';
    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.filteredData.bigCat.searchText = null;
    this.filteredData.category.searchText = null;
    this.filteredData.subCat.searchText = null;
    this.filteredData.brand.searchText = null;
    this.filteredData.product.searchText = null;
    this.filteredData.bom.searchText = null;

    // this.service.getMachineList(this.selectedVisualizationValues).subscribe(result => {
    //   this.machineList = result;
    // });

    this.getBigCategoryListSubscr = this.service.getBigCategoryList(this.selectedVisualizationValues)
      .subscribe(result => {
        if (result) {
          this.bigCatList = result.result;
          this.filteredData.bigCat.list = this.bigCatList;
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level >= 1) {
            if (this.bigCatList && this.bigCatList.length === 1) {
              this.selectedDetailvalues.bigCat = this.bigCatList[0];
              this.filteredData.bigCat.searchText = this.bigCatList[0];
              // this.bigCatChanged();
            } else {
              this.bigCatList.forEach(element => {
                if (this.selectedWasteDBDetail && this.selectedWasteDBDetail.bigCat &&
                  element.id === this.selectedWasteDBDetail.bigCat.id) {
                  this.selectedDetailvalues.bigCat = element;
                  this.filteredData.bigCat.searchText = element;
                  this.bigCatChanged();
                }
              });
            }
          }
        }
      });
  }

  bigCatChanged() {
    this.catList = [];
    this.subCatList = [];
    this.brandList = [];
    this.productList = [];
    this.bomList = [];

    this.selectedDetailvalues.category = '';
    this.selectedDetailvalues.subCat = '';
    this.selectedDetailvalues.brand = '';
    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.filteredData.category.searchText = null;
    this.filteredData.subCat.searchText = null;
    this.filteredData.brand.searchText = null;
    this.filteredData.product.searchText = null;
    this.filteredData.bom.searchText = null;

    this.getCategoryListSubScr = this.service.getCategoryList(this.selectedVisualizationValues, this.selectedDetailvalues)
      .subscribe(result => {
        if (result) {
          this.catList = result.result;
          this.filteredData.category.list = this.catList;
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level >= 2) {
            if (this.catList && this.catList.length === 1) {
              this.selectedDetailvalues.category = this.catList[0];
              this.filteredData.category.searchText = this.catList[0];
              // this.catChanged();
            } else {
              this.catList.forEach(element => {
                if (this.selectedWasteDBDetail && this.selectedWasteDBDetail.category &&
                  element.id === this.selectedWasteDBDetail.category.id) {
                  this.selectedDetailvalues.category = element;
                  this.filteredData.category.searchText = element;
                  // this.selectedDBDetailLevel = this.detailLevelList[1];
                  this.catChanged();
                }
              });
            }
          }
        }
      });
  }

  catChanged() {
    this.subCatList = [];
    this.brandList = [];
    this.productList = [];
    this.bomList = [];

    this.selectedDetailvalues.subCat = '';
    this.selectedDetailvalues.brand = '';
    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.selectedDetailvalues.subCat = '';
    this.selectedDetailvalues.brand = '';
    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.getSubCategoryListSubscr = this.service.getSubCategoryList(this.selectedVisualizationValues, this.selectedDetailvalues)
      .subscribe(result => {
        if (result) {
          this.subCatList = result.result;
          this.filteredData.subCat.list = this.subCatList;
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level >= 3) {
            if (this.subCatList && this.subCatList.length === 1) {
              this.selectedDetailvalues.subCat = this.subCatList[0];
              this.filteredData.subCat.searchText = this.subCatList[0];
              // this.subCatChanged();
            } else {
              this.subCatList.forEach(element => {
                if (this.selectedWasteDBDetail && this.selectedWasteDBDetail.subCat &&
                  element.id === this.selectedWasteDBDetail.subCat.id) {
                  this.selectedDetailvalues.subCat = element;
                  this.filteredData.subCat.searchText = element;
                  // this.selectedDBDetailLevel = this.detailLevelList[2];
                  this.subCatChanged();
                }
              });
            }
          }
        }
      });
  }

  subCatChanged() {
    this.brandList = [];
    this.productList = [];
    this.bomList = [];

    this.selectedDetailvalues.brand = '';
    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.selectedDetailvalues.brand = '';
    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.getBrandListSubscr = this.service.getBrandList(this.selectedVisualizationValues, this.selectedDetailvalues)
      .subscribe(result => {
        if (result) {
          this.brandList = result.result;
          this.filteredData.brand.list = this.brandList;
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level >= 4) {
            if (this.brandList && this.brandList.length === 1) {
              this.selectedDetailvalues.brand = this.brandList[0];
              this.filteredData.brand.searchText = this.brandList[0];
              // this.brandChanged();
            } else {
              this.brandList.forEach(element => {
                if (this.selectedWasteDBDetail && this.selectedWasteDBDetail.brand &&
                  element.id === this.selectedWasteDBDetail.brand.id) {
                  this.selectedDetailvalues.brand = element;
                  this.filteredData.brand.searchText = element;
                  // this.selectedDBDetailLevel = this.detailLevelList[3];
                  this.brandChanged();
                }
              });
            }
          }
        }
      });
  }

  brandChanged() {
    this.productList = [];
    this.bomList = [];

    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.selectedDetailvalues.prodOrder = '';
    this.selectedDetailvalues.product = '';
    this.selectedDetailvalues.bom = '';

    this.getProductListSubscr = this.service.getProductList(this.selectedVisualizationValues, this.selectedDetailvalues)
      .subscribe(result => {
        if (result) {
          this.productList = result.result;
          this.filteredData.product.list = this.productList;
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level >= 6) {
            if (this.productList && this.productList.length === 1) {
              this.selectedDetailvalues.product = this.productList[0];
              this.filteredData.product.searchText = this.productList[0];
              // this.productChanged();
            } else {
              this.productList.forEach(element => {
                if (this.selectedWasteDBDetail && this.selectedWasteDBDetail.product &&
                  element.id === this.selectedWasteDBDetail.product.id) {
                  this.selectedDetailvalues.product = element;
                  this.filteredData.product.searchText = element;
                  // this.selectedDBDetailLevel = this.detailLevelList[5];
                  this.productChanged();
                }
              });
            }
          }
        }
      });
  }

  productChanged() {
    this.bomList = [];

    this.selectedDetailvalues.bom = '';

    this.selectedDetailvalues.bom = '';

    this.getBOMComponentListSubscr = this.service.getBOMComponentList(this.selectedVisualizationValues, this.selectedDetailvalues)
      .subscribe(result => {
        if (result) {
          this.bomList = result.result;
          this.filteredData.bom.list = this.bomList;
          if (this.selectedDBDetailLevel && this.selectedDBDetailLevel.level === 7) {
            if (this.bomList && this.bomList.length === 1) {
              this.selectedDetailvalues.bom = this.bomList[0];
              this.filteredData.bom.searchText = this.bomList[0];
            } else {
              this.bomList.forEach(element => {
                if (this.selectedWasteDBDetail && this.selectedWasteDBDetail.bom &&
                  element.id === this.selectedWasteDBDetail.bom.id) {
                  this.selectedDetailvalues.bom = element;
                  this.filteredData.bom.searchText = element;
                  // this.selectedDBDetailLevel = this.detailLevelList[6];
                }
              });
            }
          }
        }
      });
  }

  applyFilters() {
    const dataToEmit = {
      visualizationVal: this.selectedVisualizationValues,
      detailLevel: this.selectedDetailvalues
    };

    this.dataSer.setStorageData('selectedWasteDBGranularity', JSON.stringify(this.selectedVisualizationValues));
    this.dataSer.setStorageData('selectedWasteDBDetail', JSON.stringify(this.selectedDetailvalues));

    this.dialogRef.close(dataToEmit);
  }

  clusterValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.clusterList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedVisualizationValues.cluster = selection;
        this.clusterChanged();
        return selection.name;
      }
    }
  }

  factoryValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.factoryList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedVisualizationValues.factory = selection;
        this.factoryChanged();
        return selection.name;
      }
    }
  }

  lineValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.lineList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedVisualizationValues.line = selection;
        this.lineChanged();
        return selection.name;
      }
    }
  }

  bigCatValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.bigCatList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedDetailvalues.bigCat = selection;
        this.bigCatChanged();
        return selection.value;
      }
    }
  }

  categoryValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.catList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedDetailvalues.category = selection;
        this.catChanged();
        return selection.value;
      }
    }
  }

  subCatValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.subCatList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedDetailvalues.subCat = selection;
        this.subCatChanged();
        return selection.value;
      }
    }
  }

  brandValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.brandList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedDetailvalues.brand = selection;
        this.brandChanged();
        return selection.value;
      }
    }
  }

  productValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.productList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedDetailvalues.product = selection;
        this.productChanged();
        return selection.value;
      }
    }
  }

  bomValueMapper = (selectedObj) => {
    if (selectedObj) {
      const selection = this.bomList.find(e => {
        return e.id === selectedObj.id;
      });
      if (selection) {
        this.selectedDetailvalues.bom = selection;
        // this.bomChanged();
        return selection.value;
      }
    }
  }

  applyFilter(event: string, changedFilter: string) {
    const evt = event + '';

    switch (changedFilter) {
      case 'cluster':
        if (!evt) {
          this.filteredData.cluster.list = this.clusterList;
        } else {
          this.filteredData.cluster.list = this.clusterList.filter
            (item => (item.id + '') === evt || item.name.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.cluster.list.length !== 1) {
        //   this.visualizationValuesOK = false;
        // }
        // else {
        //   if (this.filteredData.cluster.list[0].name === this.filteredData.cluster.searchText.name) {
        //     this.visualizationValuesOK = false;
        //   }
        // }
        break;
      case 'factory':
        if (!evt) {
          this.filteredData.factory.list = this.factoryList;
        } else {
          this.filteredData.factory.list = this.factoryList.filter
            (item => (item.id + '') === evt || item.name.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.factory.list.length !== 1) {
        //   this.visualizationValuesOK = false;
        // }
        break;
      case 'line':
        if (!evt) {
          this.filteredData.line.list = this.lineList;
        } else {
          this.filteredData.line.list = this.lineList.filter
            (item => (item.id + '') === evt || item.name.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.line.list.length !== 1) {
        //   this.visualizationValuesOK = false;
        // }
        break;
      case 'bigCat':
        if (!evt) {
          this.filteredData.bigCat.list = this.bigCatList;
        } else {
          this.filteredData.bigCat.list = this.bigCatList.filter
            (item => (item.id + '') === evt || item.value.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.bigCat.list.length !== 1) {
        //   this.detailValuesOK = false;
        // }
        break;
      case 'category':
        if (!evt) {
          this.filteredData.category.list = this.catList;
        } else {
          this.filteredData.category.list = this.catList.filter
            (item => (item.id + '') === evt || item.value.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.category.list.length !== 1) {
        //   this.detailValuesOK = false;
        // }
        break;
      case 'subCat':
        if (!evt) {
          this.filteredData.subCat.list = this.subCatList;
        } else {
          this.filteredData.subCat.list = this.subCatList.filter
            (item => (item.id + '') === evt || item.value.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.subCat.list.length !== 1) {
        //   this.detailValuesOK = false;
        // }
        break;
      case 'brand':
        if (!evt) {
          this.filteredData.brand.list = this.brandList;
        } else {
          this.filteredData.brand.list = this.brandList.filter
            (item => (item.id + '') === evt || item.value.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.brand.list.length !== 1) {
        //   this.detailValuesOK = false;
        // }
        break;
      case 'product':
        if (!evt) {
          this.filteredData.product.list = this.productList;
        } else {
          this.filteredData.product.list = this.productList.filter
            (item => (item.id + '') === evt || item.value.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.product.list.length !== 1) {
        //   this.detailValuesOK = false;
        // }
        break;
      case 'bom':
        if (!evt) {
          this.filteredData.bom.list = this.bomList;
        } else {
          this.filteredData.bom.list = this.bomList.filter
            (item => (item.id + '') === evt || item.value.toLocaleLowerCase().indexOf(evt.toLocaleLowerCase()) >= 0);
        }

        // if (this.filteredData.bom.list.length !== 1) {
        //   this.detailValuesOK = false;
        // }
        break;
    }
  }

  ngOnDestroy(): void {
    this.unSubscribe(this.getClusterListSubscr);
    this.unSubscribe(this.getFactoryListSubscr);
    this.unSubscribe(this.getLineListSubscr);
    this.unSubscribe(this.getBigCategoryListSubscr);
    this.unSubscribe(this.getCategoryListSubScr);
    this.unSubscribe(this.getSubCategoryListSubscr);
    this.unSubscribe(this.getBrandListSubscr);
    this.unSubscribe(this.getProductListSubscr);
    this.unSubscribe(this.getBOMComponentListSubscr);
  }

  unSubscribe(sub: Subscription) {
    if (sub) {
      sub.unsubscribe();
    }
  }
}
