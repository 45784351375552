import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-waste-comments-list',
  templateUrl: './waste-comments-list.component.html',
  styleUrls: ['./waste-comments-list.component.scss']
})
export class WasteCommentsListComponent implements OnInit {
  @Input() commentsList: any[];
  constructor() { }

  ngOnInit() {
  }

}
