import { Injectable } from '@angular/core';
import { NetworkService } from 'src/app/core/services/network.service';
// import { AdalService } from 'adal-angular4';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { DataService } from './data.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, delay } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service.service';

@Injectable({
  providedIn: 'root'
})

export class SharedService {
  /* istanbul ignore next */
  constructor(
    private networkSer: NetworkService,
    // private adal: AdalService,
    public auth: AuthService,
    private dataService: DataService
  ) { }

  /* istanbul ignore next */
  getComment(machineDtls: any): any {
    const timeResponse = this.setTimeEpoc();

    const req: any = {
      // ReportType: 'monthly980e',
      // Reportperiod: '102019',
      // DetailType: 'BOM',
      // DetailId: '9000',
      // DetailValue: 'sku001',
      // Stratification: 'none',
      // UserEmail: this.adal.userInfo.profile.upn,
      // firstName: this.adal.userInfo.profile.given_name,
      // lastName: this.adal.userInfo.profile.family_name,
      GranularityLevel: 'Machines',
      MachineId: machineDtls.id,
      GranularityValue: machineDtls.name,
      ReportType: timeResponse.currrentFilter,
      ReportFromTime: timeResponse.FromTimeEpoc,
      ReportToTime: timeResponse.ToTimeEpoc
    };

    return this.networkSer.httpReq(
      'get', 'comments', req);
  }
  /* istanbul ignore next */
  saveUpdateComment(commentDetails: any, action: any): any {
    const timeResponse = this.setTimeEpoc();

    // commentDetails.DetailType = 'BOM';
    // commentDetails.DetailId = '9000';
    // commentDetails.DetailValue = 'sku001';
    // commentDetails.Stratification = 'none';
    commentDetails.ReportType = timeResponse.currrentFilter;
    commentDetails.ReportFromTime = timeResponse.FromTimeEpoc;
    commentDetails.ReportToTime = timeResponse.ToTimeEpoc;
    // commentDetails.UserEmail = this.adal.userInfo.profile.upn;
    //  commentDetails.FirstName = this.adal.userInfo.profile.given_name;
    //  commentDetails.LastName = this.adal.userInfo.profile.family_name;

    let urlType = '';
    let url = 'comments';
    switch (action) {
      case 'update':
        urlType = 'put';
        url += '/' + commentDetails.Id;
        break;

      case 'add':
        urlType = 'post';
        break;
    }
    return this.networkSer.httpReq(
      urlType, url, commentDetails);
  }
  /* istanbul ignore next */
  getClusterList(): any {
    return this.networkSer.httpReq(
      'get',
      'clusters');
  }
  /* istanbul ignore next */
  getClusterListWithParam(detailsFilterCluster: any): any {
    let url = 'clusters';

    if (detailsFilterCluster) {
      if (detailsFilterCluster.bigCat && detailsFilterCluster.bigCat.id) {
        url = url + '?BigCategoryId=' + detailsFilterCluster.bigCat.id;

        if (detailsFilterCluster.category && detailsFilterCluster.category.id) {
          url = url + '&CategoryId=' + detailsFilterCluster.category.id;

          if (detailsFilterCluster.subCat && detailsFilterCluster.subCat.id) {
            url = url + '&SubCategoryId=' + detailsFilterCluster.subCat.id;

            if (detailsFilterCluster.brand && detailsFilterCluster.brand.id) {
              url = url + '&BrandId=' + detailsFilterCluster.brand.id;

              if (detailsFilterCluster.product && detailsFilterCluster.product.id) {
                url = url + '&ProductId=' + detailsFilterCluster.product.id;

                if (detailsFilterCluster.bom && detailsFilterCluster.bom.id) {
                  url = url + '&BomComponentId=' + detailsFilterCluster.bom.id;
                }
              }
            }
          }
        }
      }
    }
    return this.networkSer.httpReq('get', url);
  }

  // getClusterList(): any {
  //   return this.networkSer.httpReq(
  //     'get-dfos',
  //     'collectionName/clusters').pipe(map(
  //       data => this.dataService.processClusterResp(data)
  //     ));
  // }
  /* istanbul ignore next */
  getFactoryList(selectedVisualizationValues: any): any {
    return this.networkSer.httpReq(
      'get',
      'clusters/' + selectedVisualizationValues.cluster.id);
  }
  /* istanbul ignore next */
  getFactoryListWithParam(selectedVisualizationValues: any, detailsFilterFactory: any): any {
    let url = 'clusters/' + selectedVisualizationValues.cluster.id;

    if (detailsFilterFactory) {
      if (detailsFilterFactory.bigCat && detailsFilterFactory.bigCat.id) {
        url = url + '?BigCategoryId=' + detailsFilterFactory.bigCat.id;

        if (detailsFilterFactory.category && detailsFilterFactory.category.id) {
          url = url + '&CategoryId=' + detailsFilterFactory.category.id;

          if (detailsFilterFactory.subCat && detailsFilterFactory.subCat.id) {
            url = url + '&SubCategoryId=' + detailsFilterFactory.subCat.id;

            if (detailsFilterFactory.brand && detailsFilterFactory.brand.id) {
              url = url + '&BrandId=' + detailsFilterFactory.brand.id;

              if (detailsFilterFactory.product && detailsFilterFactory.product.id) {
                url = url + '&ProductId=' + detailsFilterFactory.product.id;

                if (detailsFilterFactory.bom && detailsFilterFactory.bom.id) {
                  url = url + '&BomComponentId=' + detailsFilterFactory.bom.id;
                }
              }
            }
          }
        }
      }
    }
    return this.networkSer.httpReq('get', url);
  }

  // getFactoryList(selectedVisualizationValues: any): any {
  //   if (selectedVisualizationValues.id) {
  //     return this.networkSer.httpReq(
  //       'get-dfos',
  //       'collectionName/clusters').pipe(map(
  //         data => this.dataService.processFactoryResp(data, selectedVisualizationValues.id)
  //       ));
  //   } else if (selectedVisualizationValues.cluster.id) {
  //     return this.networkSer.httpReq(
  //       'get-dfos',
  //       'collectionName/clusters').pipe(map(
  //         data => this.dataService.processFactoryResp(data, selectedVisualizationValues.cluster.id)
  //       ));
  //   }
  // }
  /* istanbul ignore next */
  getLineList(selectedVisualizationValues: any): any {
    // from report page factory id is comming as 'factoryId'
    const factoryId = encodeURIComponent(selectedVisualizationValues.factory && selectedVisualizationValues.factory.factoryId ?
      selectedVisualizationValues.factory.factoryId : selectedVisualizationValues.factory.id);
    return this.networkSer.httpReq(
      'get',
      'clusters/' + selectedVisualizationValues.cluster.id +
      '/factories/' + encodeURIComponent(factoryId));
  }
  /* istanbul ignore next */
  getLineListWithParam(selectedVisualizationValues: any, detailsFilterLines: any): any {
    let url = 'clusters/' + selectedVisualizationValues.cluster.id +
      '/factories/' + selectedVisualizationValues.factory.id;

    if (detailsFilterLines) {
      if (detailsFilterLines.bigCat && detailsFilterLines.bigCat.id) {
        url = url + '?BigCategoryId=' + detailsFilterLines.bigCat.id;

        if (detailsFilterLines.category && detailsFilterLines.category.id) {
          url = url + '&CategoryId=' + detailsFilterLines.category.id;

          if (detailsFilterLines.subCat && detailsFilterLines.subCat.id) {
            url = url + '&SubCategoryId=' + detailsFilterLines.subCat.id;

            if (detailsFilterLines.brand && detailsFilterLines.brand.id) {
              url = url + '&BrandId=' + detailsFilterLines.brand.id;

              if (detailsFilterLines.product && detailsFilterLines.product.id) {
                url = url + '&ProductId=' + detailsFilterLines.product.id;

                if (detailsFilterLines.bom && detailsFilterLines.bom.id) {
                  url = url + '&BomComponentId=' + detailsFilterLines.bom.id;
                }
              }
            }
          }
        }
      }
    }
    return this.networkSer.httpReq('get', url);
  }

  // public getLineList(selectedVisualizationValues: any): any {
  //   return this.networkSer.httpReq('get-dfos', `regions/${selectedVisualizationValues.cluster.id}
  //   /factories/${selectedVisualizationValues.factory.id}`).pipe(map(
  //       data =>
  //         this.dataService.processLineResp(data)
  //     ));
  // }

  // get machine dfos
  /* istanbul ignore next */
  getDfosMachineList(details: any): any {
    const clusterName = details.cluster.name;
    const factoryName = details.factory.name;
    const lineID = details.line.id;
    return this.networkSer.httpReq('get-dfos', `regions/${clusterName}/factories/${factoryName}/lines/${lineID}`).pipe(map(
      data =>
        this.dataService.processMachineResp(data)
    ));
  }
  /* istanbul ignore next */
  // LR POC
  /* istanbul ignore next */
  getDfosMachineListforLR(): any {
    const clusterName = 'Europe';
    const factoryName = 'Bydgoszcz';
    const lineID = '5f67731c42d1b7121adddba1';
    return this.networkSer.httpReq('get-dfos', `regions/${clusterName}/factories/${factoryName}/lines/${lineID}`).pipe(map(
      data =>
        this.dataService.processMachineResp(data)
    ));
  }
  /* istanbul ignore next */

  // LR POC
  getLineRejection(submittedDetailsLR): any {
    let data = {
      lineId: submittedDetailsLR.selectedLine,
      startTime: submittedDetailsLR.startTimeZone,
      endTime: submittedDetailsLR.endTimeZone,
      orderNumber: submittedDetailsLR.orderId
    };
    const urlLR = 'lineRejection';
    return this.networkSer.httpReq('post', urlLR, data);
  }

  /* istanbul ignore next */
  getOrderList(orderDetailsData): any {
    const clusterName = orderDetailsData.cluster;
    const factoryName = orderDetailsData.factory;
    const lineID = orderDetailsData.line;
    const urlforLR = 'lineRejection/orders';
    return this.networkSer.httpReq('get', urlforLR + '/' + `regions/${clusterName}/factories/${factoryName}/lines/${lineID}`);

  }
  /* istanbul ignore next */
  //

  getMachineList(selectedVisualizationValues: any, selectedDetailValues?: any): any {
    const extractedData = {
      clusterID: selectedVisualizationValues.cluster.id || selectedVisualizationValues.cluster.clusterId || null,
      factoryID: selectedVisualizationValues.factory.id || selectedVisualizationValues.factory.factoryId || null,
      lineID: selectedVisualizationValues.line.id || selectedVisualizationValues.line.lineId || null,
    };

    return this.networkSer.httpReq(
      'get',
      'clusters/' + extractedData.clusterID +
      '/factories/' + extractedData.factoryID +
      '/lines/' + extractedData.lineID);
  }
  /* istanbul ignore next */
  setURL(selectedVisualizationValues): string {
    let url = 'clusters/';
    if (selectedVisualizationValues &&
      selectedVisualizationValues.cluster && selectedVisualizationValues.cluster.id &&
      selectedVisualizationValues.cluster.id !== 'All') {
      url += selectedVisualizationValues.cluster.id + '/';
    } else {
      url += 'null' + '/';
    }

    url += 'factories/';
    if (selectedVisualizationValues &&
      selectedVisualizationValues.factory && selectedVisualizationValues.factory.id) {
      url += selectedVisualizationValues.factory.id + '/';
    } else {
      url += 'null' + '/';
    }

    url += 'lines/';
    if (selectedVisualizationValues &&
      selectedVisualizationValues.line && selectedVisualizationValues.line.id) {
      url += selectedVisualizationValues.line.id + '/';
    } else {
      url += 'null' + '/';
    }
    return url;
  }
  /* istanbul ignore next */
  getBigCategoryList(selectedVisualizationValues?: any): any {
    const url = this.setURL(selectedVisualizationValues) + 'bigCategories';

    return this.networkSer.httpReq(
      'get', url);
  }
  /* istanbul ignore next */
  getBigCategoryListNonHierarchy(): any {
    return this.networkSer.httpReq(
      'get',
      'bigCategories');
  }
  /* istanbul ignore next */
  getCategoryList(selectedVisualizationValues: any, selectedDetailValues: any): any {
    let url = this.setURL(selectedVisualizationValues);
    url += 'bigCategories/' + selectedDetailValues.bigCat.id + '/categories';

    return this.networkSer.httpReq(
      'get', url);

  }
  /* istanbul ignore next */
  getSubCategoryList(selectedVisualizationValues: any, selectedDetailValues: any): any {
    let url = this.setURL(selectedVisualizationValues);
    url += 'categories/' + selectedDetailValues.category.id + '/subCategories';

    return this.networkSer.httpReq(
      'get', url);
  }
  /* istanbul ignore next */
  getBrandList(selectedVisualizationValues: any, selectedDetailValues: any): any {
    let url = this.setURL(selectedVisualizationValues);
    url += 'subCategories/' + selectedDetailValues.subCat.id + '/brands';

    return this.networkSer.httpReq(
      'get', url);
  }
  /* istanbul ignore next */
  getProductList(selectedVisualizationValues: any, selectedDetailValues: any): any {
    let url = this.setURL(selectedVisualizationValues);
    url += 'brands/' + selectedDetailValues.brand.id + '/Products?SubCategoryId=' +
      selectedDetailValues.subCat.id;

    return this.networkSer.httpReq(
      'get', url);
  }
  /* istanbul ignore next */
  getBOMComponentList(selectedVisualizationValues: any, selectedDetailValues: any): any {
    let url = this.setURL(selectedVisualizationValues);
    url += 'Products/' + selectedDetailValues.product.id + '/bomComponents';

    return this.networkSer.httpReq(
      'get', url);
  }
  /* istanbul ignore next */
  getShiftList(lineVisualizationFilter: any, lineDetailFilter: any, dateFilter: any): any {
    if (lineVisualizationFilter.factory.id.$oid) {
      lineVisualizationFilter.factory.id = lineVisualizationFilter.factory.id.$oid;
    } else {
      lineVisualizationFilter.factory.id = lineVisualizationFilter.factory.id;
    }
    const serviceUrl = 'regions/' + lineVisualizationFilter.cluster.id +
      '/factories/' + lineVisualizationFilter.factory.id +
      '/lines/' + lineVisualizationFilter.line.id +
      '/shift/' + dateFilter;

    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */

  // POC LR
  /* istanbul ignore next */
  getShiftListforLR(dateFilter: any): any {
    const serviceUrl = 'regions/' + '5c3e12d47d85942a04b0365a' +
      '/factories/' + '5f6772a004c0b70ee82feb34' +
      '/lines/' + '5f67731c42d1b7121adddba1' +
      '/shift/' + dateFilter;

    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */

  // end
  getShiftListForManualEntry(lineVisualizationFilter: any, dateFilter: any): any {
    const serviceUrl = 'regions/' + lineVisualizationFilter.cluster.id +
      '/factories/' + lineVisualizationFilter.factory.id +
      '/lines/' + lineVisualizationFilter.line.id +
      '/shiftWithSkus/' + dateFilter;
    // 'GetSkuForManualWasteEntry?date=' + dateFilter + '&lineKey=' + lineVisualizationFilter.line.id;

    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getConfiguratorMachineList(selectedVisualizationValues: any, selectedDetailValues: any): any {
    return this.networkSer.httpReq(
      'get', 'machines/Configurator', {
      ClusterId: selectedVisualizationValues.cluster.id,
      FactoryId: selectedVisualizationValues.factory.id,
      LineId: selectedVisualizationValues.line.id,
      ProductId: selectedDetailValues.product.id
    });
  }
  // updateFlag: any
  /* istanbul ignore next */
  saveMachineBomList(
    machineBom: any,
    selectedVisualizationValues: any,
    selectedDetailValues: any,
    selectedMachine: any,
    updateFlag: any): any {
    return this.networkSer.httpReq(
      'post', 'mapBom', {
      ClusterId: selectedVisualizationValues.cluster.id,
      ClusterName: selectedVisualizationValues.cluster.name,
      FactoryId: selectedVisualizationValues.factory.id,
      FactoryName: selectedVisualizationValues.factory.name,
      LineId: selectedVisualizationValues.line.id,
      LineName: selectedVisualizationValues.line.name,
      MachineId: selectedMachine.machineId,
      MachineName: selectedMachine.machineName,
      ProductId: selectedDetailValues.product.id,
      ProductName: selectedDetailValues.product.value,
      OrderInLine: selectedMachine.orderInLine,
      Bom: machineBom
    });
  }
  /* istanbul ignore next */
  currencyConversion(dataCurrencyCode: any): any {
    let currCode: any;
    if (dataCurrencyCode.code === 'eu') {
      currCode = 'EUR';
    } else if (dataCurrencyCode.code === 'ru') {
      currCode = 'RUB';
    } else if (dataCurrencyCode.code === 'eus') {
      currCode = 'EURS';
    } else if (dataCurrencyCode.code === 'cop') {
      currCode = 'COP';
    } else if (dataCurrencyCode.code === 'ars') {
      currCode = 'ARS';
    } else if (dataCurrencyCode.code === 'svc') {
      currCode = 'SVC';
    } else if (dataCurrencyCode.code === 'ves') {
      currCode = 'VES';
    } else if (dataCurrencyCode.code === 'usd') {
      currCode = 'USD';
    } else if (dataCurrencyCode.code === 'crc') {
      currCode = 'CRC';
    } else if (dataCurrencyCode.code === 'hnl') {
      currCode = 'HNL';
    } else if (dataCurrencyCode.code === 'bob') {
      currCode = 'BOB';
    } else if (dataCurrencyCode.code === 'clp') {
      currCode = 'CLP';
    } else if (dataCurrencyCode.code === 'mxn') {
      currCode = 'MXN';
    } else if (dataCurrencyCode.code === 'brl') {
      currCode = 'BRL';
    } else if (dataCurrencyCode.code === 'zar') {
      currCode = 'ZAR';
    } else if (dataCurrencyCode.code === 'etb') {
      currCode = 'ETB';
    } else if (dataCurrencyCode.code === 'kes') {
      currCode = 'KES';
    } else if (dataCurrencyCode.code === 'ngn') {
      currCode = 'NGN';
    } else if (dataCurrencyCode.code === 'xof') {
      currCode = 'XOF';
    } else if (dataCurrencyCode.code === 'ghs') {
      currCode = 'GHS';
    } else if (dataCurrencyCode.code === 'aud') {
      currCode = 'AUD';
    } else if (dataCurrencyCode.code === 'idr') {
      currCode = 'IDR';
    } else if (dataCurrencyCode.code === 'thb') {
      currCode = 'THB';
    } else if (dataCurrencyCode.code === 'vnd') {
      currCode = 'VND';
    } else if (dataCurrencyCode.code === 'php') {
      currCode = 'PHP';
    } else if (dataCurrencyCode.code === 'inr') {
      currCode = 'INR';
    } else if (dataCurrencyCode.code === 'pkr') {
      currCode = 'PKR';
    } else if (dataCurrencyCode.code === 'bdt') {
      currCode = 'BDT';
    } else if (dataCurrencyCode.code === 'mmk') {
      currCode = 'MMK';
    } else if (dataCurrencyCode.code === 'npr') {
      currCode = 'NPR';
    } else if (dataCurrencyCode.code === 'lkr') {
      currCode = 'LKR';
    } else if (dataCurrencyCode.code === 'mdl') {
      currCode = 'MDL';
    } else if (dataCurrencyCode.code === 'dkk') {
      currCode = 'DKK';
    } else if (dataCurrencyCode.code === 'myr') {
      currCode = 'MYR';
    } else if (dataCurrencyCode.code === 'nzd') {
      currCode = 'NZD';
    } else if (dataCurrencyCode.code === 'huf') {
      currCode = 'HUF';
    } else if (dataCurrencyCode.code === 'hrk') {
      currCode = 'HRK';
    } else if (dataCurrencyCode.code === 'gbp') {
      currCode = 'GBP';
    } else if (dataCurrencyCode.code === 'chf') {
      currCode = 'CHF';
    } else if (dataCurrencyCode.code === 'rub') {
      currCode = 'RUB';
    } else if (dataCurrencyCode.code === 'czk') {
      currCode = 'CZK';
    } else if (dataCurrencyCode.code === 'cad') {
      currCode = 'CAD';
    } else if (dataCurrencyCode.code === 'cny') {
      currCode = 'CNY';
    } else if (dataCurrencyCode.code === 'twd') {
      currCode = 'TWD';
    } else if (dataCurrencyCode.code === 'rsd') {
      currCode = 'RSD';
    } else if (dataCurrencyCode.code === 'pen') {
      currCode = 'PEN';
    } else if (dataCurrencyCode.code === 'nok') {
      currCode = 'NOK';
    } else if (dataCurrencyCode.code === 'veb') {
      currCode = 'VEB';
    } else if (dataCurrencyCode.code === 'krw') {
      currCode = 'KRW';
    } else if (dataCurrencyCode.code === 'jpy') {
      currCode = 'JPY';
    } else if (dataCurrencyCode.code === 'ron') {
      currCode = 'RON';
    } else if (dataCurrencyCode.code === 'kzt') {
      currCode = 'KZT';
    } else if (dataCurrencyCode.code === 'eek') {
      currCode = 'EEK';
    } else if (dataCurrencyCode.code === 'hkd') {
      currCode = 'HKD';
    } else if (dataCurrencyCode.code === 'lvl') {
      currCode = 'LVL';
    } else if (dataCurrencyCode.code === 'ltl') {
      currCode = 'LTL';
    } else if (dataCurrencyCode.code === 'bgn') {
      currCode = 'BGN';
    } else if (dataCurrencyCode.code === 'pln') {
      currCode = 'PLN';
    } else if (dataCurrencyCode.code === 'trl') {
      currCode = 'TRL';
    } else if (dataCurrencyCode.code === 'skk') {
      currCode = 'SKK';
    } else if (dataCurrencyCode.code === 'sek') {
      currCode = 'SEK';
    } else if (dataCurrencyCode.code === 'uah') {
      currCode = 'UAH';
    } else if (dataCurrencyCode.code === 'sgd') {
      currCode = 'SGD';
    } else if (dataCurrencyCode.code === 'djf') {
      currCode = 'DJF';
    } else if (dataCurrencyCode.code === 'egp') {
      currCode = 'EGP';
    } else if (dataCurrencyCode.code === 'lak') {
      currCode = 'LAK';
    } else if (dataCurrencyCode.code === 'zmw') {
      currCode = 'ZMW';
    } else if (dataCurrencyCode.code === 'sdg') {
      currCode = 'SDG';
    } else if (dataCurrencyCode.code === 'irr') {
      currCode = 'IRR';
    } else if (dataCurrencyCode.code === 'try') {
      currCode = 'TRY';
    } else if (dataCurrencyCode.code === 'tnd') {
      currCode = 'TND';
    } else if (dataCurrencyCode.code === 'aed') {
      currCode = 'AED';
    } else if (dataCurrencyCode.code === 'zwd') {
      currCode = 'ZWD';
    } else if (dataCurrencyCode.code === 'sar') {
      currCode = 'SAR';
    } else if (dataCurrencyCode.code === 'dzd') {
      currCode = 'DZD';
    } else if (dataCurrencyCode.code === 'tzs') {
      currCode = 'TZS';
    } else if (dataCurrencyCode.code === 'bhd') {
      currCode = 'BHD';
    } else if (dataCurrencyCode.code === 'ils') {
      currCode = 'ILS';
    } else if (dataCurrencyCode.code === 'omr') {
      currCode = 'OMR';
    } else if (dataCurrencyCode.code === 'zmk') {
      currCode = 'ZMK';
    } else if (dataCurrencyCode.code === 'mzn') {
      currCode = 'MZN';
    } else if (dataCurrencyCode.code === 'rwf') {
      currCode = 'RWF';
    } else if (dataCurrencyCode.code === 'mad') {
      currCode = 'MAD';
    } else if (dataCurrencyCode.code === 'azm') {
      currCode = 'AZM';
    } else if (dataCurrencyCode.code === 'ugx') {
      currCode = 'UGX';
    } else if (dataCurrencyCode.code === 'mwk') {
      currCode = 'MWK';
    } else if (dataCurrencyCode.code === 'lbp') {
      currCode = 'LBP';
    } else if (dataCurrencyCode.code === 'jod') {
      currCode = 'JOD';
    } else if (dataCurrencyCode.code === 'pyg') {
      currCode = 'PYG';
    } else if (dataCurrencyCode.code === 'pab') {
      currCode = 'PAB';
    } else if (dataCurrencyCode.code === 'dop') {
      currCode = 'DOP';
    } else if (dataCurrencyCode.code === 'nio') {
      currCode = 'NIO';
    } else if (dataCurrencyCode.code === 'htg') {
      currCode = 'HTG';
    } else if (dataCurrencyCode.code === 'ttd') {
      currCode = 'TTD';
    } else if (dataCurrencyCode.code === 'uyu') {
      currCode = 'UYU';
    } else if (dataCurrencyCode.code === 'gtq') {
      currCode = 'GTQ';
    }

    return this.networkSer.httpReq('get', 'currencyRateFromEuro/' + currCode);
  }
  /* istanbul ignore next */
  setTimeEpoc() {
    const thisYear = new Date().getFullYear();
    const req: any = {};
    let currrentFilter: any;
    let currentDate: any;
    let dateFilterdata: any;

    if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter'))) {
      currrentFilter = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).appliedFilterType;
      currentDate = new Date(JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).dateObj);
      dateFilterdata = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter'));

      const extractedData = {
        filterYear: currentDate.getFullYear(),
        filterMonth: currentDate.getMonth(),
        filterDay: currentDate.getDate(),
        filterHr: (dateFilterdata.selectedHr && dateFilterdata.selectedHr.hourVal) ?
          dateFilterdata.selectedHr.hourVal : null,
        shiftFromHr: (dateFilterdata.shift && dateFilterdata.shift.shiftTIme && dateFilterdata.shift.shiftTIme.shiftStart) ?
          dateFilterdata.shift.shiftTIme.shiftStart : null,
        shiftToHr: (dateFilterdata.shift && dateFilterdata.shift.shiftTIme && dateFilterdata.shift.shiftTIme.shiftEnd) ?
          dateFilterdata.shift.shiftTIme.shiftEnd : null,
        shiftToDay: currentDate.getDate()
      };

      req.currrentFilter = currrentFilter;

      switch (currrentFilter) {
        case 'month':
          req.FromTimeEpoc = Math.floor(+(currentDate) / 1000);
          req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear, extractedData.filterMonth + 1)) / 1000);
          break;
        case 'week':
          req.FromTimeEpoc = Math.floor(+(currentDate) / 1000);
          req.ToTimeEpoc = Math.floor(+(new Date(dateFilterdata.endWeekFromSelectedDate)) / 1000);
          break;
        case 'date':
          req.FromTimeEpoc = Math.floor(+(currentDate) / 1000);
          req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
            extractedData.filterMonth, extractedData.filterDay + 1)) / 1000);
          break;
        case 'shift':
          if (extractedData.shiftFromHr && extractedData.shiftToHr) {
            if (extractedData.shiftFromHr.substring(0, 2) > extractedData.shiftToHr.substring(0, 2)) {
              extractedData.shiftToDay = extractedData.shiftToDay + 1;
            }

            req.FromTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
              extractedData.filterMonth, extractedData.filterDay, extractedData.shiftFromHr.substring(0, 2))) / 1000);
            req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
              extractedData.filterMonth, extractedData.shiftToDay, extractedData.shiftToHr.substring(0, 2))) / 1000);
          }
          break;
        case 'hour':
          req.FromTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
            extractedData.filterMonth, extractedData.filterDay, extractedData.filterHr)) / 1000);
          req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
            extractedData.filterMonth, extractedData.filterDay, extractedData.filterHr + 1)) / 1000);
          break;
      }
    } else {
      req.ToTimeEpoc = Math.floor(+(new Date()) / 1000);
      req.FromTimeEpoc = Math.floor(+(new Date(thisYear, 0)) / 1000);
    }

    return req;
  }
  /* istanbul ignore next */
  getPOTrends(factoryID: any, lineID?: any, unitSelected?: any): any {
    let costQty = 'cost';

    if (unitSelected && unitSelected === 'cost') {
      costQty = 'cost';
    } else if (unitSelected && unitSelected === 'quantity') {
      costQty = 'Qty';
    }

    const serviceUrl = '/factory/poTrends?FactoryId=' + factoryID + '&&LineId=' + lineID + '&&costOrQty=' + costQty;

    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getMultipleSKUList(searchValue: string) {

    const serviceUrl = 'skuDetails/' + searchValue;

    return this.networkSer.httpReq('get', serviceUrl);

  }
  /* istanbul ignore next */
  getSKEWTrends(searchValue?: any, unitSelected?: any, factoryID?: string): any {
    let costQty = 'cost';

    if (unitSelected && unitSelected === 'cost') {
      costQty = 'cost';
    } else if (unitSelected && unitSelected === 'quantity') {
      costQty = 'Qty';
    }

    let serviceUrl = 'factory/skuTrends/' + searchValue + '?costOrQty=' + costQty;

    if (factoryID) {
      serviceUrl = serviceUrl + '&FactoryId=' + factoryID;
    }

    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getTopFactoryList(selectedCluster: any): any {
    return this.networkSer.httpReq(
      'get',
      'clusters/' + selectedCluster.id);
  }
  /* istanbul ignore next */
  getTopPOSKU(
    topSearchType: any, clusterID: any, factoryID?: any, FromEpoc?: any, ToEpoc?: any,
    unitSelected?: any, sfgData?: any): any {
    let urlSearchType = '';
    let costQty = 'cost';
    let tempSfgData = true;

    if (topSearchType === 'sku') {
      urlSearchType = 'WorstSKUs';
    } else if (topSearchType === 'po') {
      urlSearchType = 'WorstPOs';
    } else if (topSearchType === 'category') {
      urlSearchType = 'WorstCategories';
    }

    if (unitSelected && unitSelected === 'cost') {
      costQty = 'cost';
    } else if (unitSelected && unitSelected === 'quantity') {
      costQty = 'Qty';
    }

    if (sfgData !== undefined && sfgData !== null) {
      tempSfgData = sfgData;
    } else {
      tempSfgData = true;
      this.dataService.setStorageData('sfgSelected', JSON.stringify({ sfgData: tempSfgData }));
    }

    const serviceUrl = 'clusters/' + encodeURIComponent(clusterID) + '/factories/' + encodeURIComponent(encodeURIComponent(factoryID)) + '/' + urlSearchType +
      '?FromTimeEpoc=' + FromEpoc + '&&ToTimeEpoc=' + ToEpoc + '&&costOrQty=' + costQty + '&&IncludeSFG=' + tempSfgData;
    return this.networkSer.httpReq('get', serviceUrl);
  }

  /* istanbul ignore next */
  downloadCSVReport(reportValue: any, userInfo: any, userEmail: any) {
    let data: any;

    data = {
      FromTimeEpoc: Math.floor(+(reportValue.fromPeriod) / 1000),
      ToTimeEpoc: Math.floor(+(reportValue.toPeriod) / 1000),
      ReportGeneratedBy: userInfo,
      Email: userEmail
    };

    if (reportValue.lineid !== 'All') {
      data.LineId = reportValue.lineid;
    }
    // const serviceUrl = 'clusters/' + reportValue.clusterid + '/factories/' + reportValue.factoryid + '/lines/'
    //   + reportValue.lineid + '/generateReport';

    const serviceUrl = 'clusters/' + encodeURIComponent(reportValue.clusterid) + '/factories/' + encodeURIComponent(encodeURIComponent(reportValue.factoryid)) + '/generateReport';

    return this.networkSer.httpReq('post', serviceUrl, data);
  }
  /* istanbul ignore next */
  getReports() {
    return this.networkSer.httpReq('get', 'getAllReports');
  }
  /* istanbul ignore next */
  deleteForm(reportId) {
    return this.networkSer.httpReq('delete', 'deleteReport/' + reportId);
  }
  /* istanbul ignore next */
  feedbackForm(rowdtls: any, formdtls?: any, file?: any) {
    let serviceUrl = '';

    if (rowdtls.isFeedback === false) {
      const payload: any = new FormData();
      payload.append('ReportId', rowdtls.rowId);
      payload.append('FeedbackUploadBy', rowdtls.userInfo);
      const FeedbackFormQuestions = {
        Q1DedicatedStandardWasteReport: this.dataService.responseFormbuttonCheck(formdtls.qst1),
        Q2POInStandardWasteReport: formdtls.qst2,
        Q3POInNgtw: formdtls.qst3,
        Q4CountCostMatch: this.dataService.responseFormbuttonCheck(formdtls.qst4),
        Q5FindMissingPO: this.dataService.responseFormbuttonCheck(formdtls.qst5),
        Q6FindPOWithZeroWaste: this.dataService.responseFormbuttonCheck(formdtls.qst6),
        Q7FindPODuplication: this.dataService.responseFormbuttonCheck(formdtls.qst7),
        Q9FindPOWithNegativeValue: this.dataService.responseFormbuttonCheck(formdtls.qst8),
        Q8MatchPOWithDoesntMatchWaste: this.dataService.responseFormbuttonCheck(formdtls.qst9),
        Q10ConfiguredMachines: this.dataService.responseFormbuttonCheck(formdtls.qst10),
        Q11ConfiguredNameSiteLine: this.dataService.responseFormbuttonCheck(formdtls.qst11),
        Q12NGTWRating: formdtls.qst12,
        Q13IsActualConsumption: this.dataService.responseFormbuttonCheck(formdtls.qst13),
        Suggestions: formdtls.qst14,
        Q5NumberOfMissingPO: formdtls.qst5_sub1,
        Q6NumberOfPOWithZeroWaste: formdtls.qst6_sub1,
        Q7NumberOfPODuplication: formdtls.qst7_sub1,
        Q9NumberOfPOWithNegativeValue: formdtls.qst8_sub1,
        Q8NumberOfMatchPOWithDoesntMatchWaste: formdtls.qst9_sub1
      };
      payload.append('FeedbackFormQuestions', JSON.stringify(FeedbackFormQuestions));
      if (file) {
        payload.append('IsFactoryReportFileUpload', true);
        payload.append('FactoryReportFile', file, file.name);
      } else {
        payload.append('IsFactoryReportFileUpload', false);
      }

      if (rowdtls.lineId === '') {
        serviceUrl = 'clusters/' + rowdtls.clusterId + '/factories/' + rowdtls.factoryId + '/lines/'
          + null + '/uploadFeedbackform';
      } else {
        serviceUrl = 'clusters/' + rowdtls.clusterId + '/factories/' + rowdtls.factoryId + '/lines/'
          + rowdtls.lineId + '/uploadFeedbackform';
      }
      return this.networkSer.httpReq('post', serviceUrl, payload, true);
    } else {
      serviceUrl = 'viewReportWithFeedback/' + rowdtls.rowId;
      return this.networkSer.httpReq('get', serviceUrl);
    }
  }
  /* istanbul ignore next */
  getRealTimeData(cluster, factory, line, fromTime, toTime): any {
    const serviceUrl = 'regions/' + cluster + '/factories/' + factory +
      '/lines/' + line + '/waste?startTime=' + fromTime + '&EndTime=' + toTime;

    return this.networkSer.httpReq(
      'get-dfos', serviceUrl);
  }
  /* istanbul ignore next */
  getRealTimeSKU(cluster, factory, line): any {
    const serviceUrl = 'regions/' + cluster + '/factories/' + factory + '/lines/' + line + '/productionRun';
    return this.networkSer.httpReq('get-dfos', serviceUrl);
  }
  /* istanbul ignore next */
  getTargetWaste(lineId): any {
    const serviceUrl = 'collectionName/' + 'lines/' + lineId;
    return this.networkSer.httpReq('get-dfos', serviceUrl);
  }
  /* istanbul ignore next */
  getHourlyLiveData(cflDetails: any, fromTime: any, toTime: any, shiftStartTime: any, shiftEndTime: any): any {
    const cluster = cflDetails.cluster.name;
    const factory = cflDetails.factory.name;
    const line = cflDetails.line.name;
    // const lineID = cflDetails.line.id;

    // const serviceUrl = 'regions/' + cluster + '/factories/' + factory +
    //   '/lines/' + line + '/waste?startTime=' + fromTime + '&EndTime=' + toTime
    //   + '&getWasteTarget=true' + '&lineId=' + lineID;

    const serviceUrl = 'regions/' + cluster + '/factories/' + factory +
      '/lines/' + line + '/realTimeData?StartTime=' + fromTime + '&EndTime=' + toTime +
      '&ShiftStartTime=' + shiftStartTime + '&ShiftEndTime=' + shiftEndTime;
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */

  // LR POC
  /* istanbul ignore next */
  getHourlyLiveDataforLR(fromTime: any, toTime: any, shiftStartTime: any, shiftEndTime: any): any {
    const cluster = 'Europe';
    const factory = 'Bydgoszcz';
    const line = 'L5';
    // const lineID = cflDetails.line.id;

    // const serviceUrl = 'regions/' + cluster + '/factories/' + factory +
    //   '/lines/' + line + '/waste?startTime=' + fromTime + '&EndTime=' + toTime
    //   + '&getWasteTarget=true' + '&lineId=' + lineID;

    const serviceUrl = 'regions/' + cluster + '/factories/' + factory +
      '/lines/' + line + '/realTimeData?StartTime=' + fromTime + '&EndTime=' + toTime +
      '&ShiftStartTime=' + shiftStartTime + '&ShiftEndTime=' + shiftEndTime;
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */

  getDfosTimezoneforLR(): any {

    const serviceUrl = 'regions/' + 'Europe' + '/factories/' + '5f6772a004c0b70ee82feb34';
    return this.networkSer.httpReq('get-dfos', serviceUrl);
  }

  // LR POC end
  getDfosTimezone(dtls): any {
    if (dtls.factory.id.$oid) {
      dtls.factory.id = dtls.factory.id.$oid;
    } else {
      dtls.factory.id = dtls.factory.id;
    }
    const serviceUrl = 'regions/' + dtls.cluster.name + '/factories/' + dtls.factory.id;
    return this.networkSer.httpReq('get-dfos', serviceUrl);
  }
  /* istanbul ignore next */
  getDFOSClusterList(): any {
    return this.networkSer.httpReq(
      'get',
      'master').pipe(map(
        data => this.dataService.processClusterResp(data)
      ));
  }
  /* istanbul ignore next */
  getDFOSFactoryList(selectedVisualizationValues: any): any {
    if (selectedVisualizationValues.id) {
      return this.networkSer.httpReq(
        'get-dfos',
        'collectionName/clusters').pipe(map(
          data => this.dataService.processFactoryResp(data, selectedVisualizationValues.id)
        ));
    } else if (selectedVisualizationValues.cluster.id) {
      return this.networkSer.httpReq(
        'get-dfos',
        'collectionName/clusters').pipe(map(
          data => this.dataService.processFactoryResp(data, selectedVisualizationValues.cluster.id)
        ));
    }
  }
  /* istanbul ignore next */
  postFileLineLayout(clusterid: string, factoryid: string, lineid: string, fileData: any) {
    return this.networkSer.httpReq('post', 'uploadLineLayout?clusterId=' +
      clusterid + '&factoryid=' + factoryid + '&lineid=' + encodeURIComponent(lineid), fileData, true);
  }
  /* istanbul ignore next */
  getLineLayoutList() {
    const serviceUrl = 'getAllLineLayoutData';
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  deleteLineLayout(serviceURL) {
    return this.networkSer.httpReq('delete', serviceURL);
  }
  /* istanbul ignore next */
  getDFOSLineList(selectedVisualizationValues: any): any {
    return this.networkSer.httpReq('get-dfos', `regions/${selectedVisualizationValues.cluster.id}
    /factories/${selectedVisualizationValues.factory.id}`).pipe(map(
      data =>
        this.dataService.processLineResp(data)
    ));
  }
  /* istanbul ignore next */
  getTimeLossData(startTime: any, endTime: any): any {
    const localStorageLossData = JSON.parse(sessionStorage.getItem('caivanoDashboard')) || {};

    // startTime = '2021-02-26T00:00:00Z';
    // endTime = '2021-02-26T23:59:00Z';

    if (localStorageLossData && localStorageLossData.cluster && localStorageLossData.factory && localStorageLossData.line) {
      const serviceLossDataUrl =
        'regions/' + localStorageLossData.cluster.name +
        '/factories/' + localStorageLossData.factory.name +
        '/lines/' + localStorageLossData.line.name +
        '/loss?start=' + startTime +
        '&end=' + endTime +
        '&unClassified=' + false;

      return this.networkSer.httpReq('get-dfos', serviceLossDataUrl);
    }
  }
  /* istanbul ignore next */
  getTimeLossSummaryData(startTime: any, endTime: any, localStorageName: any): any {
    const localStorageLossSummaryData = JSON.parse(sessionStorage.getItem(localStorageName)) || {};

    // startTime = '2021-02-26T00:00:00Z';
    // endTime = '2021-02-26T23:59:00Z';

    if (localStorageLossSummaryData &&
      localStorageLossSummaryData.cluster &&
      localStorageLossSummaryData.factory &&
      localStorageLossSummaryData.line) {
      const serviceLossSummaryDataUrl =
        'regions/' + localStorageLossSummaryData.cluster.name +
        '/factories/' + localStorageLossSummaryData.factory.name +
        '/lines/' + localStorageLossSummaryData.line.name +
        '/lossSummary?start=' + startTime +
        '&end=' + endTime;

      return this.networkSer.httpReq('get-dfos', serviceLossSummaryDataUrl);
    }
  }
  /* istanbul ignore next */
  getDataFromMDCS(clusterId, factory): any {
    const localStorageData = JSON.parse(sessionStorage.getItem('spareCapacity')) || {};

    if (localStorageData && clusterId && factory
      && localStorageData.year && localStorageData.month) {
      const serviceUrl =
        'cluster/' + clusterId +
        '/factory/' + factory.factoryId +
        '/OEE/loss?year=' + localStorageData.year +
        '&period=' + 'month' +
        '&month=' + localStorageData.month;

      return this.networkSer.httpReq('get-mdcs', serviceUrl);
    }
  }
  /* istanbul ignore next */
  getRealTimeLineList(selectedVisualizationValues: any): any {
    const tempStartTime = new Date().toISOString().split('.');
    const startTime = tempStartTime[0] + 'Z';
    const url = 'regions/' + selectedVisualizationValues.cluster.name +
      '/factories/' + selectedVisualizationValues.factory.name +
      '/lines/realtime?startTime=' + startTime;

    return this.networkSer.httpReq('get', url);
  }
  /* istanbul ignore next */
  getRegistration(): any {
    const serviceUrl = 'ValidateUser?Email=' + this.auth.getAllAccountInfo;
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getBigCatList(): any {
    return this.networkSer.httpReq('get', 'GetBigCategories');
  }
  /* istanbul ignore next */
  getSubFamilyList(bigCategory?: any): any {
    let serviceUrl = '';

    if (!bigCategory || bigCategory === null) {
      serviceUrl = 'SubFamilies';
    } else {
      serviceUrl = 'SubFamilies?BigCategory=' + bigCategory;
    }

    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getEquipmentList(subFamily: any): any {
    return this.networkSer.httpReq('get', 'GetEquipments?SubFamily=' + subFamily);
  }
  /* istanbul ignore next */
  getProjectTypeList(): any {
    return this.networkSer.httpReq('get', 'GetProjectTypes');
  }
  /* istanbul ignore next */
  getLossTypeList(ProjectType: any): any {
    return this.networkSer.httpReq('get', 'GetLossTypes?ProjectType=' + ProjectType);
  }
  /* istanbul ignore next */
  getProjectNameList(): any {
    return this.networkSer.httpReq('get', 'GetProjectList');
  }
  /* istanbul ignore next */
  getSkillGroupList(): any {
    return this.networkSer.httpReq('get', 'GetGroupOfSkills');
  }
  /* istanbul ignore next */
  getSkillList(): any {
    return this.networkSer.httpReq('get', 'GetSkills');
  }
  /* istanbul ignore next */
  getSkillLossTypeList(): any {
    return this.networkSer.httpReq('get', 'Get_LossTypeSkill_Mappings');
  }
  /* istanbul ignore next */
  // setProjectDetails(registrationValues, editValue?: KnowledgeMatrixModel): any {
  //   if (editValue && editValue.Id && editValue.Id !== '') {

  //     return this.networkSer.httpReq('put', 'ProjectDetails?Id=' + editValue.Id, registrationValues);
  //   } else {

  //     return this.networkSer.httpReq('post', 'ProjectDetails', registrationValues);
  //   }
  // }
  /* istanbul ignore next */
  deleteProjectDetails(id: string): any {
    return this.networkSer.httpReq('delete', 'ProjectDetails?Id=' + id);
  }
  /* istanbul ignore next */
  getProjectDetails(pageNumber: any, limit: any): any {
    const tempSkip = (pageNumber * limit) + 1;
    const tempLimit = (pageNumber + 1) * limit;

    const serviceUrl = 'ProjectDetails?skip=' + tempSkip + '&limit=' + tempLimit;
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getSearchResults(searchValue: any): any {
    const serviceUrl = 'SearchProjects?keywords=' + searchValue;
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getEmailList(searchValue): any {
    const serviceUrl = 'GetOrgLevelEmails?filtertext=' + searchValue + '&top=999';
    // &skiptoken
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  getNotificationList(): any {
    return this.networkSer.httpReq('get', 'ApplicationIssues', {}, false, false);
  }
  /* istanbul ignore next */
  getDownloadData(): any {
    return this.networkSer.httpReq('get', 'Download');
  }
  /* istanbul ignore next */
  getAssessmentList(queryParam?: string): any {
    const serviceUrl = 'SelfAssessmentDetails?Email=' + this.auth.getAllAccountInfo + queryParam;
    // const serviceUrl = 'SelfAssessmentDetails?Email=' + 'test';
    return this.networkSer.httpReq('get', serviceUrl);
  }
  /* istanbul ignore next */
  // postAssessment(requset: SelfAssessmentReq): any {
  //   requset.Email = this.auth.getAllAccountInfo.toString();
  //   requset.UserName = this.auth.getAllAccountInfo.toString();
  //   requset.CreatedBy = this.auth.getAllAccountInfo.toString();

  //   return this.networkSer.httpReq('post', 'SelfAssessmentDetails', requset);
  // }
  /* istanbul ignore next */
  getUnitPrice(): any {
    // return this.networkSer.httpReq('get', 'GetSkills');
    return this.networkSer.httpReq('get', 'GetSkuUnitCost');
  }
  /* istanbul ignore next */
  setManualEntry(manualData: any): any {
    return this.networkSer.httpReq('post', 'ManualWasteEntry', manualData);
  }
  /* istanbul ignore next */
  getManualEntry(FromDate: any, ShiftStartTime: any): any {
    const localStorageLossData = JSON.parse(sessionStorage.getItem('caivanoDashboard')) || {};

    // startTime = '2021-02-26T00:00:00Z';
    // endTime = '2021-02-26T23:59:00Z';
    if (localStorageLossData.factory.id.$oid) {
      localStorageLossData.factory.id = localStorageLossData.factory.id.$oid;
    } else {
      localStorageLossData.factory.id = localStorageLossData.factory.id;
    }

    if (localStorageLossData && localStorageLossData.cluster && localStorageLossData.factory && localStorageLossData.line) {
      const serviceLossDataUrl =
        'cluster/' + localStorageLossData.cluster.id +
        '/factory/' + localStorageLossData.factory.id +
        '/line/' + localStorageLossData.line.id +
        '/machineWasteDetails?FromDate=' + new Date(FromDate).toISOString().split('.')[0] + 'Z' +
        '&ShiftStartTime=' + ShiftStartTime;

      return this.networkSer.httpReq('get', serviceLossDataUrl);
    }
  }


  /* istanbul ignore next */
  getManualTargetPercentage(skuId: any): any {
    const localStorageLossData = JSON.parse(sessionStorage.getItem('caivanoDashboard')) || {};

    if (localStorageLossData && localStorageLossData.line) {
      const serviceUrl =
        'line/' + localStorageLossData.line.id +
        '/sku/' + skuId +
        '/machineWastePercent';

      return this.networkSer.httpReq('get', serviceUrl);
    }
  }
  /* istanbul ignore next */
  getUsersForMention(queryParams) {
    return this.networkSer.httpReq('get', 'GetOrgLevelEmails', queryParams, false, false)
      .pipe(map(res => res.response));
  }
  /* istanbul ignore next */
  getReplies(queryParams) {
    return this.networkSer.httpReq('get', 'getreplieswithreactions', queryParams, false, false)
      .pipe(map(this.transformResult));
  }

  /* post reply */
  /* istanbul ignore next */
  postReply(body) {
    return this.networkSer.httpReq('post', 'insertreply', body, false, false)
      .pipe(map(this.transformResult));
  }
  /* istanbul ignore next */
  postReaction(body) {
    return this.networkSer.httpReq('post', 'insertreaction', body, false, false)
      .pipe(map(this.transformResult));
  }
  /* istanbul ignore next */
  deleteReaction(id: number, email: string) {
    return this.networkSer.httpReq('delete', `deletemessagereaction?messageId=${id}&createdBy=${email}`, null, false, false)
      .pipe(map(this.transformResult));
  }
  /* istanbul ignore next */
  getComments(queryParams) {
    return this.networkSer.httpReq('get', 'getcommentswithreactions', queryParams, false, false)
      .pipe(map(this.transformResult));
  }

  /* post comment */
  /* istanbul ignore next */
  postComment(body) {
    return this.networkSer.httpReq('post', 'insertcomment', body, false, false)
      .pipe(map(this.transformResult));
  }

  // usage report - 59384
  /* istanbul ignore next */
  insertUsage(request) {
    return this.networkSer.httpReq('post', 'insertusage', request, false, false)
      .pipe(map(this.transformResult));
  }
  /* istanbul ignore next */
  getRealTimeClusterList() {
    return this.networkSer.httpReq('get', 'master/dfosline').pipe(map(
      data => this.dataService.processClusterResp1(data)
    ));
  }
  /* istanbul ignore next */
  transformResult(res) {
    if (res && (res.message === 'Ok' || res.message === 'ok')) {
      return res.result;
    }
  }
}
