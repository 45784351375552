// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, Input, OnDestroy } from '@angular/core';
import 'quill-mention';
// import 'quill-emoji';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { SharedService } from '../../service/shared.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-da-comments-shared',
  templateUrl: './da-comments.component.html',
  styleUrls: ['./da-comments.component.scss']
})
export class DaCommentsComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('scrollDiv', { static: false }) private myScrollContainer: ElementRef;
  @Output() closeComment = new EventEmitter<boolean>();
  @Input() factorySummary;

  destroy$: Subject<boolean> = new Subject<boolean>();
  isLoading = false;
  message: string;
  comments: Array<Comment> = [];
  shortName: any;
  user: any;
  userDtls: any;
  btnEnabled = false;
  isSending = false;
  pageStart: number;
  pageLimit = 10;
  isViewLoadMoreClicked = false;
  dashboardName: string;

  htmlText = null;
  hasFocus = false;

  users: Array<any> = [];

  quillConfig2: any = {};

  lineId: string;
  skuId: string;
  commentBox: string;
  userMentionedList: Array<string> = [];
  userMentionTimer: any;
  userMentionSubscription: Subscription;

  constructor(
    private service: SharedService,
    private route: ActivatedRoute,
    public auth: AuthService,
    private authService: MsalService,
  ) { }

  ngOnInit() {
    this.quillConfig2 = { //
      toolbar: { //
        container: [], //
      }, //
      mention: { //
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'], //

        source: async (searchTerm, renderList, mentionChar) => { //
          /* istanbul ignore next */
          if (this.userMentionSubscription) { //
            this.userMentionSubscription.unsubscribe(); //
          } //
          /* istanbul ignore next */
          if (this.userMentionTimer) { //
            clearInterval(this.userMentionTimer); //
          } //
          /* istanbul ignore next */
          if (mentionChar === '@') { //
            if (searchTerm.length > 1) { //
              this.userMentionTimer = setTimeout(() => { //
                this.suggestPeople(searchTerm).then(users => { //
                  renderList(users); //
                }); //
              }, 500); //
            } //
          } //
        }, //
      }, //
      keyboard: { //
        bindings: { //
          shiftEnter: { //
            key: 13, //
            shiftKey: true, //
            /* istanbul ignore next */
            handler: (range, context) => { //
              /* istanbul ignore next */
              return true;
            }
          },
          enter: {
            key: 13,
            /* istanbul ignore next */
            handler: (range, context) => {
              /* istanbul ignore next */
              this.postComment();
              return false;
            }
          }
        }
      }
    };

    this.dashboardName = this.getDashboardName();
    this.pageStart = 1;
    //  this.user = this.adal.getAllAccounts;
    if (this.auth.checkIsAuthticated) {

      // this.userDtls = this.authService.getAllAccounts().forEach(item => item.name);
      const accessTokenRequest = {
        // scopes: ['user.read'],
        scopes: [environment.msalAuthConfig.auth.clientId],
        authority: environment.msalAuthConfig.auth.authority
      };
      this.authService.acquireTokenSilent(accessTokenRequest).then(async (payload: any) => {
        //  this.loaderHandlerService.toggleLoader(0);
        if (payload && payload.account && payload.account.name) { // Assign username
          this.userDtls = payload.account.name;
          this.user = payload.account.userName;
          const fullname = this.userDtls;
          const split = fullname.split(" ");
          this.shortName = split[0][0] + split[1][0];
        }
        //   this.authService.setTokenExpiryTime(payload); // Set token expiry time
        return payload;
      });
    }

    this.route.queryParams.subscribe(params => {
      this.factorySummary.fromDate = params.FromDate;
      this.factorySummary.toDate = params.ToDate;
      this.comments = [];
      this.message = '';
      this.pageStart = 1;
      this.isViewLoadMoreClicked = false;
      this.lineId = '0';
      this.skuId = '0';
      this.commentBox = 'FACTORY_INSIGHT';
      this.comments = [];
      /* istanbul ignore next */
      if (params.level) {
        if (params.level === 'line') {
          this.lineId = params.LineId;
          this.commentBox = 'LINE_INSIGHT';
        }
        if (params.level === 'sku') {
          this.lineId = params.lineId;
          this.skuId = params.skuId;
          this.commentBox = 'SKU_INSIGHT';
        }
      }
      this.getComments();
    });
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  scrollToBottom(): void {

    setTimeout(() => {
      /* istanbul ignore next */
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    }, 1000);
  }

  suggestPeople(searchTerm: string) {
    return new Promise((resolve, reject) => {
      const param = {
        filtertext: searchTerm
      };
      this.userMentionSubscription = this.service.getUsersForMention(param)
        // .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            const users = res.value.map(user => {
              return { id: user.mail, value: user.displayName };
            });
            resolve(users);
          }
        });
    });
  }

  getComments() {
    if (!this.factorySummary) {
      /* istanbul ignore next */
      return;
    }
    if (this.auth.checkIsAuthticated) {
      this.userDtls = this.authService.getAccount().userName;
    }
    this.isLoading = true;
    this.message = '';
    const param = {
      commentBox: this.commentBox,
      entityId1: this.factorySummary.factoryId,
      entityId2: this.lineId,
      entityId3: this.skuId,
      FromDate: this.factorySummary.fromDate,
      ToDate: this.factorySummary.toDate,
      PageNumber: this.pageStart,
      PageSize: this.pageLimit,
      userEmailId: this.userDtls
    };
    this.service.getComments(param)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((res: Array<Comment>) => {
        if (res) {
          const commentsResponse = [...res].reverse();
          this.comments.unshift(...commentsResponse);
          this.isLoading = false;
          if (this.pageStart === 1) {
            this.scrollToBottom();
          }
        } else {
          this.pageStart--;
          this.message = (this.isViewLoadMoreClicked) ? 'No more comments available' : 'Comments not available.';
          this.isLoading = false;
        }
      });
  }

  loadMore() {
    this.pageStart = this.comments.length + 1;
    this.isViewLoadMoreClicked = true;
    this.getComments();
  }

  postComment() {
    if (this.htmlText) {
      this.isSending = true;
      const body: any = {
        commentBox: this.commentBox,
        entityId1: this.factorySummary.factoryId,
        entityId2: this.lineId,
        entityId3: this.skuId,
        fromDate: this.factorySummary.fromDate,
        toDate: this.factorySummary.toDate,
        message: this.htmlText,
        filePath: 'Empty',
        MentionedUserList: this.userMentionedList.join(),
        createdBy: this.userDtls,
        createdByEmailId: this.user,
        createdOn: new Date(),
        disLikeCount: 0,
        likeCount: 0,
        messageId: 0,
        parentMessageId: 0,
        replyCount: 0,
        URL: window.location.href,
        DashboardName: this.dashboardName
      };

      this.htmlText = '';
      this.service.postComment(body)
        // .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            body.messageId = res;
            this.comments.push(body);
            this.isLoading = false;
            this.scrollToBottom();
            this.isSending = false;
            this.message = '';
          }
        });
    }
  }

  getDashboardName() {
    const dateFrom = moment(this.factorySummary.fromDate);
    const dateTo = moment(this.factorySummary.toDate);
    let month = '';
    if (Number(dateFrom.format('M')) === Number(dateTo.format('M'))) {
      month = dateFrom.format('MMM');
    } else {
      /* istanbul ignore next */
      month = `${dateFrom.format('MMM')} - ${dateTo.format('MMM')}`;
    }
    const name = `${this.factorySummary.factoryName}-Insights-${month} ${dateFrom.format('YYYY')}`;
    return name;
  }

  close() {
    this.closeComment.emit(false);
  }

  onContentChanged = (event) => {
    if (this.htmlText === null || this.htmlText === '') {
      if (this.userMentionSubscription) {
        this.userMentionSubscription.unsubscribe();
      }
    }
    this.userMentionedList = [];
    if (event.content.ops) {
      const userMention = event.content.ops;
      userMention.forEach(user => {
        if (user.insert.mention) {
          this.userMentionedList.push(user.insert.mention.id);
        }
      });
    }
  }

  onChangeDate(start, end) {
    if (!this.factorySummary) {
      /* istanbul ignore next */
      return;
    }
    this.factorySummary.fromDate = start;
    this.factorySummary.toDate = end;
    this.comments = [];
    this.message = '';
    this.pageStart = 1;
    this.isViewLoadMoreClicked = false;
    this.getComments();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
