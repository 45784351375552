import { Injectable, Component } from '@angular/core';
import { Subject, from, Observable, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class DataService {
  data = {};
  events;
  listeners: any = {};

  private name = new Subject<string>();
  name$ = this.name.asObservable();

  private removeBookmark = new Subject<string>();
  removeBookmark$ = this.removeBookmark.asObservable();

  private bestPractice = new Subject<string>();
  bestPractice$ = this.bestPractice.asObservable();

  private factoryVariant = new Subject<boolean>();
  factoryVariant$ = this.factoryVariant.asObservable();

  constructor() {
    this.events = from(this.subject);
    this.events.subscribe(({ name, args }) => {
      this.invokeListeners(name, args);
    });
  }

  private subject = new Subject<any>();
  private unitSubject = new BehaviorSubject<any>('cost');
  private SearchRadioSubject = new BehaviorSubject<any>('searchRadio');

  headerRefreshDataVal = new BehaviorSubject<any>(null);
  /* istanbul ignore next */
  getHeaderRefreshDataSubjectVal() {
    return this.headerRefreshDataVal;
  }
  /* istanbul ignore next */
  setHeaderRefreshDataSubjectVal(value) {
    this.headerRefreshDataVal.next(value);
  }
  /* istanbul ignore next */
  invokeListeners(name: string, args: any) {
    if (this.listeners[name]) {
      for (const listnr of this.listeners[name]) {
        if (listnr) {
          listnr(...args);
        }
      }
    }
  }
  /* istanbul ignore next */
  removeAllListnersFor(name: string) {
    this.listeners[name] = null;
  }
  /* istanbul ignore next */
  on(name: string, listener) {
    if (!this.listeners[name]) {
      this.listeners[name] = [];
    }
    this.listeners[name].push(listener);
    // return;
  }
  /* istanbul ignore next */
  publishData(data: string) {
    this.name.next(data);
  }
  /* istanbul ignore next */
  publishFactoryVariant(data: boolean) {
    this.factoryVariant.next(data);
  }
  /* istanbul ignore next */
  removeBookmarkData(str: string) {
    this.removeBookmark.next(str);
  }
  /* istanbul ignore next */
  publishDataBestPractice(data: string) {
    this.bestPractice.next(data);
  }
  /* istanbul ignore next */
  broadcast(name: string, ...args) {
    this.subject.next({
      name,
      args
    });
  }
  /* istanbul ignore next */
  getData(key: string): any {
    return this.data[key];
  }
  /* istanbul ignore next */
  setData(key: string, value: any): void {
    this.data[key] = value;
  }
  /* istanbul ignore next */
  clearData(): void {
    this.data = {};
  }
  /* istanbul ignore next */
  getStorageData(key: string): any {
    return sessionStorage.getItem(key);
  }

  setStorageData(key: string, value): void {
    sessionStorage.setItem(key, value);
  }
  /* istanbul ignore next */
  clearStorageData(): void {
    localStorage.clear();
    sessionStorage.clear();
  }
  /* istanbul ignore next */
  removeItemFromStorage(key: string): void {
    sessionStorage.removeItem(key);
  }
  /* istanbul ignore next */
  setUnit(unit: string, when: string) {
    this.unitSubject.next({ text: unit, occurence: when });
    // return;
  }

  getUnit(): Observable<any> {
    return this.unitSubject.asObservable();
  }
  /* istanbul ignore next */
  setSearchHideHeading(searchRadio: string, FromTimeEpoc?: any) {
    this.SearchRadioSubject.next({ text: searchRadio, date: FromTimeEpoc });
    // return;
  }
  /* istanbul ignore next */
  getSearchHideHeading(): Observable<any> {
    return this.SearchRadioSubject.asObservable();
  }
  /* istanbul ignore next */
  convertStandardizedUnit(arr: any): any {
    arr.forEach(item => {
      item.quantity.forEach(quantityArr => {
        let convertedValue = 0;
        quantityArr.value.forEach(component => {
          switch (component.unit) {
            case 'ton': {
              convertedValue = component.value * 1000;
              break;
            }
            case 'litre': {
              convertedValue = component.value * 1;
              break;
            }
            case 'pk': {
              convertedValue = component.value / 1000;
              break;
            }
            case 'kg': {
              convertedValue = component.value * 1;
              break;
            }
          }
          component.convertedValue = convertedValue;
        });
      });
    });
    return arr;
  }

  setDetailLevelList() {
    const detailLevelArr = [
      {
        label: 'Big Category',
        code: 'bigCat',
        translateCode: 'filter_dropdowns.big_cat',
        level: 1
      }, {
        label: 'Category',
        code: 'Cat',
        translateCode: 'filter_dropdowns.category',
        level: 2
      }, {
        label: 'Sub Category',
        code: 'subCat',
        translateCode: 'filter_dropdowns.sub_cat',
        level: 3
      }, {
        label: 'Brand',
        code: 'brand',
        translateCode: 'filter_dropdowns.brand',
        level: 4
      }, {
        label: 'Product',
        code: 'pdt',
        translateCode: 'filter_dropdowns.product',
        level: 5
      }, {
        label: 'BoM Component',
        code: 'bomComp',
        translateCode: 'filter_dropdowns.bom_component',
        level: 6
      }
    ];

    return detailLevelArr;
  }
  /* istanbul ignore next */
  getWasteData(selectedGranularityLevel?, selectedDetail?, dateFilterdata?, cooispiFlag?, costOrQty?, validatedData?, leadSubFamily?): any {
    const thisYear = new Date().getFullYear();
    let wasteFor = 'Cluster';
    let clusterId = null;
    let factoryId = null;
    let lineId = null;
    const IsValidated = validatedData;
    let serviceUrl = 'clusters/' + clusterId + '/factories/' + factoryId + '/lines/' + lineId + '/IsValidated/' + IsValidated;

    const req: any = {};
    if (dateFilterdata && dateFilterdata.dateObj) {
      const extractedData = {
        filterYear: dateFilterdata.dateObj.getFullYear(),
        filterMonth: dateFilterdata.dateObj.getMonth(),
        filterDay: dateFilterdata.dateObj.getDate(),
        filterHr: (dateFilterdata.selectedHr && dateFilterdata.selectedHr.hourVal) ?
          dateFilterdata.selectedHr.hourVal : null,
        shiftFromHr: (dateFilterdata.shift && dateFilterdata.shift.shiftTIme && dateFilterdata.shift.shiftTIme.shiftStart) ?
          dateFilterdata.shift.shiftTIme.shiftStart : null,
        shiftToHr: (dateFilterdata.shift && dateFilterdata.shift.shiftTIme && dateFilterdata.shift.shiftTIme.shiftEnd) ?
          dateFilterdata.shift.shiftTIme.shiftEnd : null,
        shiftToDay: dateFilterdata.dateObj.getDate()
      };

      switch (dateFilterdata.appliedFilterType) {
        case 'month':
          // req.FromTimeEpoc = Math.floor(+(dateFilterdata.dateObj) / 1000);
          req.FromTimeEpoc = Math.floor(+(Date.UTC(dateFilterdata.dateObj.getFullYear(),
            dateFilterdata.dateObj.getMonth())) / 1000);
          // req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear, extractedData.filterMonth + 1)) / 1000);
          req.ToTimeEpoc = Math.floor(+(Date.UTC(dateFilterdata.dateObj.getFullYear(),
            dateFilterdata.dateObj.getMonth() + 1,
            dateFilterdata.dateObj.getDate() - 1, 11, 59, 59)) / 1000);
          break;
        case 'week':
          // req.FromTimeEpoc = Math.floor(+(dateFilterdata.dateObj) / 1000);
          req.FromTimeEpoc = Math.floor(+(Date.UTC(dateFilterdata.dateObj.getFullYear(),
            dateFilterdata.dateObj.getMonth(), dateFilterdata.dateObj.getDate())) / 1000);
          // req.ToTimeEpoc = Math.floor(+(dateFilterdata.endWeekFromSelectedDate) / 1000);
          req.ToTimeEpoc = Math.floor(+(Date.UTC(dateFilterdata.endWeekFromSelectedDate.getFullYear(),
            dateFilterdata.endWeekFromSelectedDate.getMonth(),
            dateFilterdata.endWeekFromSelectedDate.getDate() - 1, 11, 59, 59)) / 1000);
          break;
        case 'date':
          req.FromTimeEpoc = Math.floor(+(dateFilterdata.dateObj) / 1000);
          req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
            extractedData.filterMonth, extractedData.filterDay + 1)) / 1000);
          break;
        case 'hour':
          req.FromTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
            extractedData.filterMonth, extractedData.filterDay, extractedData.filterHr)) / 1000);
          req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
            extractedData.filterMonth, extractedData.filterDay, extractedData.filterHr + 1)) / 1000);
          break;
        case 'shift':
          if (extractedData.shiftFromHr && extractedData.shiftToHr) {
            if (extractedData.shiftFromHr.substring(0, 2) > extractedData.shiftToHr.substring(0, 2)) {
              extractedData.shiftToDay = extractedData.shiftToDay + 1;
            }

            req.FromTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
              extractedData.filterMonth, extractedData.filterDay, extractedData.shiftFromHr.substring(0, 2))) / 1000);
            req.ToTimeEpoc = Math.floor(+(new Date(extractedData.filterYear,
              extractedData.filterMonth, extractedData.shiftToDay, extractedData.shiftToHr.substring(0, 2))) / 1000);
            break;
          }
      }
    } else {
      req.FromTimeEpoc = Math.floor(+(new Date(thisYear, 0)) / 1000);
      req.ToTimeEpoc = Math.floor(+(new Date()) / 1000);
    }

    if (cooispiFlag || cooispiFlag === false) {
      req.COOISPIFlag = cooispiFlag;
    }
    if (validatedData || validatedData === false) {
      req.IsValidated = validatedData;
    }
    if (selectedGranularityLevel) {
      if (selectedGranularityLevel.cluster) {
        wasteFor = 'factory';
        req.ClusterId = selectedGranularityLevel.cluster.id || selectedGranularityLevel.cluster.clusterId || null;
        clusterId = req.ClusterId;
        if (selectedGranularityLevel.factory) {
          wasteFor = 'line';
          req.FactoryId = selectedGranularityLevel.factory.id || selectedGranularityLevel.factory.factoryId || null;
          factoryId = req.FactoryId;
          if (selectedGranularityLevel.line) {
            wasteFor = 'sku';
            req.LineId = selectedGranularityLevel.line.id || selectedGranularityLevel.line.lineId || null;
            lineId = req.LineId;
            serviceUrl = 'clusters/' + clusterId + '/factories/' + factoryId + '/lines/' + lineId + '/IsValidated/' + IsValidated;
          }
        }
      }
    }

    req.wasteFor = wasteFor;
    req.serviceUrl = serviceUrl;

    if (selectedDetail && selectedDetail[0] && Array.isArray(selectedDetail)) {
      selectedDetail.forEach(detail => {
        if (detail && detail.type && detail.type === 'Bigcategory') {
          req.BigCategoryId = detail.id;
        }
        if (detail && detail.type && detail.type === 'Category') {
          req.CategoryId = detail.id;
        }
        if (detail && detail.type && detail.type === 'SubCategory') {
          req.SubCategoryId = detail.id;
        }
      });
    }


    // if (selectedDetail) {
    //   if (selectedDetail.bigCat) {
    //     req.BigCategoryId = selectedDetail.bigCat.id;
    //     if (selectedDetail.category) {
    //       req.CategoryId = selectedDetail.category.id;
    //       if (selectedDetail.subCat) {
    //         req.SubCategoryId = selectedDetail.subCat.id;
    //         if (selectedDetail.brand) {
    //           req.brandId = selectedDetail.brand.id;
    //           if (selectedDetail.product) {
    //             req.ProductId = selectedDetail.product.id;
    //             if (selectedDetail.bom) {
    //               req.BomComponentId = selectedDetail.bom.id;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    req.costOrQty = costOrQty;

    if (leadSubFamily) {
      req.LeadSubFamily = leadSubFamily;
    }

    if (dateFilterdata) {
      req.FromDate = dateFilterdata.startOfMonth;
      req.ToDate = dateFilterdata.endOfMonth;
    }

    return req;
  }
  /* istanbul ignore next */
  getYUnit(currencyCodeUnit) {
    const req = {
      symbolCode: '',
      label: ''
    };

    if (currencyCodeUnit === 'eu') {
      req.symbolCode = '&euro;';
      req.label = 'Euro';
    } else if (currencyCodeUnit === 'ru') {
      req.symbolCode = '&#8381;';
      req.label = 'Ruble';
    } else if (currencyCodeUnit === 'cop') {
      req.symbolCode = '&#36;';
      req.label = 'COP';
    } else if (currencyCodeUnit === 'eus') {
      req.symbolCode = '&euro;';
      req.label = 'Euro Static';
    } else if (currencyCodeUnit === 'ars') {
      req.symbolCode = '&#36;';
      req.label = 'ARS';
    } else if (currencyCodeUnit === 'svc') {
      req.symbolCode = '&#8353;';
      req.label = 'SVC';
    } else if (currencyCodeUnit === 'ves') {
      req.symbolCode = '&#66;&#115;';
      req.label = 'VES';
    } else if (currencyCodeUnit === 'crc') {
      req.symbolCode = '&#8353;';
      req.label = 'CRC';
    } else if (currencyCodeUnit === 'hnl') {
      req.symbolCode = '&#76;';
      req.label = 'HNL';
    } else if (currencyCodeUnit === 'brl') {
      req.symbolCode = '&#82;&#36;';
      req.label = 'BRL';
    } else if (currencyCodeUnit === 'usd') {
      req.symbolCode = '&#36;';
      req.label = 'USD';
    } else if (currencyCodeUnit === 'bob') {
      req.symbolCode = '&#66;&#115;';
      req.label = 'BOB';
    } else if (currencyCodeUnit === 'clp') {
      req.symbolCode = '&#36;';
      req.label = 'CLP';
    } else if (currencyCodeUnit === 'mxn') {
      req.symbolCode = '&#36;';
      req.label = 'MXN';
    } else if (currencyCodeUnit === 'zar') {
      req.symbolCode = '&#82;';
      req.label = 'ZAR';
    } else if (currencyCodeUnit === 'etb') {
      req.symbolCode = 'Br';
      req.label = 'ETB';
    } else if (currencyCodeUnit === 'kes') {
      // req.symbolCode = 'KSh';
      req.symbolCode = 'K';
      req.label = 'KES';
    } else if (currencyCodeUnit === 'ngn') {
      req.symbolCode = '&#8358;';
      req.label = 'NGN';
    } else if (currencyCodeUnit === 'xof') {
      req.symbolCode = 'CFA';
      req.label = 'XOF';
    } else if (currencyCodeUnit === 'ghs') {
      req.symbolCode = '&#8373;';
      req.label = 'GHS';
    } else if (currencyCodeUnit === 'aud') {
      // req.symbolCode = '&#36;';
      req.symbolCode = 'A&#36;';
      req.label = 'AUD';
    } else if (currencyCodeUnit === 'idr') {
      req.symbolCode = '&#82;&#112;';
      req.label = 'IDR';
    } else if (currencyCodeUnit === 'thb') {
      req.symbolCode = '&#3647;';
      req.label = 'THB';
    } else if (currencyCodeUnit === 'vnd') {
      req.symbolCode = '&#8363;';
      req.label = 'VND';
    } else if (currencyCodeUnit === 'php') {
      req.symbolCode = '&#8369;';
      req.label = 'PHP';
    } else if (currencyCodeUnit === 'inr') {
      req.symbolCode = '&#x20B9;';
      req.label = 'INR';
    } else if (currencyCodeUnit === 'pkr') {
      req.symbolCode = 'Rs';
      req.label = 'PKR';
    } else if (currencyCodeUnit === 'bdt') {
      req.symbolCode = '&#x9F3;';
      req.label = 'BDT';
    } else if (currencyCodeUnit === 'mmk') {
      req.symbolCode = 'K';
      req.label = 'MMK';
    } else if (currencyCodeUnit === 'npr') {
      req.symbolCode = 'Rp';
      req.label = 'NPR';
    } else if (currencyCodeUnit === 'lkr') {
      req.symbolCode = 'Rs';
      req.label = 'LKR';
    } else if (currencyCodeUnit === 'mdl') {
      req.symbolCode = '';
      req.label = 'MDL';
    } else if (currencyCodeUnit === 'dkk') {
      req.symbolCode = '';
      req.label = 'DKK';
    } else if (currencyCodeUnit === 'myr') {
      req.symbolCode = '';
      req.label = 'MYR';
    } else if (currencyCodeUnit === 'nzd') {
      req.symbolCode = '';
      req.label = 'NZD';
    } else if (currencyCodeUnit === 'huf') {
      req.symbolCode = '';
      req.label = 'HUF';
    } else if (currencyCodeUnit === 'hrk') {
      req.symbolCode = '';
      req.label = 'HRK';
    } else if (currencyCodeUnit === 'gbp') {
      req.symbolCode = '';
      req.label = 'GBP';
    } else if (currencyCodeUnit === 'chf') {
      req.symbolCode = '';
      req.label = 'CHF';
    } else if (currencyCodeUnit === 'czk') {
      req.symbolCode = '';
      req.label = 'CZK';
    } else if (currencyCodeUnit === 'cad') {
      req.symbolCode = '';
      req.label = 'CAD';
    } else if (currencyCodeUnit === 'cny') {
      req.symbolCode = '';
      req.label = 'CNY';
    } else if (currencyCodeUnit === 'twd') {
      req.symbolCode = '';
      req.label = 'TWD';
    } else if (currencyCodeUnit === 'd') {
      req.symbolCode = '';
      req.label = 'D';
    } else if (currencyCodeUnit === 'pen') {
      req.symbolCode = '';
      req.label = 'PEN';
    } else if (currencyCodeUnit === 'nok') {
      req.symbolCode = '';
      req.label = 'NOK';
    } else if (currencyCodeUnit === 'veb') {
      req.symbolCode = '';
      req.label = 'VEB';
    } else if (currencyCodeUnit === 'krw') {
      req.symbolCode = '';
      req.label = 'KRW';
    } else if (currencyCodeUnit === 'jpy') {
      req.symbolCode = '';
      req.label = 'JPY';
    } else if (currencyCodeUnit === 'ron') {
      req.symbolCode = '';
      req.label = 'RON';
    } else if (currencyCodeUnit === 'kzt') {
      req.symbolCode = '';
      req.label = 'KZT';
    } else if (currencyCodeUnit === 'eek') {
      req.symbolCode = '';
      req.label = 'EEK';
    } else if (currencyCodeUnit === 'hkd') {
      req.symbolCode = '';
      req.label = 'HKD';
    } else if (currencyCodeUnit === 'lvl') {
      req.symbolCode = '';
      req.label = 'LVL';
    } else if (currencyCodeUnit === 'ltl') {
      req.symbolCode = '';
      req.label = 'LTL';
    } else if (currencyCodeUnit === 'bgn') {
      req.symbolCode = '';
      req.label = 'BGN';
    } else if (currencyCodeUnit === 'pln') {
      req.symbolCode = '';
      req.label = 'PLN';
    } else if (currencyCodeUnit === 'trl') {
      req.symbolCode = '';
      req.label = 'TRL';
    } else if (currencyCodeUnit === 'skk') {
      req.symbolCode = '';
      req.label = 'SKK';
    } else if (currencyCodeUnit === 'sek') {
      req.symbolCode = '';
      req.label = 'SEK';
    } else if (currencyCodeUnit === 'uah') {
      req.symbolCode = '';
      req.label = 'UAH';
    } else if (currencyCodeUnit === 'sgd') {
      req.symbolCode = '';
      req.label = 'SGD';
    } else if (currencyCodeUnit === 'djf') {
      req.symbolCode = '';
      req.label = 'DJF';
    } else if (currencyCodeUnit === 'egp') {
      req.symbolCode = '';
      req.label = 'EGP';
    } else if (currencyCodeUnit === 'lak') {
      req.symbolCode = '';
      req.label = 'LAK';
    } else if (currencyCodeUnit === 'zmw') {
      req.symbolCode = '';
      req.label = 'ZMW';
    } else if (currencyCodeUnit === 'sdg') {
      req.symbolCode = '';
      req.label = 'SDG';
    } else if (currencyCodeUnit === 'irr') {
      req.symbolCode = '';
      req.label = 'IRR';
    } else if (currencyCodeUnit === 'try') {
      req.symbolCode = '';
      req.label = 'TRY';
    } else if (currencyCodeUnit === 'tnd') {
      req.symbolCode = '';
      req.label = 'TND';
    } else if (currencyCodeUnit === 'aed') {
      req.symbolCode = '';
      req.label = 'AED';
    } else if (currencyCodeUnit === 'zwd') {
      req.symbolCode = '';
      req.label = 'ZWD';
    } else if (currencyCodeUnit === 'sar') {
      req.symbolCode = '';
      req.label = 'SAR';
    } else if (currencyCodeUnit === 'dzd') {
      req.symbolCode = '';
      req.label = 'DZD';
    } else if (currencyCodeUnit === 'tzs') {
      req.symbolCode = '';
      req.label = 'TZS';
    } else if (currencyCodeUnit === 'bhd') {
      req.symbolCode = '';
      req.label = 'BHD';
    } else if (currencyCodeUnit === 'ils') {
      req.symbolCode = '';
      req.label = 'ILS';
    } else if (currencyCodeUnit === 'omr') {
      req.symbolCode = '';
      req.label = 'OMR';
    } else if (currencyCodeUnit === 'zmk') {
      req.symbolCode = '';
      req.label = 'ZMK';
    } else if (currencyCodeUnit === 'mzn') {
      req.symbolCode = '';
      req.label = 'MZN';
    } else if (currencyCodeUnit === 'rwf') {
      req.symbolCode = '';
      req.label = 'RWF';
    } else if (currencyCodeUnit === 'mad') {
      req.symbolCode = '';
      req.label = 'MAD';
    } else if (currencyCodeUnit === 'azm') {
      req.symbolCode = '';
      req.label = 'AZM';
    } else if (currencyCodeUnit === 'ugx') {
      req.symbolCode = '';
      req.label = 'UGX';
    } else if (currencyCodeUnit === 'mwk') {
      req.symbolCode = '';
      req.label = 'MWK';
    } else if (currencyCodeUnit === 'lbp') {
      req.symbolCode = '';
      req.label = 'LBP';
    } else if (currencyCodeUnit === 'jod') {
      req.symbolCode = '';
      req.label = 'JOD';
    } else if (currencyCodeUnit === 'pyg') {
      req.symbolCode = '';
      req.label = 'PYG';
    } else if (currencyCodeUnit === 'pab') {
      req.symbolCode = '';
      req.label = 'PAB';
    } else if (currencyCodeUnit === 'dop') {
      req.symbolCode = '';
      req.label = 'DOP';
    } else if (currencyCodeUnit === 'nio') {
      req.symbolCode = '';
      req.label = 'NIO';
    } else if (currencyCodeUnit === 'htg') {
      req.symbolCode = '';
      req.label = 'HTG';
    } else if (currencyCodeUnit === 'ttd') {
      req.symbolCode = '';
      req.label = 'TTD';
    } else if (currencyCodeUnit === 'uyu') {
      req.symbolCode = '';
      req.label = 'UYU';
    } else if (currencyCodeUnit === 'gtq') {
      req.symbolCode = '';
      req.label = 'GTQ';
    }
    return req;
  }
  /* istanbul ignore next */
  processClusterResp(data) {
    const clusterResp = {
      status: 'success',
      data: []
    };
    console.log("dtaa", data);
    if (data && data.length > 0) {
      // data.sort((a, b) => (a.cluster > b.cluster) ? 1 : -1);
      for (const cluster of data) {
        cluster.factories.sort((a, b) => (a.factory > b.factory) ? 1 : -1);
        if (cluster.factories && cluster.factories.length > 0) {
          const tempCluster = {
            name: cluster.cluster,
            // clusterId: cluster.cluster.length,            
            factories: cluster.factories.map(factry => ({
              name: factry.factory,
              factoryId: factry.factoryId
            }))
          };
          clusterResp.data.push(tempCluster);
        }
      }
      return clusterResp.data;
    } else {
      return clusterResp.data;
    }
  }
  processClusterResp1(data) {
    const clusterResp = {
      status: 'success',
      data: []
    };
    if (data && data.length > 0) {
      data.sort((a, b) => (a.cluster > b.cluster) ? 1 : -1);
      for (const cluster of data) {
        cluster.factories.sort((a, b) => (a.factory > b.factory) ? 1 : -1);
        if (cluster.factories && cluster.factories.length > 0) {
          const tempCluster = {
            name: cluster.cluster,
            // id: cluster.id,
            // clusterId: cluster.clusterId,
            subRegionName: cluster.cluster,
            factories: cluster.factories.map(factry => ({
              factoryId: factry.factoryId,
              name: factry.factory,
              // id: {
              //   $oid: factry.factoryObjectId
              // },
              lineList: factry.lines
            }))
          };
          clusterResp.data.push(tempCluster);
        }
      }
      return clusterResp.data;
    } else {
      return clusterResp.data;
    }
  }
  /* istanbul ignore next */
  processFactoryResp(data, clusterId) {
    const factoryResp = {
      status: 'success',
      data: []
    };
    if (data.length > 0) {
      for (const cluster of data) {
        if (cluster._id.$oid === clusterId) {
          if (cluster.children) {
            for (const factory of cluster.children) {
              const tempFactory = {
                name: factory.name,
                id: factory.id.$oid,
                language: factory.language,
                comments: factory.comments,
                isActive: factory.IsActive,
                localName: factory.localName,
                nodetype: factory.nodetype,
                sapPlantCode: factory.sapPlantCode,
                site: factory.site,
                timeZone: factory.timeZone,
                factoryId: factory.factoryId,
                latitude: (factory.latitude) ? factory.latitude : '',
                longitude: (factory.longitude) ? factory.longitude : ''
              };
              factoryResp.data.push(tempFactory);
            }
          }
        }
      }
      return factoryResp.data;
    } else {
      return factoryResp.data;
    }
  }
  /* istanbul ignore next */
  processLineResp(data) {
    const lineResp = {
      status: 'success',
      data: []
    };
    if (data._id) {
      if (data._id.$oid) {
        if (data.children) {
          for (const line of data.children) {
            const tempLine = {
              name: line.name,
              id: line.id.$oid,
              comments: line.comments,
              isActive: line.IsActive,
              localName: line.localName,
              nodetype: line.nodetype,
              sapCode: line.sapCode,
              lineId: line.lineId
            };
            lineResp.data.push(tempLine);
          }
          return lineResp.data;
        } else {
          return lineResp.data;
        }
      } else {
        return lineResp.data;
      }
    } else {
      return lineResp.data;
    }
  }
  /* istanbul ignore next */
  processMachineResp(data) {
    const machineResp = {
      status: 'success',
      data: []
    };
    if (data._id) {
      if (data._id.$oid) {
        if (data.machines) {
          for (const machine of data.machines) {
            const tempLine = {
              name: machine.name,
              id: machine.id.$oid,
              localName: machine.localName !== null ? machine.localName : machine.name,
              order: machine.orderInLine,
              connected: machine.connected,
              orderInLine: machine.orderInLine,
              tagName: [
                { name: 'tagnameCountIn', value: machine.tagnameCountIn ? machine.tagnameCountIn : null },
                { name: 'tagnameCountIn2', value: machine.tagnameCountIn2 ? machine.tagnameCountIn2 : null },
                { name: 'tagnameCountIn3', value: machine.tagnameCountIn3 ? machine.tagnameCountIn3 : null },
                { name: 'tagnameCountOut', value: machine.tagnameCountOut ? machine.tagnameCountOut : null },
                { name: 'tagnameCountRej', value: machine.tagnameCountRej ? machine.tagnameCountRej : null },
                { name: 'tagnameCountRej2', value: machine.tagnameCountRej2 ? machine.tagnameCountRej2 : null },
                { name: 'tagnameCountRej3', value: machine.tagnameCountRej3 ? machine.tagnameCountRej3 : null },
                { name: 'tagnameCountRej4', value: machine.tagnameCountRej4 ? machine.tagnameCountRej4 : null },
                { name: 'tagnameCountRej5', value: machine.tagnameCountRej5 ? machine.tagnameCountRej5 : null },
                { name: 'tagnameSpeed', value: machine.tagnameSpeed ? machine.tagnameSpeed : null },
                { name: 'tagnameStateBlocked', value: machine.tagnameStateBlocked ? machine.tagnameStateBlocked : null },
                { name: 'tagnameStateRun', value: machine.tagnameStateRun ? machine.tagnameStateRun : null },
                { name: 'tagnameStateStop', value: machine.tagnameStateStop ? machine.tagnameStateStop : null },
                { name: 'tagnameStateWaiting', value: machine.tagnameStateWaiting ? machine.tagnameStateWaiting : null }
              ]
            };
            machineResp.data.push(tempLine);
          }
          return machineResp.data.sort((a, b) => {
            return a.order - b.order;
          });
        } else {
          return [];
        }
      } else {
        return machineResp.data;
      }
    } else {
      return machineResp.data;
    }
  }
  /* istanbul ignore next */
  responseFormbuttonCheck(check) {
    if (check === '1') {
      return true;
    } else {
      return false;
    }
  }
}
