// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SharedService } from 'src/app/shared/service/shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/core/services/auth.service.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-reply-shared',
  templateUrl: './reply.component.html',
  styleUrls: ['./reply.component.scss']
})
export class ReplyComponent implements OnInit, OnDestroy {
  @Input() reply: any;
  @Input() dasboardName: string;

  destroy$: Subject<boolean> = new Subject<boolean>();
  user: any;
  isLiked: boolean;
  isDisliked: boolean;

  constructor(
    private service: SharedService,
    public auth: AuthService,
    public authService: MsalService
  ) { }

  ngOnInit() {
    // this.user = this.auth.getAllAccountInfo;
    if (this.auth.checkIsAuthticated) {
      this.user = this.authService.getAccount();
    }
    if (this.reply.userReaction) {
      if (this.reply.userReaction === 'LIKE') {
        this.isLiked = true;
      } else if (this.reply.userReaction === 'DISLIKE') {
        this.isDisliked = true;
      }
    }
  }

  insertReaction(reaction: string) {
    if (this.auth.checkIsAuthticated) {
      this.user = this.authService.getAccount();
    }
    const body = {
      MessageId: this.reply.messageId,
      Reaction: reaction,
      CreatedBy: this.user.userName,
      URL: window.location.href,
      DashboardName: this.dasboardName,
      CreatedbyName: this.user.userName
    };
    this.service.postReaction(body)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  deleteReaction(reaction: string) {
    if (this.auth.checkIsAuthticated) {
      this.user = this.authService.getAccount();
    }
    this.service.deleteReaction(this.reply.messageId, this.user.userName)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onLike() {
    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.insertReaction('LIKE');
      this.reply.likeCount++;
      if (this.isDisliked && this.reply.disLikeCount > 0) {
        this.reply.disLikeCount--;
      }
      this.isDisliked = false;
    } else {
      this.deleteReaction('LIKE');
      this.reply.likeCount--;
    }
  }

  onDislike() {
    this.isDisliked = !this.isDisliked;
    if (this.isDisliked) {
      this.insertReaction('DISLIKE');
      this.reply.disLikeCount++;
      if (this.isLiked && this.reply.likeCount > 0) {
        this.reply.likeCount--;
      }
      this.isLiked = false;
      // if (this.isLiked) {
      //   this.deleteReaction('LIKE');
      //   this.reply.likeCount--;
      //   this.isLiked = false;
      // }
    } else {
      /* istanbul ignore next */
      this.deleteReaction('DISLIKE');
      /* istanbul ignore next */
      this.reply.disLikeCount--;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
