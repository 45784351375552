import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbUtilsService {

  constructor() { }

  breadCrumbCreator(
    granularitydata,
    detaildata?) {

    let granularityBreadCrumb = [];
    let detailBreadcrumb = [];

    granularityBreadCrumb = [{ label: 'All', type: 'all' }];
    if (granularitydata && granularitydata.cluster && granularitydata.cluster.name) {
      granularityBreadCrumb.push({ label: granularitydata.cluster.name, type: 'cluster' });
      if (granularitydata.factory && granularitydata.factory.name) {
        granularityBreadCrumb.push({ label: granularitydata.factory.name, type: 'factory' });
        if (granularitydata.line && granularitydata.line.name) {
          granularityBreadCrumb.push({ label: granularitydata.line.name, type: 'line' });
        }
      }
    }

    detailBreadcrumb = [{ label: 'All', type: 'all' }];
    if (detaildata && detaildata.bigCat && detaildata.bigCat.value) {
      detailBreadcrumb.push({ label: detaildata.bigCat.value });
      if (detaildata.category && detaildata.category.value) {
        detailBreadcrumb.push({ label: detaildata.category.value });
        if (detaildata.subCat && detaildata.subCat.value) {
          detailBreadcrumb.push({ label: detaildata.subCat.value });
          if (detaildata.brand && detaildata.brand.value) {
            detailBreadcrumb.push({ label: detaildata.brand.value });
            if (detaildata.product && detaildata.product.value) {
              detailBreadcrumb.push({ label: detaildata.product.value });
              if (detaildata.bom && detaildata.bom.value) {
                detailBreadcrumb.push({ label: detaildata.bom.value });
              }
            }
          }
        }
      }
    }

    return {
      granularity: granularityBreadCrumb,
      detail: detailBreadcrumb
    };
  }
}
