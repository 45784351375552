import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { WindowRefService } from '../service/WindowRefService';

@Pipe({
    name: 'localDecimalPipe', pure: true
})

export class DecimalToCommaPipe implements PipeTransform {
    constructor(private win: WindowRefService) {
        // super(win.ln);
    }
    /* istanbul ignore next */
    transform(value: any, ...args: any[]): any {
        const numberValue = parseFloat(Number(value).toFixed(2));
        const localizedValue = Intl.NumberFormat(this.win.ln).format(numberValue);
        return localizedValue;
    }
}
