import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
function _window(): any {
    // return the global native browser window object
    return window;
}

@Injectable({
    providedIn: 'root'
})

export class WindowRefService {
    get nativeWindow(): any {
        return _window();
    }

    public ln = 'en';

    constructor() {
        try {
            if (!isNullOrUndefined(this.nativeWindow.navigator.language) && this.nativeWindow.navigator.language !== '') {
                this.ln = this.nativeWindow.navigator.language;
            }
        } finally { }
    }
}
