import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject, OnDestroy, EventEmitter, Output, Input, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { default as _rollupMoment, Moment } from 'moment';
import { SelectLineComponent } from '../select-line/select-line.component';
import { RequesterService } from 'src/app/features/waste-dashboard/services/requester.service';
import { ToastrService } from 'ngx-toastr';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};
@Component({
  selector: 'app-confirmation-popup-model',
  templateUrl: './confirmation-popup-model.component.html',
  styleUrls: ['./confirmation-popup-model.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class ConfirmationPopupModelComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() dateSelected: EventEmitter<any> = new EventEmitter<Date>();
  @ViewChild('reportGeneratorDialog', { static: false }) lineCreatorDialog: TemplateRef<any>;
  @ViewChild('reportSuccess', { static: false }) reportSuccess: ElementRef;
  @ViewChild('reportNoReports', { static: false }) reportNoReports: ElementRef;
  @ViewChild('reportDelete', { static: false }) reportDelete: ElementRef;

  date = new FormControl(moment());
  factorySelectionForm: FormGroup;
  clusterList: any[] = [];
  factoryList: any[] = [];
  fileredClusterList: any[] = [];
  filteredFactoryList: any[] = [];
  isSelected: boolean;
  subscriptionMessage: string;
  uniqueClusterList: any;
  applicationList: any = [];
  plannedYr: any;
  lineList: any[] = [];
  filteredLineList: any[] = [];
  allSelected = false;
  @ViewChild('stateSelect', { static: false }) stateSelect;
  selectAllChecked: boolean;
  selectedItems = new FormControl([]);
  constructor(
    @Inject(MAT_DIALOG_DATA) public dataPassed,
    public fb: FormBuilder, private daService: RequesterService, private toastrService: ToastrService,
    public dialog: MatDialogRef<ConfirmationPopupModelComponent>, private cdr: ChangeDetectorRef, public dialog1: MatDialog

  ) { }

  ngOnInit() {
    this.factorySelectionForm = this.fb.group({
      cluster: ["", [Validators.required]],
      factory: [[], [Validators.required]],
      application: ["", [Validators.required]],
      date: [this.plannedYr, [Validators.required]]
    });


    this.f.date.setValue(this.date.value.year());
    sessionStorage.setItem('selectedDAAYear', JSON.stringify(this.date.value.year()));
    this.f.factory.disable();
    this.f.cluster.disable();
    this.clearSelected();
    this.applicationList = [{ name: "BuildApp", value: "buildApp" }, { name: "DFOS", value: "dfos" }, { name: "SPC", value: "SPC" }, { name: "NGTW", value: "ngtw" }];
  }






  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  onDateSelected(normalizedYear: Moment, dp: any) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    dp.close();
    let isoString = new Date(this.date.value);
    const date = moment(isoString, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)');
    this.date.setValue(date);
    this.dateSelected.emit(date);
    this.f.date.setValue(ctrlValue.year());
    sessionStorage.setItem('selectedDAAYear', JSON.stringify(ctrlValue.year()));
    this.isSelected = true;
    this.subscriptionMessage = '';
  }





  get f() {
    return this.factorySelectionForm.controls;
  }

  /* istanbul ignore next */
  autoCompleteCluster(event: string) {
    this.f.factory.setValue([]);
    this.f.factory.disable();
    if (event && event !== '') {
      const searchKeyword = event.toLowerCase();
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      this.uniqueClusterList = this.uniqueClusterList.filter(c => {
        if (c.name.toLowerCase().includes(searchKeyword)) {
          return true;
        }
      });
    } else {
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      this.filteredFactoryList = this.factoryList;
      this.isSelected = false;
    }
  }

  autoCompleteFactory(event: string) {
    this.isSelected = false;
    if (event && event !== '') {
      const searchKeyword = event.toLowerCase();
      const clonedFactoryList = [...this.factoryList];
      this.filteredFactoryList = clonedFactoryList.filter(f => {
        if (f.name.toLowerCase().includes(searchKeyword)) {
          return true;
        }
      });
    } else {
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      this.filteredFactoryList = this.factoryList;
    }
  }

  onAppChange(factory: any) {
    this.f.application.setValue(factory.name);
    sessionStorage.setItem('selectedDAApplication', JSON.stringify(factory));
    this.isSelected = true;
    this.subscriptionMessage = '';
    this.getClusters();
    this.f.cluster.enable();
  }


  getClusters() {
    const selectedApplication = JSON.parse(sessionStorage.getItem('selectedDAApplication'));
    const clusters = selectedApplication.value === "buildApp" || selectedApplication.value === "ngtw" ? JSON.parse(sessionStorage.getItem('userfactory.data')) : JSON.parse(sessionStorage.getItem('dfos.userfactory.dataforline'));
    if (clusters) {
      this.clusterList = this.fileredClusterList = clusters;
      this.uniqueClusterList = [];
      this.uniqueClusterList = [...new Map(this.clusterList.map((m) => [m.name, m])).values()];
      if (clusters.length === 1) {
        this.onClusterChange(clusters[0]);
      }
    }
  }
  /* istanbul ignore next */
  getFactories(cluster: any) {
    this.factoryList = this.filteredFactoryList = [];
    for (let index = 0; index < this.fileredClusterList.length; index++) {
      if (this.fileredClusterList[index].name === cluster.name) {
        this.fileredClusterList.forEach((entry) => {
          entry.factories.forEach(element => {
            element['subRegionName'] = entry.name;
          });
        });

        this.factoryList = this.filteredFactoryList = [...this.factoryList, ...this.fileredClusterList[index].factories];
      }

    }
    this.factoryList.sort((a, b) => a.name.localeCompare(b.name));
  }




  onSelectAllChange(checked: boolean): void {
    if (this.factoryList.length != 0) {
      const factoryNames = checked ? this.factoryList.map(factory => factory.factoryId) : [];
      this.factorySelectionForm.get('factory').setValue(factoryNames);
    } else {
      console.error('selectedCluster or factoryList is undefined');
    }
    this.selectAllChecked = checked;
  }






  clearSelected() {
    sessionStorage.removeItem('selectedDACluster');
    sessionStorage.removeItem('selectedDAFactory');
    sessionStorage.removeItem('selectedDAApplication');
    sessionStorage.removeItem('selectedDAADate');
  }

  onClusterChange(cluster: any) {
    this.f.cluster.setValue(cluster.name);
    sessionStorage.setItem('selectedDACluster', JSON.stringify(cluster));
    this.f.factory.setValue([]);
    this.f.factory.enable();
    this.getFactories(cluster);
  }






  getLocalStorageData(key: string) {
    return JSON.parse(sessionStorage.getItem(key));
  }

  onSubmit() {
    if (this.factorySelectionForm.valid) {
      sessionStorage.setItem('selectedDACluster', JSON.stringify(this.filteredFactoryList[0]));
      let saveForm = {
        factoryId: this.factorySelectionForm.controls.factory.value,
        cluster: this.factorySelectionForm.controls.cluster.value,
        application: this.factorySelectionForm.controls.application.value,
        year: this.factorySelectionForm.controls.date.value
      };

      this.daService.downloadexcel(saveForm).subscribe((resp: any) => {
        if (resp !== '') {
          this.downloadZipFile(resp);
          this.toastrService.success(this.reportSuccess.nativeElement.innerHTML, '', { enableHtml: true });
        } else {
          this.toastrService.success(this.reportNoReports.nativeElement.innerHTML, '', { enableHtml: true });
        }
      });

    }
  }


  downloadZipFile(data) {
    const a = document.createElement('a');
    a.href = encodeURI(data);
    a.click();
  }

  dialogClose() {
    this.dialog.close({ action: 'cancelled' });
  }


  ngOnDestroy(): void {

  }

}
