import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, OnDestroy } from '@angular/core';
import { TimePickerUtilService } from '../../service/time-picker-util.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DashboardFilterComponent } from '../dashboard-filter/dashboard-filter.component';
import { DataService } from '../../service/data.service';
import { Router } from '@angular/router';
import { BreadcrumbUtilsService } from '../../service/breadcrumb-utils.service';

@Component({
  selector: 'app-dashboard-filter-header',
  templateUrl: './dashboard-filter-header.component.html',
  styleUrls: ['./dashboard-filter-header.component.scss']
})
export class DashboardFilterHeaderComponent implements OnInit, OnDestroy {
  [x: string]: any;
  @Input() dashboardName: string;
  @Output() applyFilter = new EventEmitter<any>();

  timeText: any;
  granularityBreadCrumb: any[];
  detailBreadcrumb: any[];
  showTimePicker: any = false;
  enableApplyBtn: boolean;
  typeOfFilter: any;
  filterApplied: boolean;
  today: Date;
  currentDate: any;
  currrentFilter: any;
  appliedHour: any;
  appliedShift: any;
  appliedEndWeek: any;
  currentHour: any;
  currentShift: any;
  currentEndWeek: any;
  emitData: any;

  selectedFilters: any = {
    visualizationVal: null,
    detailLevel: null
  };

  showData: any = {
    total: true,
    RM: true,
    PM: true,
  };

  unitService: any;
  unitSelected: any;

  constructor(
    private timeInitser: TimePickerUtilService,
    private dialog: MatDialog,
    private dataService: DataService,
    private router: Router,
    private breadCrmbSer: BreadcrumbUtilsService

  ) { }

  ngOnInit() {
    this.today = new Date();
    this.timeText = new Date();
    this.enableApplyBtn = false;
    this.typeOfFilter = 'filter-changed';
    this.filterApplied = false;
    this.currrentFilter = 'month';
    this.currentDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
    this.currentShift = {};
    let selectedHourDetail = {};

    if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter'))) {
      this.filterApplied = true;

      this.currrentFilter = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).appliedFilterType;
      this.currentDate = new Date(JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).dateObj);

      if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr &&
        JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr.label) {
        this.currentHour = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr.label;
        selectedHourDetail = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).selectedHr;
      } else {
        this.currentHour = '';
        selectedHourDetail = {};
      }

      if (this.currrentFilter === 'week') {
        this.currentEndWeek = new Date(JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).endWeekFromSelectedDate);
      } else {
        this.currentEndWeek = '';
      }

      if (JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).shift) {
        this.currentShift = JSON.parse(this.dataService.getStorageData('selectedWasteDBTimeFilter')).shift;
      } else {
        this.currentShift = {};
      }
    } else {
      this.currrentFilter = 'month';
      this.currentDate = new Date(this.today.getFullYear(), this.today.getMonth() - 1);
    }

    this.emitData = {
      timeFilter: {
        appliedFilterType: this.currrentFilter,
        dateObj: this.currentDate,
        selectedHr: selectedHourDetail,
        endWeekFromSelectedDate: this.currentEndWeek,
        shift: this.currentShift
      },
      selectedFilters: this.selectedFilters
    };

    // this.checkForSelectedGranularityAndDetail();
    // this.openDialog();

    // this.granularityBreadCrumb = [{label:'Europe',link:'/waste-dashboard'},
    // {label:'Heppenheim',link:'/waste-dashboard'},{label:'Line 01',link:'/line-dashboard'}];

    // this.detailBreadcrumb = [{label:'Big Category1'},{label:'Small category1'},{label:'brand1'}];
    this.unitService = this.dataService.getUnit().subscribe(message => {
      this.unitSelected = message.text;
    });

    this.dataService.on('getSelectedWaste', this.getSelectedWasteForLineHeader);
    this.dataService.on('currentLink', this.LineHeaderSideMenuClicked);
  }

  getSelectedWasteForLineHeader = () => {
    this.checkForSelectedGranularityAndDetail();
  }

  LineHeaderSideMenuClicked = (item) => {
    if (item === '/line-dashboard') {
      this.openDialog();
    }
  }

  checkForSelectedGranularityAndDetail() {
    const selectedGranularity = JSON.parse(this.dataService.getStorageData('selectedWasteDBGranularity'));
    if (selectedGranularity && selectedGranularity.line && (selectedGranularity.line.lineId || selectedGranularity.line.id)) {
      const selectedDetail = JSON.parse(this.dataService.getStorageData('selectedWasteDBDetail'));
      if (selectedDetail) {
        this.breadCrumbCreator(selectedGranularity, selectedDetail);
        this.selectedFilters.detailLevel = selectedDetail;
      } else {
        this.breadCrumbCreator(selectedGranularity);
      }

      this.selectedFilters.visualizationVal = selectedGranularity;
    } else {
      // this.openDialog();
    }
  }

  granularityClick(item): void {
    if (this.selectedFilters) {
      switch (item.type) {
        case 'all':
          this.selectedFilters.visualizationVal.cluster = null;
          this.selectedFilters.visualizationVal.factory = null;
          this.selectedFilters.visualizationVal.line = null;
          this.selectedFilters.visualizationVal.type = 'cluster';
          // this.dataService.setStorageData('selectedWasteDBGranularity', JSON.stringify(this.selectedFilters.visualizationVal));
          this.dataService.setStorageData('selectedWasteDBDetail', JSON.stringify(this.selectedFilters.detailLevel));
          // this.router.navigate(['waste-dashboard']);
          break;
        case 'cluster':
          this.selectedFilters.visualizationVal.factory = null;
          this.selectedFilters.visualizationVal.line = null;
          this.selectedFilters.visualizationVal.type = 'factory';
          // this.dataService.setStorageData('selectedWasteDBGranularity', JSON.stringify(this.selectedFilters.visualizationVal));
          this.dataService.setStorageData('selectedWasteDBDetail', JSON.stringify(this.selectedFilters.detailLevel));
          // this.router.navigate(['waste-dashboard']);
          break;
        case 'factory':
          this.selectedFilters.visualizationVal.line = null;
          this.selectedFilters.visualizationVal.type = 'line';
          // this.dataService.setStorageData('selectedWasteDBGranularity', JSON.stringify(this.selectedFilters.visualizationVal));
          this.dataService.setStorageData('selectedWasteDBDetail', JSON.stringify(this.selectedFilters.detailLevel));
        // this.router.navigate(['waste-dashboard']);
      }

      this.emitDataToLine();
    }
  }

  detailClick(item): void {
    if (item.type === 'all') {
      this.selectedFilters.detailLevel.bigCat = '';
      this.selectedFilters.detailLevel.category = '';
      this.selectedFilters.detailLevel.subCat = '';
      this.selectedFilters.detailLevel.brand = '';
      this.selectedFilters.detailLevel.prodOrder = '';
      this.selectedFilters.detailLevel.product = '';
      this.selectedFilters.detailLevel.bom = '';


      this.breadCrumbCreator(this.selectedFilters.visualizationVal, this.selectedFilters.detailLevel);
      this.dataService.setStorageData('selectedWasteDBDetail', JSON.stringify(this.selectedFilters.detailLevel));

      this.emitDataToLine();
    }
  }

  openDialog(addUpdate?: string) {
    // this.filterApplied = false;

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      action: addUpdate
    };

    dialogConfig.height = '91%';
    dialogConfig.width = '85%';

    const dialogRef = this.dialog.open(DashboardFilterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((filterData) => {
      if (filterData && filterData.visualizationVal) {
        this.selectedFilters = filterData;

        this.emitDataToLine();

        this.breadCrumbCreator(filterData.visualizationVal, filterData.detailLevel);
      }
    });
  }

  appliedFilters(event) {
    this.enableApplyBtn = false;

    // if (event.typeOfFilter !== 'week') {
    //   this.filterApplied = false;
    // }

    if (event.typeOfFilter !== 'filter-changed') {
      this.enableApplyBtn = true;
    }

    this.typeOfFilter = event.typeOfFilter;
    this.appliedHour = event.hour;
    this.appliedShift = event.shift;

    if (event.typeOfFilter !== 'month') {
      this.timeText = new Date(event.year, event.month, event.date);
      this.appliedEndWeek = new Date(event.year, event.month, (event.date + 7));
    } else {
      this.timeText = new Date(event.year, event.month);
      this.appliedEndWeek = '';
    }

  }

  showHideTImeFilter(btn: string) {
    if (btn === 'ok') {
      this.filterApplied = true;
      this.currrentFilter = this.typeOfFilter;
      this.currentDate = this.timeText;
      this.currentHour = this.appliedHour.label;
      this.currentShift = this.appliedShift;
      this.currentEndWeek = this.appliedEndWeek;

      this.emitDataToLine();
    }

    this.showTimePicker = !this.showTimePicker;
  }

  breadCrumbCreator(granularitydata, detaildata?) {
    // this.granularityBreadCrumb = [{ label: 'All', type: 'all' }];
    // if (granularitydata && granularitydata.cluster && granularitydata.cluster.name) {
    //   this.granularityBreadCrumb.push({ label: granularitydata.cluster.name, type: 'cluster' });
    //   if (granularitydata.factory && granularitydata.factory.name) {
    //     this.granularityBreadCrumb.push({ label: granularitydata.factory.name, type: 'factory' });
    //     if (granularitydata.line && granularitydata.line.name) {
    //       this.granularityBreadCrumb.push({ label: granularitydata.line.name, type: 'line' });
    //     }
    //   }
    // }

    // this.detailBreadcrumb = [{ label: 'All', type: 'all' }];
    // if (detaildata && detaildata.bigCat && detaildata.bigCat.value) {
    //   this.detailBreadcrumb.push({ label: detaildata.bigCat.value });
    //   if (detaildata.category && detaildata.category.value) {
    //     this.detailBreadcrumb.push({ label: detaildata.category.value });
    //     if (detaildata.subCat && detaildata.subCat.value) {
    //       this.detailBreadcrumb.push({ label: detaildata.subCat.value });
    //       if (detaildata.brand && detaildata.brand.value) {
    //         this.detailBreadcrumb.push({ label: detaildata.brand.value });
    //         if (detaildata.product && detaildata.product.value) {
    //           this.detailBreadcrumb.push({ label: detaildata.product.value });
    //           if (detaildata.bom && detaildata.bom.value) {
    //             this.detailBreadcrumb.push({ label: detaildata.bom.value });
    //           }
    //         }
    //       }
    //     }
    //   }
    // }

    const breadCrmbData =
      this.breadCrmbSer.breadCrumbCreator(granularitydata, detaildata);

    this.granularityBreadCrumb = breadCrmbData.granularity;
    this.detailBreadcrumb = breadCrmbData.detail;
  }

  filterChange() {
    this.dataService.broadcast('lineDBWasteChange', this.showData);
  }

  emitDataToLine() {
    this.emitData = {
      timeFilter: {
        appliedFilterType: this.currrentFilter,
        dateObj: this.currentDate,
        selectedHr: this.appliedHour,
        endWeekFromSelectedDate: this.currentEndWeek,
        shift: this.currentShift
      },
      selectedFilters: this.selectedFilters
    };

    if (this.currrentFilter !== 'week') {
      this.emitData.endWeekFromSelectedDate = '';
    }

    this.dataService.setStorageData('selectedWasteDBTimeFilter', JSON.stringify(this.emitData.timeFilter));
    this.applyFilter.emit(this.emitData);
  }

  ngOnDestroy(): void {
    if (this.unitSelected) {
      this.unitService.unsubscribe();
    }

    this.dataService.removeAllListnersFor('currentLink');
  }

}
