import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import moment from 'moment';
import { SharedService } from 'src/app/shared/service/shared.service';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/core/services/auth.service.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-comment-shared',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  animations: [
    trigger('showReply', [
      state('initial', style({
        height: '0',
        overflow: 'hidden',
        opacity: '0'
      })),
      state('final', style({
        overflow: 'visible'
      })),
      transition('initial <=> final', animate('.5s ease-in-out'))
    ])
  ]
})
export class CommentComponent implements OnInit, OnDestroy {

  @Input() comment;
  @Input() factorySummary;
  @Input() isShowReply = false;
  @Input() dasboardName: string;
  @Output() scrollBottom = new EventEmitter();

  destroy$: Subject<boolean> = new Subject<boolean>();
  users: Array<any> = [];
  replies: Array<any> = [];
  shortName: any;
  user: any;
  userDtls: any;
  isLoading = false;
  message: string;
  isLiked: boolean;
  isDisliked: boolean;

  htmlText = null;
  hasFocus = false;
  userMentionedList: Array<string> = [];
  userMentionTimer: any;
  userMentionSubscription: Subscription;
  quillConfig1: any;

  constructor(
    private service: SharedService,
    public auth: AuthService,
    private authService: MsalService,
    private route: Router
  ) { }

  ngOnInit() {
    // this.user = this.auth.getAllAccountInfo;
    if (this.auth.checkIsAuthticated) {

      // this.userDtls = this.authService.getAllAccounts().forEach(item => item.name);
      const accessTokenRequest = {
        //  scopes: ['user.read'],
        scopes: [environment.msalAuthConfig.auth.clientId],
        authority: environment.msalAuthConfig.auth.authority
      };
      this.authService.acquireTokenSilent(accessTokenRequest).then(async (payload: any) => {
        //  this.loaderHandlerService.toggleLoader(0);
        if (payload && payload.account && payload.account.name) { // Assign username
          this.userDtls = payload.account.name;
          this.user = payload.account.userName;
          const fullname = this.userDtls;
          const split = fullname.split(" ");
          this.shortName = split[0][0] + split[1][0];
        }
        //   this.authService.setTokenExpiryTime(payload); // Set token expiry time
        return payload;
      });
    }
    if (this.comment.userReaction) {
      if (this.comment.userReaction === 'LIKE') {
        this.isLiked = true;
      } else if (this.comment.userReaction === 'DISLIKE') {
        this.isDisliked = true;
      }
    }
    if (this.isShowReply) {
      this.getReplies();
    }
    /* istanbul ignore next */
    this.quillConfig1 = {
      // toolbar: '.toolbar',
      toolbar: {
        container: [],
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: async (searchTerm, renderList, mentionChar) => {
          /* istanbul ignore next */
          if (this.userMentionSubscription) {
            this.userMentionSubscription.unsubscribe();
          }
          /* istanbul ignore next */
          if (this.userMentionTimer) {
            clearInterval(this.userMentionTimer);
          }
          /* istanbul ignore next */
          if (mentionChar === '@') {
            /* istanbul ignore next */
            if (searchTerm.length > 1) {
              this.userMentionTimer = setTimeout(() => {
                this.suggestPeople(searchTerm).then(users => {
                  renderList(users);
                });
              }, 500);
            }
          }
        },
      },
      // 'emoji-toolbar': true,
      // 'emoji-textarea': true,
      // 'emoji-shortname': true,
      keyboard: {
        bindings: {
          shiftEnter: {
            key: 13,
            shiftKey: true,
            handler: (range, context) => {
              /* istanbul ignore next */
              return true;
            }
          },
          enter: {
            key: 13,
            handler: (range, context) => {
              /* istanbul ignore next */
              this.postReply();
              return false;
            }
          }
        }
      }
    };

    this.setInitialUserMention();
  }

  setInitialUserMention() {
    this.htmlText = `<p>
      <span class="mention" data-index="1" data-denotation-char="@"
        data-id="${this.comment.createdByEmailId}"
        data-value="${this.comment.createdBy}">﻿
        <span contenteditable="false">
          <span class="ql-mention-denotation-char">@</span>
          ${this.comment.createdBy}
        </span>﻿
      </span>
    </p>`;
  }

  suggestPeople(searchTerm: string) {
    return new Promise((resolve, reject) => {
      const param = {
        filtertext: searchTerm
      };
      this.userMentionSubscription = this.service.getUsersForMention(param)
        // .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            const users = res.value.map(user => {
              return { id: user.mail, value: user.displayName };
            });
            resolve(users);
          }
        });
    });
  }
  /* istanbul ignore next */
  toggleReply() {
    this.isShowReply = !this.isShowReply;
    if (this.isShowReply) {
      this.getReplies();
    }
  }

  getReplies() {
    if (this.auth.checkIsAuthticated) {
      this.userDtls = this.authService.getAccount().userName;
    }
    this.isLoading = true;
    this.message = 'Loading...';
    const param = {
      ParentMessageId: this.comment.messageId,
      userEmailId: this.userDtls
    };
    this.replies = [];
    this.service.getReplies(param)
      // .pipe(takeUntil(this.destroy$))
      .subscribe((res: Array<any>) => {
        if (res) {
          // this.replies = [...res].reverse();
          this.replies = [...res];
          this.isLoading = false;
          this.scrollBottom.emit(true);
        } else {
          this.isLoading = false;
        }
      });
  }

  postReply() {
    if (this.htmlText) {
      this.isLoading = true;
      this.message = 'Sending...';
      const body: any = {
        parentMessageId: this.comment.messageId,
        message: this.htmlText,
        filePath: 'Empty',
        MentionedUserList: this.userMentionedList.join(),
        createdBy: this.userDtls,
        createdByEmailId: this.user,
        createdOn: new Date(),
        disLikeCount: 0,
        likeCount: 0,
        messageId: 0,
        replyCount: 0,
        URL: window.location.href,
        DashboardName: this.dasboardName
      };

      this.htmlText = null;
      this.setInitialUserMention();
      this.service.postReply(body)
        // .pipe(takeUntil(this.destroy$))
        .subscribe(res => {
          if (res) {
            body.messageId = res;
            this.replies.push(body);
            this.isLoading = false;
            this.message = '';
            this.comment.replyCount++;
          }
        });
    }
  }

  onContentChanged = (event) => {
    this.userMentionedList = [];
    if (event.content.ops) {
      const userMention = event.content.ops;
      userMention.forEach(user => {
        if (user.insert.mention) {
          this.userMentionedList.push(user.insert.mention.id);
        }
      });
    }
  }

  insertReaction(reaction: string) {
    if (this.auth.checkIsAuthticated) {
      this.userDtls = this.authService.getAccount().userName;
    }
    const body = {
      MessageId: this.comment.messageId,
      Reaction: reaction,
      CreatedBy: this.userDtls,
      URL: window.location.href,
      DashboardName: this.dasboardName,
      CreatedbyName: this.userDtls
    };
    this.service.postReaction(body)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  deleteReaction(reaction: string) {
    this.service.deleteReaction(this.comment.messageId, this.user)
      // .pipe(takeUntil(this.destroy$))
      .subscribe();
  }

  onLike() {
    this.isLiked = !this.isLiked;
    if (this.isLiked) {
      this.insertReaction('LIKE');
      this.comment.likeCount++;
      if (this.isDisliked && this.comment.disLikeCount > 0) {
        this.comment.disLikeCount--;
      }
      this.isDisliked = false;
      // if (this.isDisliked) {
      //   this.deleteReaction('DISLIKE');
      //   this.comment.disLikeCount--;
      //   this.isDisliked = false;
      // }
    } else {
      this.deleteReaction('LIKE');
      this.comment.likeCount--;
    }
  }

  onDislike() {
    this.isDisliked = !this.isDisliked;
    if (this.isDisliked) {
      this.insertReaction('DISLIKE');
      this.comment.disLikeCount++;
      if (this.isLiked && this.comment.likeCount > 0) {
        this.comment.likeCount--;
      }
      this.isLiked = false;
      // if (this.isLiked) {
      //   this.deleteReaction('LIKE');
      //   this.comment.likeCount--;
      //   this.isLiked = false;
      // }
    } else {
      this.deleteReaction('DISLIKE');
      this.comment.disLikeCount--;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
